import React, { useCallback, useState } from 'react';
import { Modal } from 'react-ess-components';

import { translations } from '../utils';
import useToggle from './useToggle';

function useConfirmationModal(onOpen, onClose) {
  const [isVisible, setVisible] = useToggle(false);
  const [isLoading, setIsLoading] = useState(false);
  const [props, setProps] = useState({});

  const openModal = useCallback(
    (props = {}) => {
      setProps(props);
      if (onOpen) onOpen(props);
      setVisible(true);
    },
    [onOpen, setVisible],
  );

  const closeModal = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const onConfirm = useCallback(async () => {
    setIsLoading(true);
    if (onClose) await onClose(props);
    setIsLoading(false);
    setVisible(false);
  }, [onClose, props, setVisible]);

  const renderModal = useCallback((labels) => {
    if (isVisible) {
      return (<Modal
        requestClose={closeModal}
        open
        rightButtonProps={{ label: labels.rightButton, onClick: onConfirm, isLoading }}
        leftButtonProps={{ label: translations.getLabel('cancel'), onClick: closeModal }}
        title={labels.title}
      >
        <p className="confirmation-message">{labels.message}</p>
      </Modal>
      );
    }
    return null;
  }, [closeModal, isLoading, isVisible, onConfirm]);

  return [renderModal, openModal];
}

export default useConfirmationModal;
