import React, { PureComponent } from 'react';
import { Button, Modal } from 'react-ess-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { backgroundImages } from '../../../../../constants';
import ConfigContext from '../../../../../context/ConfigContext';
import { companyAdminService } from '../../../../../services';
import { fileUploadUtils,translations } from '../../../../../utils';
import AppBackgroundTab from './app/AppBackgroundTab';
import WebBackgroundTab from './web/WebBackgroundTab';

import './BackgroundTab.scss';

const TABS = { APP: 'app', WEB: 'web' };

export default class Background extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      downloadUrl: null,
      isLoadingWeb: false,
      isLoadingApp: false,
      isLoadingWebStartPage: false,
      isRemoveLoading: false,
      isConfirmationModalOpen: false,
      activeTab: '',
    };
  }

  componentDidMount() {
    this.getCompanyPreferences();
    const url = this.props.match.url.substring(this.props.match.url.lastIndexOf('/') + 1, this.props.match.url.length);
    const tab = TABS[url.toUpperCase()];
    if (!tab) this.setActiveTab()();
    else this.setActiveTab(tab)();
  }

  getCompanyPreferences = async () => {
    const result = await companyAdminService.getCompanyPreferences(this.props.companyId);
    this.context.updateCompanyConfig(result);
  }

  getType = (type) => {
    switch (type) {
      case backgroundImages.web:
        return 'webBackgroundUrl';
      case backgroundImages.webStartPage:
        return 'webStartpageImageUrl';
      case backgroundImages.app:
        return 'phoneHeaderImageUrl';
      default:
        break;
    }
  }

  handleUpload = async (file, type) => {
    this.setState({ [`isLoading${type}`]: true });
    const url = await companyAdminService.getPresignedUrl(this.props.companyId, this.getType(type));
    if (url) {
      await fileUploadUtils.uploadFileToPresignedUrl(url, file, 'image/png');
      this.getCompanyPreferences();
      return this.setState({ [`isLoading${type}`]: false });
    }
    this.setState({ [`isLoading${type}`]: false });
  }

  onDelete = async () => {
    this.setState({ isRemoveLoading: true });
    await companyAdminService.deleteImage(this.props.companyId, this.getType(this.state.type));
    this.setState({ isRemoveLoading: false, isConfirmationModalOpen: false });
    this.getCompanyPreferences();
  }

  setActiveTab = (tab) => () => {
    const url = this.props.match.url;
    if (tab) {
      const base = url.substring(0, url.lastIndexOf('/') + 1);
      this.props.history.push(`${base}${tab}`);
      this.setState({ activeTab: tab });
    } else {
      this.props.history.push(`${url}/${TABS.WEB}`);
      this.setState({ activeTab: TABS.WEB });
    }
  }

  toggleConfirmationModal = (type) => this.setState({ isConfirmationModalOpen: !this.state.isConfirmationModalOpen, type });

  render() {
    const classNameWeb = classNames({ 'active': this.state.activeTab === TABS.WEB });
    const classNameApp = classNames({ 'active': this.state.activeTab === TABS.APP });

    return (
      <React.Fragment>
        <div className="background-container">
          <div className="inner-tabs">
            <Button onClick={this.setActiveTab(TABS.WEB)} className={classNameWeb} theme="transparent">{translations.getLabel('titleWebApp')}</Button>
            <Button onClick={this.setActiveTab(TABS.APP)} className={classNameApp} theme="transparent">{translations.getLabel('titleMobileApp')}</Button>
          </div>
          {this.state.activeTab === TABS.WEB
            ?
            <WebBackgroundTab
              isLoadingWeb={this.state.isLoadingWeb}
              isLoadingWebStartPage={this.state.isLoadingWebStartPage}
              handleUpload={this.handleUpload}
              toggleConfirmationModal={this.toggleConfirmationModal}
            />
            :
            <AppBackgroundTab
              isLoadingApp={this.state.isLoadingApp}
              handleUpload={this.handleUpload}
              toggleConfirmationModal={this.toggleConfirmationModal}
            />
          }

        </div>
        <Modal
          requestClose={this.toggleConfirmationModal}
          open={this.state.isConfirmationModalOpen}
          rightButtonProps={{ label: translations.getLabel('lblDelete'), onClick: this.onDelete, isLoading: this.state.isRemoveLoading }}
          leftButtonProps={{ label: translations.getLabel('cancel'), onClick: this.toggleConfirmationModal }}
          title={translations.getLabel('lblRemoveImage')}
        >
          <p className="confirmation-message">{translations.getLabel('lblDeleteImage')}</p>
        </Modal>
      </React.Fragment>
    );
  }
}

Background.contextType = ConfigContext;

Background.propTypes = {
  companyId: PropTypes.string.isRequired,
  match: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
};
