import React, { useContext, useEffect,useState } from 'react';
import { SelectPopup,VacationWidget } from 'react-ess-components';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import ConfigContext from '../../../../../../context/ConfigContext';
import { holdingAdminService } from '../../../../../../services';
import { translations } from '../../../../../../utils';
import Counter from '../counter/Counter';

export default function VacationCounters({ companyId, closePopups, allCounters: allCountersProp, isAllLoading }) {
  const context = useContext(ConfigContext);

  const [allCounters, setAllCounters] = useState([]);
  const [isAddingVacation, setIsAddingVacation] = useState(false);
  const [selectedCounters, setSelectedCounters] = useState([]);
  const [vacationCounters, setVacationCounters] = useState([]);
  const [isVacationLoading, setIsVacationLoading] = useState(true);

  const getCountersData = async () => {
    const vacationCounters = await holdingAdminService.getVacationCounters(context.config.holdingId, companyId);
    if (vacationCounters) {
      setVacationCounters(vacationCounters);
    }
    setAllCounters(allCountersProp);
    setIsVacationLoading(false);
  };

  useEffect(() => {
    getCountersData();
  }, [allCountersProp]); //eslint-disable-line react-hooks/exhaustive-deps

  const onChangeVacationCounters = (selectedCounters) => {
    setSelectedCounters(selectedCounters.splice(0, 3));
  };

  const handleSaveVacation = async () => {
    if (selectedCounters.length > 3) return toast.error(translations.getLabel('lblMaxSelection'));
    toggleSelectVacation();
    setIsVacationLoading(true);
    await holdingAdminService.updateVacationCounters(context.config.holdingId, companyId, { counters: selectedCounters });
    const vacationCounters = await holdingAdminService.getVacationCounters(context.config.holdingId, companyId);
    setIsVacationLoading(false);
    setVacationCounters(vacationCounters || []);
    setSelectedCounters([]);
  };

  const toggleSelectVacation = () => {
    const selection = [];

    if (!isAddingVacation) {
      vacationCounters.forEach((counter) => {
        selection.push(counter.id);
      });
    }

    setIsAddingVacation(!isAddingVacation);
    setSelectedCounters(selection);
  };


  return (
    <div>
      <div className="vacation-container">
        <p className="label">{translations.getLabel('lblConfigureVacationCounters')}</p>
        <Counter title={translations.getLabel('titleVacationCounters')} onEdit={toggleSelectVacation} >
          <VacationWidget
            vacation={vacationCounters}
            emptyLabel={translations.getLabel('lblNoVacation')}
            title={translations.getLabel('titleWidgetVacation')}
            totalLabel={translations.getLabel('lblTotal')}
            isLoading={isVacationLoading || isAllLoading}
          />
        </Counter>
      </div>

      <SelectPopup
        data={allCounters}
        popupOpen={isAddingVacation}
        isMultiple
        isModal
        selected={selectedCounters}
        title={translations.getLabel('titleAddCountersVacation')}
        requestClose={closePopups(toggleSelectVacation)}
        onValueSelected={onChangeVacationCounters}
        onSave={handleSaveVacation}
        acceptLabel={translations.getLabel('save')}
        cancelLabel={translations.getLabel('cancel')}
        isSearchEnabled={false}
      />
    </div >
  );
}

VacationCounters.propTypes = {
  companyId: PropTypes.string.isRequired,
  closePopups: PropTypes.func.isRequired,
  allCounters: PropTypes.array.isRequired,
  isAllLoading: PropTypes.bool,
};

VacationCounters.defaultProps = {
  isAllLoading: true,
};
