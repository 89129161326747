import React, { useEffect,useState } from 'react';
import { Button, Modal, SelectUserModal } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

import { rights as RIGHTS } from '../../../../../constants';
import { useToggle } from '../../../../../hooks';
import { companyAdminService } from '../../../../../services';
import { translations } from '../../../../../utils';
import ListItem from '../../../../listItem/ListItem';
import EditCompanyAdmin from './EditCompanyAdmin';

import './companyManagersTab.scss';

let didCancel = false;

const CompanyManagersTab = ({ companyId }) => {
  const [isLoadingAdmins, setIsLoadingAdmins] = useState(true);
  const [admins, setAdmins] = useState([]);
  const [users, setUsers] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [openAdmins, setOpenAdmins] = useState([]);
  const [removeAdminId, setRemoveAdminId] = useState(null);
  const [isRemoveModalOpen, toggleIsRemoveModalOpen] = useToggle(false);
  const [isRemoveAdminLoading, setIsRemoveAdminLoading] = useState(false);
  const [isAddingAdmin, toggleAddAdmin] = useToggle(false);
  const [isUsersLoading, setIsUsersLoading] = useState(true);
  const [isSavingChanges, setIsSavingChanges] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [hasUsers, setHasUsers] = useState(false);

  useEffect(() => {
    didCancel = false;
    const getAdminsAndDepartments = async () => {
      const result = await companyAdminService.getCompanyAdmins(companyId);
      const departments = await companyAdminService.getDepartments(companyId);
      if (!didCancel) {
        if (result) {
          setAdmins(result);
          setDepartments(departments || []);
        }
        setIsLoadingAdmins(false);
      }
    };

    const getUsers = async () => {
      const result = await companyAdminService.getCompanyUsers(companyId);
      if (!didCancel) {
        setIsUsersLoading(false);
        setHasUsers(!!result?.length);
        setUsers(result || []);
      }
    };

    getAdminsAndDepartments();
    getUsers();
    return () => {
      didCancel = true;
    };
  }, [companyId]);


  const toggleAdmin = id => () => {
    if (openAdmins.includes(id)) {
      setOpenAdmins(openAdmins.filter(adminId => adminId !== id));
    } else {
      setOpenAdmins([...openAdmins, id]);
    }
  };

  const toggleRemoveModal = (id = null) => () => {
    setRemoveAdminId(id);
    toggleIsRemoveModalOpen();
  };

  const deleteAdmin = async () => {
    setIsRemoveAdminLoading(true);
    await companyAdminService.removeCompanyAdmin(companyId, removeAdminId);
    if (!didCancel) {
      setAdmins(admins.filter(admin => admin.userId !== removeAdminId));
      setOpenAdmins(openAdmins.filter(adminId => adminId !== removeAdminId));
      toggleRemoveModal()();
      setIsRemoveAdminLoading(false);
    }
  };

  const addNewAdmin = async (userId) => {
    setIsSavingChanges(true);
    await companyAdminService.postCompanyAdmin(companyId, userId);
    const admins = await companyAdminService.getCompanyAdmins(companyId);
    if (!didCancel) {
      if (admins) {
        setAdmins(admins);
        setIsSavingChanges(false);
      }
      closeAddAdmin();
      toggleAdmin(userId)();
    }
  };

  const onSave = async (adminId, rights) => {
    const result = await companyAdminService.updateCompanyAdmin(companyId, adminId, rights);
    if (result) {
      const admins = await companyAdminService.getCompanyAdmins(companyId);
      if (!didCancel) {
        if (admins) setAdmins(admins);
        toggleAdmin(adminId)();
      }
      return true;
    }
    return false;
  };

  const searchUser = async (search) => {
    setIsSearching(true);
    const users = await companyAdminService.getCompanyUsers(companyId, search);
    if (!didCancel) {
      setIsSearching(false);
      setUsers(users || []);
    }
  };

  const filterUsers = () => {
    const existingAdmins = admins.map(admin => admin.userId);
    return users.filter(user => !existingAdmins.includes(user.id));
  };

  const renderAdmin = (admin) => {
    const isOpen = openAdmins.includes(admin.userId);
    return <ListItem
      key={admin.userId}
      title={`${admin.lastName} ${admin.firstName}`}
      onIconClick={isOpen ? toggleRemoveModal(admin.userId) : toggleAdmin(admin.userId)}
      icon={isOpen ? 'TrashIcon' : 'EditIcon'}
      iconColor={isOpen ? 'error' : 'primary'}
      actionLabel={isOpen ? translations.getLabel('lblRemoveAdmin') : ''}
      avatarProps={{ firstName: admin.firstName, lastName: admin.lastName, source: admin.profilePictureUrl }}
    >
      {isOpen
        ? (
          <EditCompanyAdmin
            admin={admin}
            departments={departments}
            onSave={onSave}
            onCancel={toggleAdmin}
          />
        ) : (
          <div className="admin-rights-view">
            {admin.departmentRights.map(renderDepartmentsView)}
          </div>
        )
      }
    </ListItem>;
  };

  const renderDepartmentsView = (rights) => {
    return (<p key={rights.departmentId} className="department-rights">
      <span className="department-name">{rights.departmentName}</span>
      {rights.actions.length > 0 && <span className="department-separator"> - </span>}
      {rights.actions.length > 0 && <span className="rights">{rights.actions.map(action => RIGHTS[action] ? translations.getLabel(RIGHTS[action].label) : '-').join(', ')}</span>}
    </p>);
  };

  const closeAddAdmin = () => {
    searchUser();
    toggleAddAdmin();
  };

  const renderSkeleton = (key) => <div key={key} className="loading-wrapper"><Skeleton width="100%" height="10.5rem" /></div>;

  const filteredUsers = filterUsers();
  return (
    <div className="company-admins-container">
      <div className="company-admin-controls">
        {isUsersLoading
          ? <Skeleton width="20rem" height="4.8rem" />
          : hasUsers && <Button disabled={isAddingAdmin || !filteredUsers.length} onClick={toggleAddAdmin}>{translations.getLabel('btnAddManager')}</Button>
        }
      </div>
      {isLoadingAdmins
        ?
        [1, 2, 3].map(renderSkeleton)
        :
        <div>
          {admins.map(renderAdmin)}
        </div>}
      <Modal
        open={isRemoveModalOpen}
        title={translations.getLabel('lblRemoveAdmin')}
        requestClose={toggleRemoveModal()}
        leftButtonProps={{
          label: translations.getLabel('cancel'),
          onClick: toggleRemoveModal(),
        }}
        rightButtonProps={{
          label: translations.getLabel('lblRemoveAdmin'),
          isLoading: isRemoveAdminLoading,
          onClick: deleteAdmin,
        }}
      >
        <div className="warning-message">{translations.getLabel('lblDeleteManager')}</div>
      </Modal>
      {
        isAddingAdmin &&
        <SelectUserModal
          cancelLabel={translations.getLabel('cancel')}
          isLoading={isSearching}
          isMultiSelect={false}
          isSaveLoading={isSavingChanges}
          onSearch={searchUser}
          searchLabel={translations.getLabel('lblSearchEmployee')}
          onSelect={addNewAdmin}
          requestClose={closeAddAdmin}
          saveLabel={translations.getLabel('lblAdd')}
          title={translations.getLabel('lblNewManager')}
          users={filteredUsers}
        />
      }
    </div>
  );
};

CompanyManagersTab.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default CompanyManagersTab;
