import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ConfigContext from '../../../../context/ConfigContext';
import { companyAdminService, holdingAdminService } from '../../../../services';
import AdjustViewingPeriod from './components/AdjustViewingPeriod';
import ShiftTime from './components/ShiftTime';
import VacationAvailability from './components/VacationAvailability';

let didCancel = false;

const SetTimePeriods = (props) => {
  const context = useContext(ConfigContext);
  const [periods, setPeriods] = useState(null);
  const [isPeriodsLoading, setIsPeriodsLoading] = useState(true);

  useEffect(() => {
    didCancel = false;

    if (props.companyId) {
      const initConfig = async () => {
        const result = await holdingAdminService.getCompanyConfig(props.companyId);
        if (result) {
          context.updateCompanyConfig(result.config);
        }
      };
      if (!didCancel) {
        initConfig();
        initPeriods();
      }
    }
    return () => {
      didCancel = true;
    };
  }, [props.companyId]);  //eslint-disable-line react-hooks/exhaustive-deps

  const initPeriods = async () => {
    setIsPeriodsLoading(true);
    const result = await companyAdminService.getPeriods(props.companyId);
    if (result) setPeriods(result);
    setIsPeriodsLoading(false);
  };

  return (
    <div className="counters-tab">
      <VacationAvailability companyId={props.companyId} holdingId={props.holdingId} periods={periods} isLoading={isPeriodsLoading} onSaved={initPeriods} />
      <ShiftTime showTodayUntil hideEndDate holdingId={props.holdingId} companyId={props.companyId} periods={periods} isLoading={isPeriodsLoading} onSaved={initPeriods} />
      <AdjustViewingPeriod showTodayFrom holdingId={props.holdingId} companyId={props.companyId} periods={periods} isLoading={isPeriodsLoading} onSaved={initPeriods} />
    </div>
  );
};

SetTimePeriods.propTypes = {
  companyId: PropTypes.string.isRequired,
  holdingId: PropTypes.string.isRequired,
};

export default SetTimePeriods;
