import {
  BackgroundTab,
  ColorsTab,
  CompanyEmployeesTab,
  CompanyManagersTab,
  CounterConfigurationTab,
  CountersTab,
  DashboardCountersTab,
  HACompanyAdminsTab,
  LogoTab,
  RequestCountersTab,
  ScheduleConfigTab,
  SelfRosterTab,
  SetTimePeriods,
  TimeRegistrationLocationsTab,
  TimeRegistrationTab,
  TransactionsTab,
} from '../components';
import { translations } from '../utils';

export const companyAdminTabStructure = [
  {
    url: 'company-admins',
    label: translations.getLabel('titleCompanyAdmins'),
    component: HACompanyAdminsTab,
  },
  {
    url: 'configuration',
    label: translations.getLabel('titleCompanyConfiguration'),
    sideTabs: [
      {
        url: 'schedule',
        label: translations.getLabel('titleSchedule'),
        component: ScheduleConfigTab,
      },
      {
        url: 'dashboard-counters',
        label: translations.getLabel('lblDashboardCounters'),
        component: DashboardCountersTab,
      },
      {
        url: 'counters',
        label: translations.getLabel('lblCounters'),
        component: CountersTab,
      },
      {
        url: 'counterConfiguration',
        label: translations.getLabel('lblCounterConfiguration'),
        component: CounterConfigurationTab,
      },
      {
        url: 'requestCounters',
        label: translations.getLabel('lblRequestCounters'),
        component: RequestCountersTab,
      },
      {
        url: 'timeRegistration',
        label: translations.getLabel('titleTimeRegistration'),
        component: TimeRegistrationTab,
      },
      {
        url: 'transactions',
        label: translations.getLabel('titleTransactions'),
        component: TransactionsTab,
      },
    ],
  },
  {
    url: 'company-users',
    label: translations.getLabel('titleCompanyUsers'),
    sideTabs: [
      {
        url: 'company-managers',
        label: translations.getLabel('titleManagers'),
        component: CompanyManagersTab,
      },
      {
        url: 'company-employees',
        label: translations.getLabel('titleEmployees'),
        component: CompanyEmployeesTab,
      },
    ],
  },
  {
    url: 'branding',
    label: translations.getLabel('titleCompanyBranding'),
    sideTabs: [
      {
        url: 'logo',
        label: translations.getLabel('lblLogo'),
        component: LogoTab,
      },
      {
        url: 'colors',
        label: translations.getLabel('lblColors'),
        component: ColorsTab,
      },
      {
        url: 'background',
        label: translations.getLabel('lblBackground'),
        component: BackgroundTab,
      },
    ],
  },
  {
    url: 'extra-setup',
    label: translations.getLabel('titleExtraSetup'),
    sideTabs: [
      {
        url: 'time-registration-locations',
        label: translations.getLabel('titleTimeRegistrationLocations'),
        component: TimeRegistrationLocationsTab,
      },
      {
        url: 'set-time-periods',
        label: translations.getLabel('titleSetTimePeriods'),
        component: SetTimePeriods,
      },
      {
        url: 'self-rostering',
        label: translations.getLabel('titleSelfRostering'),
        component: SelfRosterTab,
      },
    ],
  },
];
