import React, { useEffect,useState } from 'react';
import { SelectPopup } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

import { holdingAdminService } from '../../../../../services';
import { translations } from '../../../../../utils';
import EditItem from '../../../../editItem/EditItem';

export default function VacationTypes(props) {
    const [transactionTypes, setTransactionTypes] = useState([]);
    const [allTransactionTypes, setAllTransactionTypes] = useState([]);
    const [selectedTransactionTypes, setSelectedTransactionTypes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isEditOpen, setIsEditOpen] = useState(false);

    useEffect(() => {
        getTransactionTypes();
    }, [props.companyId]); //eslint-disable-line react-hooks/exhaustive-deps

    const getTransactionTypes = async () => {
        setIsLoading(true);
        const result = await holdingAdminService.getTransactionTypes(props.holdingId, props.companyId);
        const resultAll = await holdingAdminService.getAllTransactionTypes(props.holdingId);
        if (result) {
            setTransactionTypes(result);

            const ids = result.map(type => type.id);
            setSelectedTransactionTypes(ids);
        }
        if (resultAll) {
            const formattedResult = resultAll.map(type => ({ value: type.id, label: type.nameEn }));
            setAllTransactionTypes(formattedResult);
        }
        setIsLoading(false);
    };

    const renderRow = (transactionType) => (
        <tr key={transactionType.id}>
            <td>{transactionType.nameEn}</td>
        </tr>
    );

    const openEditModal = () => setIsEditOpen(true);
    const closeEditModal = () => {
        setSelectedTransactionTypes(transactionTypes.map(type => type.id));
        setIsEditOpen(false);
    };
    const onChange = (transactionTypes) => setSelectedTransactionTypes(transactionTypes);

    const onSave = async () => {
        setIsLoading(true);
        setIsEditOpen(false);
        await holdingAdminService.updateTransactionTypes(props.holdingId, props.companyId, { transactionTypeIds: selectedTransactionTypes });
        getTransactionTypes();
        setIsLoading(false);
    };

    return (
        <div>
            <EditItem title={translations.getLabel('titleTransactionTypesSchedule')} label={translations.getLabel('lblTransactionTypesCompanyScheduleDescription')} onClick={openEditModal} >
                {(transactionTypes.length <= 0 && !isLoading) ?
                    null
                    :
                    <table className="table" >
                        <thead>
                            <tr>
                                <th>{translations.getLabel('titleTransactionTypesSchedule')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                isLoading
                                    ?
                                    <tr><td> <Skeleton width="100%" height="4rem" /></td></tr>
                                    :
                                    transactionTypes.map(renderRow)
                            }
                        </tbody>
                    </table>
                }
                {
                    !isLoading && !transactionTypes.length && <p>{translations.getLabel('lblNoTransactionTypes')}</p>
                }
            </EditItem>

            <SelectPopup
                data={allTransactionTypes}
                popupOpen={isEditOpen}
                isMultiple
                isModal
                selected={selectedTransactionTypes}
                title={translations.getLabel('titleEditTransactionType')}
                requestClose={closeEditModal}
                onValueSelected={onChange}
                onSave={onSave}
                acceptLabel={translations.getLabel('save')}
                cancelLabel={translations.getLabel('cancel')}
                isSearchEnabled={false}
                isAllowedEmpty
            />
        </div>
    );
}

VacationTypes.propTypes = {
    companyId: PropTypes.string.isRequired,
    holdingId: PropTypes.string.isRequired,
};

