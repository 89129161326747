import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { routes,storageItems } from './constants';
import ConfigContext from './context/ConfigContext';
import AuthorizedLayout from './routes/AuthorizedLayout';
import AuthorizedRoute from './routes/AuthorizedRoute';
import UnauthorizedLayout from './routes/UnauthorizedLayout';
import UnauthorizedRoute from './routes/UnauthorizedRoute';
import { configService, superAdminService } from './services';
import { LOGGED_OUT_ERROR } from './utils/authInterceptor';

import 'react-toastify/dist/ReactToastify.css';
import 'react-ess-components/build/index.css';
import './toast.scss';
import './app.scss';

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      companyConfig: {},
      config: {},
      holdings: [],
      isLoading: true,
      isSuperUser: false,
      me: {},
      allCounters: {},
    };
  }

  async componentDidMount() {
    const _state = { isLoading: false };
    try {
      if (localStorage.getItem(storageItems.IS_SUPER_ADMIN) !== 'true' && !window.location.pathname.includes(routes.loginSuperAdmin)) {
        const result = await configService.getMe();
        _state.me = result.me;
        localStorage.setItem(storageItems.IS_LOGGED_IN, 'true');
      }
    } catch (err) {
      if (err.response === LOGGED_OUT_ERROR) {
        window.location.pathname = routes.login;
      }
    }
    if (localStorage.getItem(storageItems.IS_LOGGED_IN) === 'true') {
      let holdings;
      let configResult;
      if (localStorage.getItem(storageItems.IS_SUPER_ADMIN) === 'true') {
        this.updateIsSuperUser(true);
        holdings = await superAdminService.getHoldings();
      } else {
        configResult = await configService.getHoldingConfig(localStorage.getItem(storageItems.HOLDING));
        if (configResult) localStorage.setItem(storageItems.HOLDING, configResult.config?.url);
      }
      if (configResult) _state.config = configResult.config;
      if (holdings) _state.holdings = holdings.data;
      this.setState(_state);
    } else {
      this.setState({ isLoading: false });
    }
  }

  /***************
   * START CONTEXT UPDATE CALLS
   ***************/
  updateConfig = (config) => this.setState({ config: { ...this.state.config, ...config } });

  updateCompanyConfig = (companyConfig) => this.setState({ companyConfig });

  updateAllCounters = (allCounters) => this.setState({ allCounters });

  updateIsSuperUser = (isSuperUser) => this.setState({ isSuperUser });

  updateHoldings = (holdings) => this.setState({ holdings });

  updateCompanies = (companies) => this.setState({ companies });

  updateMe = (me) => this.setState({ me });

  clearContext = () => this.setState({ config: {}, holdings: [], isSuperUser: false });
  /***************
   * END CONTEXT UPDATE CALLS
   ***************/

  render() {
    // TODO: make a good looking loading state
    if (this.state.isLoading) return <div className="loading-indicator">Loading... </div>;
    return (
      <React.Fragment>
        <ToastContainer closeOnClick={false} className="toast-container" toastClassName="toast" bodyClassName="toast-body" progressClassName="toast-progress" />
        <ConfigContext.Provider value={
          {
            config: this.state.config,
            updateConfig: this.updateConfig,
            companyConfig: this.state.companyConfig,
            updateCompanyConfig: this.updateCompanyConfig,
            isSuperUser: this.state.isSuperUser,
            updateIsSuperUser: this.updateIsSuperUser,
            holdings: this.state.holdings,
            updateHoldings: this.updateHoldings,
            me: this.state.me,
            updateMe: this.updateMe,
            companies: this.state.companies,
            updateCompanies: this.updateCompanies,
            allCounters: this.state.allCounters,
            updateAllCounters: this.updateAllCounters,
          }}>
          <BrowserRouter>
            <Switch>
              <UnauthorizedRoute path="/auth" component={UnauthorizedLayout} />
              <AuthorizedRoute path="/" component={AuthorizedLayout} />
            </Switch>
          </BrowserRouter>
        </ConfigContext.Provider>
      </React.Fragment>
    );
  }
};

export default App;
