import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import CounterConfiguration from './counter/CounterConfiguration';
import SelfRosterConfiguration from './selfRoster/SelfRosterConfiguration';
import TransactionConfiguration from './transactions/TransactionConfiguration';

import './CountersConfigurationTab.scss';

class CountersConfigurationTab extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <CounterConfiguration holdingId={this.props.holdingId} />
        <TransactionConfiguration holdingId={this.props.holdingId} />
        <SelfRosterConfiguration holdingId={this.props.holdingId} />
      </>
    );
  }
}

CountersConfigurationTab.propTypes = {
  holdingId: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
};

export default CountersConfigurationTab;
