import React, { useContext,useEffect, useState } from 'react';
import { InputDateField,Modal } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { format, isBefore,parseISO } from 'date-fns';
import PropTypes from 'prop-types';

import ConfigContext from '../../../../../context/ConfigContext';
import { useToggle } from '../../../../../hooks';
import { holdingAdminService } from '../../../../../services';
import { translations } from '../../../../../utils';
import EditItem from '../../../../editItem/EditItem';
import CountersConfiguration from '../components/countersConfiguration/CountersConfiguration';

const PAY_PERIOD_TYPES = {
  CALENDAR_MONTH: 'CALENDAR_MONTH',
  FOUR_WEEK_RANGE: 'FOUR_WEEK_RANGE',
};

let didCancel = false;

export default function CounterConfigurationTab(props) {
  const context = useContext(ConfigContext);
  const payPeriod = context.companyConfig.payPeriod;
  const [isInitialLoading, setInitialLoading] = useState(true);
  const [isModalOpen, toggleModalOpen] = useToggle();
  const [newPayPeriod, setNewPayPeriod] = useState(payPeriod?.type);
  const [isLoading, setIsLoading] = useState(false);
  const [newStartDate, setNewStartDate] = useState({
    date: payPeriod?.start ? parseISO(payPeriod?.start) : new Date(),
  });

  useEffect(() => {
    didCancel = false;
    initConfig();

    return () => {
      didCancel = true;
    };
  }, [props.companyId]);//eslint-disable-line react-hooks/exhaustive-deps

  const initConfig = async () => {
    const result = await holdingAdminService.getCompanyConfig(props.companyId);
    if (result && !didCancel) {
      await context.updateCompanyConfig(result.config);
    }
    if (!didCancel) setInitialLoading(false);
  };

  useEffect(() => {
    const payPeriod = context.companyConfig.payPeriod;
    setNewPayPeriod(payPeriod?.type);
    setNewStartDate({
      date: payPeriod?.start ? parseISO(payPeriod?.start) : new Date(),
    });
  }, [context.companyConfig.payPeriod]);//eslint-disable-line react-hooks/exhaustive-deps

  const onSave = async () => {
    setIsLoading(true);
    const result = await holdingAdminService.updateCompanyConfig(props.holdingId, props.companyId, { payPeriod: { type: newPayPeriod, start: format(newStartDate.date, 'yyyy-MM-dd') } });
    context.updateCompanyConfig(result.config);
    if (!didCancel) {
      setIsLoading(false);
      toggleModalOpen();
    }
  };

  const { CALENDAR_MONTH: month, FOUR_WEEK_RANGE: fourWeek } = PAY_PERIOD_TYPES;

  const getLabel = () => {
    const payPeriod = context.companyConfig.payPeriod;
    return (
      `${translations.getLabel(payPeriod?.type === fourWeek ? 'lblFourWeekInterval' : 'lblMonthInterval')} `
      + `${payPeriod?.type === fourWeek ? translations.getLabel('lblIntervalStartingOn', { date: payPeriod?.start ? format(parseISO(payPeriod?.start), 'dd-MM-yyyy') : new Date() }) : ''}`
    );
  };

  const filterDate = (date) => isBefore(date, new Date());

  return (
    <div className="counters-tab">
      <EditItem title={translations.getLabel('titlePayPeriod')} label={translations.getLabel('lblPayPeriod')} onClick={toggleModalOpen}>
        <p className="weekday-value">
          {
            isInitialLoading ?
              <Skeleton width={150} />
              :
              getLabel()
          }
        </p>
      </EditItem>

      <CountersConfiguration holdingId={props.holdingId} companyId={props.companyId} />

      <Modal
        title={translations.getLabel('titlePayPeriod')}
        open={isModalOpen}
        requestClose={toggleModalOpen}
        rightButtonProps={{ label: translations.getLabel('save'), onClick: onSave, isLoading: isLoading, disabled: newPayPeriod === fourWeek && !newStartDate?.date }}
        leftButtonProps={{ label: translations.getLabel('cancel'), onClick: toggleModalOpen }}
      >
        <div className="popup-startofweek-container">
          {translations.getLabel('lblPayPeriod')}
          <label htmlFor={month}>
            <input type="radio" onChange={() => setNewPayPeriod(month)} id={month} checked={newPayPeriod === month} /> {translations.getLabel('lblMonthInterval')} {translations.getLabel('lblMonthExample')}
          </label>
          <label htmlFor={fourWeek}>
            <input type="radio" onChange={() => setNewPayPeriod(fourWeek)} id={fourWeek} checked={newPayPeriod === fourWeek} /> {translations.getLabel('lblFourWeekInterval')}
          </label>
        </div>

        {
          newPayPeriod === fourWeek &&
          <div className="calendar-input">
            <p className="start-label">{translations.getLabel('lblStartingOn')}</p>
            <InputDateField emptyIcon value={newStartDate} onChange={setNewStartDate} filterDate={(date) => filterDate(date)} />
          </div>
        }
      </Modal>
    </div >
  );
}

CounterConfigurationTab.propTypes = {
  companyId: PropTypes.string.isRequired,
  holdingId: PropTypes.string.isRequired,
};
