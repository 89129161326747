import React from 'react';
import { Icon } from 'react-ess-components';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { defaultLogo } from '../../assets';
import { storageItems } from '../../constants';
import { loginService } from '../../services';

import './navbar.scss';

export const NAV_BAR_HEIGHT = 64;

export class Navbar extends React.PureComponent {
  handleLogout = () => {
    try {
      loginService.logout(localStorage.getItem(storageItems.IS_SUPER_ADMIN) === 'true');
      localStorage.removeItem(storageItems.IS_LOGGED_IN);
      localStorage.removeItem(storageItems.IS_SUPER_ADMIN);
      localStorage.removeItem(storageItems.HOLDING, '');
      return this.props.history.push('/');
    } catch (err) {
      localStorage.removeItem(storageItems.IS_LOGGED_IN);
      return this.props.history.push('/');
    }
  }

  render() {
    return (<div className="app-header" style={{
      heigth: `${NAV_BAR_HEIGHT / 10}rem`,
    }}>
      <div className="app-header-left">
        <img src={this.props.holding.logoUrl} alt={`Logo ${this.props.holding.name}`} />
        <h2 className="company-name">{this.props.holding.name}</h2>
      </div>
      <div className="app-header-right">
        <button className="navbar-button" onClick={this.handleLogout}><Icon tag="LogoutIcon" /></button>
      </div>
    </div>);
  }
}

Navbar.propTypes = {
  holding: PropTypes.object,
  history: PropTypes.object.isRequired,
};

Navbar.defaultProps = {
  holding: {
    name: 'SP-Expert Connect',
    logoUrl: defaultLogo,
  },
};

export default withRouter(Navbar);
