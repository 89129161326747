import React from 'react';
import { Redirect,Route, Switch } from 'react-router-dom';

import { routes } from '../constants';
import { Login } from '../scenes';

const UnauthorizedLayout = (props) => {
  return (
    <div className="unauth-layout">
      <section className="unauth-action-view">
        <Switch>
          <Route path={routes.login} exact component={Login} />
          <Route path={routes.loginSuperAdmin} exact component={Login} />
          <Redirect to={routes.login} />
        </Switch>
      </section>
    </div>
  );
};

export default UnauthorizedLayout;
