import { useEffect, useState } from 'react';

const useSelectArray = (initial, limit) => {
    const [selectArray, setSelectArray] = useState(initial);
    const [hasChanged, sethasChanged] = useState(false);

    useEffect(() => {
        if (JSON.stringify(initial) !== JSON.stringify(selectArray)) sethasChanged(true);
        else sethasChanged(false);
    }, [initial, selectArray]);

    const select = (value) => {
        if (selectArray.includes(value)) setSelectArray(selectArray.filter(v => v !== value));
        else {
            if (!limit || selectArray.length < limit) setSelectArray([...selectArray, value]);
        }
    };

    const clear = () => setSelectArray([]);
    const reset = () => setSelectArray(initial);
    const set = (value) => setSelectArray(value);

    return {
        select,
        clear,
        reset,
        set,
        selectArray,
        hasChanged,
    };
};

export default useSelectArray;