import React, { useContext,useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

import ConfigContext from '../../../../context/ConfigContext';
import { companyAdminService } from '../../../../services';
import ListItem from '../../../listItem/ListItem';

const HACompaniesTab = ({ history }) => {
  const context = useContext(ConfigContext);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    let didCancel = false;
    const getCompanies = async () => {
      setIsLoading(!context.companies.length);
      const result = await companyAdminService.getCompanies();
      if (result) {
        context.updateCompanies(result);
      }
      if (!didCancel) setIsLoading(false);
    };
    getCompanies();
    return () => {
      didCancel = true;
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const goToDetailsPage = (companyId) => () => {
    history.push(`/company-admin/${companyId}/company-admins`);
  };

  const renderSkeleton = (key) => <div key={key} className="loading-wrapper"><Skeleton width="100%" height="10.5rem" /></div>;

  const renderCompany = (company) => (
    <ListItem
      key={company.id}
      avatarProps={{ isCompany: !company.logoUrl, source: company.logoUrl }}
      title={company.name}
      description={company.id}
      onClick={goToDetailsPage(company.id)} />
  );


  return (
    <div className="companies-container">
      {isLoading
        ? [1, 2, 3].map(renderSkeleton)
        : (
          <div>
            {context.companies.map(renderCompany)}
          </div>
        )}
    </div >
  );
};

HACompaniesTab.propTypes = {
  history: PropTypes.object.isRequired,
};

export default HACompaniesTab;
