import React, { Component } from 'react';

import TabNavigator from '../../components/tabNavigator/TabNavigator';
import ConfigContext from '../../context/ConfigContext';
import { holdingAdminTabStructure } from '../../routes/HoldingAdminTabStructure';

export default class HoldingAdminConfiguration extends Component {
  render() {
    return (
      <div className="page fixed-width">
        <h2>{this.context.config.name}</h2>
        <TabNavigator {...this.props} holdingId={this.context.config.holdingId} tabsStructure={holdingAdminTabStructure} routePrefix="/holding-admin" />
      </div>
    );
  }
}

HoldingAdminConfiguration.contextType = ConfigContext;

