import { useEffect,useState } from 'react';

const useForm = (initial, shouldClear = false) => {
  const [form, setForm] = useState(initial);
  const [error, setError] = useState(null);
  const setFormAttribute = (value, name) => {
    setForm({ ...form, [name]: value });
  };

  const clearForm = () => {
    setForm(initial);
  };

  const clearError = () => {
    setError(null);
  };

  useEffect(() => {
    return () => {
      clearForm();
      clearError();
    };
  }, [shouldClear]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    form,
    setForm,
    setFormAttribute,
    error,
    setError,
    clearForm,
  };
};

export default useForm;
