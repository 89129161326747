export const companySettings = {
  deleteCompany: {
    label: 'lblDeleteCompanyWarning',
    tag: 'deleteCompany',
  },
  modules: {
    labelTitle: 'titleModulesUse',
    labelDescription: 'lblModulesUse',
    tag: 'modules',
    types: {
      // chat: {
      //   labelTitle: 'titleChat',
      //   labelDescription: 'lblModulesChat',
      //   tag: 'chat',
      // },
      library: {
        labelTitle: 'titleLibrary',
        labelDescription: 'lblModulesLibrary',
        tag: 'library',
      },
      news: {
        labelTitle: 'titleNews',
        labelDescription: 'lblModulesNews',
        tag: 'news',
      },
      schedule: {
        labelTitle: 'titleSchedule',
        labelDescription: 'lblModulesSchedule',
        tag: 'schedule',
      },
      selfRoster: {
        labelTitle: 'titleSelfRostering',
        labelDescription: 'lblModulesSelfRostering',
        tag: 'selfRoster',
      },
      counters: {
        labelTitle: 'titleCounters',
        labelDescription: 'lblModulesCounters',
        tag: 'counters',
      },
      timeRegistration: {
        labelTitle: 'titleTimeRegistration',
        labelDescription: 'lblModulesTimeRegistration',
        tag: 'timeRegistration',
      },
      shiftOffer: {
        labelTitle: 'titleShiftOffer',
        labelDescription: 'lblModulesShiftOffer',
        tag: 'shiftOffer',
      },
      shiftSwap: {
        labelTitle: 'titleShiftSwap',
        labelDescription: 'lblModulesShiftSwap',
        tag: 'shiftSwap',
      },
    },
  },
  requestApproval: {
    labelDescription: 'lblPersonalRequestApprovalDescription',
    labelTitle: 'titlePersonalRequestApproval',
    tag: 'approveOwnRequests',
  },
  showSchedule: {
    labelDescription: 'lblSeeColleagueScheduleDescription',
    labelTitle: 'titleSeeColleagueSchedule',
    tag: 'showColleaguesSchedules',
  },
  updateCompany: {
    label: 'titleUpdateCompany',
    tag: 'updateCompany',
  },
  availabilityScheduleLevel: {
    labelTitle: 'titleAvailabilityRequestScheduleLevel',
    labelDescription: 'lblAvailabilityRequestScheduleLevel',
    tag: 'availabilityScheduleLevel',
  },
  vacationRequestScheduleLevel: {
    labelTitle: 'titleVacationRequestScheduleLevel',
    labelDescription: 'lblVacationRequestScheduleLevel',
    tag: 'vacationRequestScheduleLevel',
  },
  schoolScheduleLevel: {
    labelTitle: 'titleSchoolRosterScheduleLevel',
    labelDescription: 'lblSchoolRosterScheduleLevel',
    tag: 'schoolScheduleLevel',
  },
};
