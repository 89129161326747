export const holdingBrandingColors = {
  headerColor: '#3399FF',
  primaryColor: '#3399FF',
};

export const brandingColors = {
  headerColor: '#3399FF',
  primaryColor: '#3399FF',
  navbarTextColor: '#FFFFFF',
  navbarTextHoverColor: '#FFFFFF',
  navbarIconColor: '#FFFFFF',
  phonePageTitleColor: '#FFFFFF',
  phoneIconsColor: '#FFFFFF',
};

export const brandingColorsLabels = {
  headerColor: { title: 'lblHeader', description: 'lblHeaderColorDescription' },
  primaryColor: { title: 'lblPrimaryColor', description: 'lblPrimaryColorDescription' },
  navbarTextColor: { title: 'lblHeaderTextColor', description: 'lblHeaderTextColorDescription' },
  navbarTextHoverColor: { title: 'lblHeaderTextHoverColor', description: 'lblHeaderTextHoverColorDescription' },
  navbarIconColor: { title: 'lblNavbarIconColor', description: 'lblNavbarIconColorDescription' },
  phonePageTitleColor: { title: 'lblHeaderPhoneTitleColor', description: 'lblHeaderPhoneTitleColorDescription' },
  phoneIconsColor: { title: 'lblHeaderPhoneIconsColor', description: 'lblHeaderPhoneIconsColorDescription' },
};
