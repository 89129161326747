import React, { useEffect,useState } from 'react';
import { SelectPopup } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

import { holdingAdminService } from '../../../../../../services';
import { translations } from '../../../../../../utils';
import EditItem from '../../../../../editItem/EditItem';

export default function VacationTypes(props) {
  const [vacationTypes, setVacationTypes] = useState([]);
  const [allVacationTypes, setAllVacationTypes] = useState([]);
  const [selectedVacationTypes, setSelectedVacationTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isEditOpen, setIsEditOpen] = useState(false);

  useEffect(() => {
    getVacationTypes();
  }, [props.companyId]); //eslint-disable-line react-hooks/exhaustive-deps

  const getVacationTypes = async () => {
    setIsLoading(true);
    const result = await holdingAdminService.getVacationTypes(props.holdingId, props.companyId);
    const resultAll = await holdingAdminService.getAllVacationTypes(props.holdingId);
    if (result) {
      setVacationTypes(result);

      const ids = result.map(type => type.holdingVacationTypeId);
      setSelectedVacationTypes(ids);
    }
    if (resultAll) {
      const formattedResult = resultAll.map(type => ({ value: type.id, label: type.descriptionEn }));
      setAllVacationTypes(formattedResult);
    }
    setIsLoading(false);
  };

  const renderRow = (vacationType) => (
    <tr key={vacationType.holdingVacationTypeId}>
      <td>{vacationType.descriptionEn}</td>
    </tr>
  );

  const openEditModal = () => setIsEditOpen(true);
  const closeEditModal = () => {
    setSelectedVacationTypes(vacationTypes.map(type => type.holdingVacationTypeId));
    setIsEditOpen(false);
  };
  const onChange = (vacationTypes) => setSelectedVacationTypes(vacationTypes);

  const onSave = async () => {
    setIsLoading(true);
    setIsEditOpen(false);
    await holdingAdminService.updateVacationTypes(props.holdingId, props.companyId, { holdingVacationTypeIds: selectedVacationTypes });
    getVacationTypes();
    setIsLoading(false);
  };

  return (
    <div>
      <EditItem title={translations.getLabel('titleVacationTypesSchedule')} label={translations.getLabel('lblVacationTypesCompanyScheduleDescription')} onClick={openEditModal} >
        {(vacationTypes.length <= 0 && !isLoading) ?
          null
          :
          <table className="table" >
            <thead>
              <tr>
                <th>{translations.getLabel('titleVacationTypesSchedule')}</th>
              </tr>
            </thead>
            <tbody>
              {
                isLoading
                  ?
                  <tr><td> <Skeleton width="100%" height="4rem" /></td></tr>
                  :
                  vacationTypes.map(renderRow)
              }
            </tbody>
          </table>
        }
        {
          !isLoading && !vacationTypes.length && <p>{translations.getLabel('lblNoVacationTypes')}</p>
        }
      </EditItem>

      <SelectPopup
        data={allVacationTypes}
        popupOpen={isEditOpen}
        isMultiple
        isModal
        selected={selectedVacationTypes}
        title={translations.getLabel('titleEditVacationType')}
        requestClose={closeEditModal}
        onValueSelected={onChange}
        onSave={onSave}
        acceptLabel={translations.getLabel('save')}
        cancelLabel={translations.getLabel('cancel')}
        isSearchEnabled={false}
        isAllowedEmpty
      />
    </div>
  );
}

VacationTypes.propTypes = {
  companyId: PropTypes.string.isRequired,
  holdingId: PropTypes.string.isRequired,
};
