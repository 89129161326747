import React, { useEffect,useState } from 'react';
import { Button, Icon, IconButton, Modal } from 'react-ess-components';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { brandingColors, brandingColorsLabels } from '../../../../../constants';
import { useToggle } from '../../../../../hooks';
import { companyAdminService } from '../../../../../services';
import { translations, validation } from '../../../../../utils';
import ColorInput from './ColorInput';

import './ColorsTab.scss';

const ColorsTab = ({ companyId }) => {
  const [isEditing, toggleIsEditing] = useToggle(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isReverting, setIsReverting] = useState(false);
  const [isModalOpen, toggleModal] = useToggle(false);
  const [colors, setColors] = useState({ ...brandingColors });

  useEffect(() => {
    let didCancel = false;
    const initCompanyPreferences = async () => {
      const preferences = await companyAdminService.getCompanyPreferences(companyId);
      if (preferences) {
        const colors = Object.keys(brandingColors).reduce((accu, colorKey) => {
          if (preferences[colorKey]) return { ...accu, [colorKey]: preferences[colorKey] };
          return accu;
        }, {});
        if (!didCancel) setColors(colors);
      }
      if (!didCancel) setIsLoading(false);
    };

    initCompanyPreferences();
    return () => {
      didCancel = true;
    };
  }, [companyId]);


  const save = async () => {
    await companyAdminService.patchCompanyPreferences(companyId, colors);
    toggleIsEditing();
  };

  const returnToDefault = async () => {
    setIsReverting(true);
    await companyAdminService.patchCompanyPreferences(companyId, brandingColors);
    setIsReverting(false);
    setColors({ ...brandingColors });
    toggleModal();
    toggleIsEditing();
  };

  const isValid = () => Object.keys(brandingColors).every((colorKey) => validation.isColor(colors[colorKey]));

  const saveClassName = classnames('save-area', { 'hidden': !isEditing });
  const editIconClassName = classnames('edit-icon', { 'hidden': isEditing });

  return (
    <React.Fragment>
      <div className="colors-tab">
        <div className="edit-panel">
          <p>{translations.getLabel('lblCompanyColorHelpDescription')}</p>
          <IconButton className={editIconClassName} tag="EditIcon" onClick={toggleIsEditing} />
        </div>
        <div>
          {Object.keys(brandingColors).map((colorKey) => (
            <ColorInput
              key={colorKey}
              title={translations.getLabel(brandingColorsLabels[colorKey].title)}
              description={translations.getLabel(brandingColorsLabels[colorKey].description)}
              color={colors[colorKey]}
              onChange={(color) => setColors({ ...colors, [colorKey]: color })}
              isEditing={isEditing}
              isLoading={isLoading}
            />)
          )}
        </div>
        <div className={saveClassName}>
          <div>
            <Button onClick={toggleModal} theme="transparent">
              <p>{translations.getLabel('lblResetColors')}</p>
              <Icon tag="ResetIcon" color="error" />
            </Button>
          </div>
          <Button onClick={save} disabled={!isValid()} className="save-button">{translations.getLabel('save')}</Button>
        </div>
        <Modal
          open={isModalOpen}
          title={translations.getLabel('lblDefaultColorsTitle')}
          requestClose={toggleModal}
          leftButtonProps={{
            label: translations.getLabel('cancel'),
            onClick: toggleModal,
          }}
          rightButtonProps={{
            label: translations.getLabel('lblRevert'),
            onClick: returnToDefault,
            isLoading: isReverting,
          }}
        >
          <div className="warning-message">{translations.getLabel('lblRevertColorsWarning')}</div>
        </Modal>
      </div>
    </React.Fragment>
  );
};

ColorsTab.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default ColorsTab;
