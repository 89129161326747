import React, { PureComponent } from 'react';
import { Button, InputField, InputType,Modal } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import propTypes from 'prop-types';

import { superAdminService } from '../../../../services';
import { translations } from '../../../../utils';
import ListItem from '../../../listItem/ListItem';
import CompanyDetailsTab from './companyDetailsTab/CompanyDetailsTab';

import './companiesTab.scss';

export default class CompaniesTab extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      detailsView: null,
      detailProps: null,
      companies: [],
      loadingCompanies: false,
      addingCompany: false,
      companyName: '',
    };
  }

  componentDidMount() {
    this.getCompanies();
  }

  getCompanies = async () => {
    this.setState({ loadingCompanies: true });
    const companies = await superAdminService.getCompaniesOfHolding(this.props.holdingId);
    if (companies) this.setState({ companies, loadingCompanies: false });
  }

  renderCompany = (company) => {
    const { name, id, logoUrl } = company;
    const detailProps = { holdingId: this.props.holdingId, companyId: id, companyName: name, goBack: this.renderMainPage };
    return <ListItem key={id} title={name} description={id} onClick={this.goToDetailsPage(id, detailProps)} avatarProps={{ isCompany: !logoUrl, source: logoUrl }} />;
  }

  goToDetailsPage = (companyId, props) => () => {
    this.setState({ detailsView: companyId, detailProps: props });
  }

  renderMainPage = (option) => {
    if (option === 'reload') this.getCompanies();
    this.setState({ detailsView: null });
  }

  renderSkeleton = (key) => <div key={key} className="loading-wrapper"><Skeleton width="100%" height="10.5rem" /></div>;

  handleAddCompanyModal = () => this.setState({ addingCompany: !this.state.addingCompany });

  handleCompanyName = (value) => this.setState({ companyName: value });

  addCompany = async () => {
    await superAdminService.addCompany(this.props.holdingId, this.state.companyName);
    this.handleAddCompanyModal();
    this.getCompanies();
  }

  render() {
    return (
      <React.Fragment>
        {
          !this.state.detailsView ? <div className="companies-container">
            <div className="company-controls">
              <Button onClick={this.handleAddCompanyModal}>{translations.getLabel('lblAddNewCompany')}</Button>
            </div>
            {this.state.loadingCompanies
              ? [1, 2, 3].map(this.renderSkeleton)
              :
              <div>
                {this.state.companies.map(this.renderCompany)}
              </div>
            }
            <Modal
              open={this.state.addingCompany}
              title={translations.getLabel('lblAddNewCompany')}
              requestClose={this.handleAddCompanyModal}
              leftButtonProps={{
                label: translations.getLabel('cancel'),
                onClick: this.handleAddCompanyModal,
              }}
              rightButtonProps={{
                label: translations.getLabel('lblAdd'),
                onClick: this.addCompany,
                disabled: !this.state.companyName,
              }}
            >
              <div className="warning-message">
                <InputField autoFocus type={InputType.Text} emptyIcon label={translations.getLabel('lblCompanyName')} onChange={this.handleCompanyName} value={this.state.companyName} />
              </div>
            </Modal>
          </div >
            :
            <CompanyDetailsTab {...this.state.detailProps} />
        }
      </React.Fragment>
    );
  }
}

CompaniesTab.propTypes = {
  holdingId: propTypes.string.isRequired,
};
