import React, { Component } from 'react';
import { IconButton, InputField, InputType,Modal } from 'react-ess-components';
import PropTypes from 'prop-types';

import TabNavigator from '../../components/tabNavigator/TabNavigator';
import { routes } from '../../constants';
import ConfigContext from '../../context/ConfigContext';
import { superAdminTabStructure } from '../../routes/SuperAdminTabStructure';
import { configService, superAdminService } from '../../services';
import { translations } from '../../utils';

import './SuperAdminConfiguration.scss';

export default class SuperAdminConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updatingName: false,
      holdingName: '',
      pendingHoldingName: '',
    };
  }

  async componentDidMount() {
    const result = await configService.getConfig(this.props.match.params.id);

    const id = this.props.match.params.id;
    const holding = this.context.holdings.find(holding => holding.id === id);
    if (holding) this.setState({ holdingName: holding.name, pendingHoldingName: holding.name });

    if (result) return this.context.updateConfig(result.config);
    this.context.updateConfig({});
  }

  goBack = () => {
    this.props.history.replace(routes.holdings);
  }

  updateHoldingName = async () => {
    const id = this.props.match.params.id;
    const holding = this.context.holdings.find(holding => holding.id === id);
    await superAdminService.updateHolding(holding.id, { name: this.state.pendingHoldingName, url: holding.url });
    this.setState({ holdingName: this.state.pendingHoldingName });
    this.toggleUpdateNameModal();
  }

  toggleUpdateNameModal = () => this.setState({ updatingName: !this.state.updatingName })

  handleHoldingName = (holdingName) => this.setState({ pendingHoldingName: holdingName })

  render() {
    const id = this.props.match.params.id;
    return (
      <div className="page fixed-width app-holding">
        <div className="header">
          <h2><IconButton tag="ArrowLeft" color="primary" onClick={this.goBack} /> {this.state.holdingName}</h2>
          <IconButton tag="EditIcon" className="edit-holding-name-icon" color="primary" onClick={this.toggleUpdateNameModal} />
        </div>
        <TabNavigator {...this.props} tabsStructure={superAdminTabStructure} routePrefix={`/admin/holding/${id}`} holdingId={id} />
        <Modal
          open={this.state.updatingName}
          title={translations.getLabel('lblHoldingName')}
          requestClose={this.toggleUpdateNameModal}
          leftButtonProps={{
            label: translations.getLabel('cancel'),
            onClick: this.toggleUpdateNameModal,
          }}
          rightButtonProps={{
            label: translations.getLabel('save'),
            onClick: this.updateHoldingName,
            disabled: this.state.pendingHoldingName === '' || this.state.holdingName === this.state.pendingHoldingName,
          }}
        >
          <div className="warning-message">
            <InputField autoFocus type={InputType.Text} emptyIcon label={translations.getLabel('lblHoldingName')} onChange={this.handleHoldingName} value={this.state.pendingHoldingName} />
          </div>
        </Modal>
      </div>
    );
  }
}

SuperAdminConfiguration.contextType = ConfigContext;

SuperAdminConfiguration.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
