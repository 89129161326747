import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { requestTypes } from '../../../../../constants';
import { holdingAdminService } from '../../../../../services';
import ShiftOfferCounters from './components/ShiftOfferCounters';
import VacationCounters from './components/VacationCounters';

let didCancel = false;

const RequestCountersTab = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [vacationCounters, setVacationCounters] = useState([]);
  const [vacationTypes, setVacationTypes] = useState([]);
  const [shiftOfferCounters, setShiftOfferCounters] = useState([]);
  const [availableCounters, setAvailableCounters] = useState([]);

  useEffect(() => {
    didCancel = false;

    if (props.companyId) getData();

    return () => {
      didCancel = true;
    };
  }, [props.companyId]); //eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    setIsLoading(true);
    const result = await holdingAdminService.getCounterVisibilities(props.holdingId, props.companyId);
    const counters = await holdingAdminService.getAllCounters(props.holdingId);
    const vacationTypesResult = await holdingAdminService.getVacationTypes(props.holdingId, props.companyId);
    if (!didCancel) {
      if (result) {
        setVacationCounters(result.filter(c => c.visibleOn === requestTypes.vacation) || []);
        setShiftOfferCounters(result.filter(c => c.visibleOn === requestTypes.shiftOffer) || []);
      }
      if (counters) {
        setAvailableCounters(counters);
      }
      if (vacationTypesResult) {
        setVacationTypes(vacationTypesResult);
      }
      setIsLoading(false);
    }
  };

  return (
    <div className="counters-tab">
      <VacationCounters
        vacationTypes={vacationTypes}
        counters={vacationCounters}
        isLoading={isLoading}
        availableCounters={availableCounters}
        holdingId={props.holdingId}
        companyId={props.companyId}
        getData={getData}
      />
      <ShiftOfferCounters
        counters={shiftOfferCounters}
        isLoading={isLoading}
        availableCounters={availableCounters}
        holdingId={props.holdingId}
        companyId={props.companyId}
        getData={getData}
      />
    </div>
  );
};

RequestCountersTab.propTypes = {
  companyId: PropTypes.string.isRequired,
  holdingId: PropTypes.string.isRequired,
};

export default RequestCountersTab;
