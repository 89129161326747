import React, { useEffect, useState } from 'react';
import { Button, Icon } from 'react-ess-components';
import PropTypes from 'prop-types';

import { companyAdminService } from '../../../../services';
import { translations } from '../../../../utils';
import SelfRosterProfile from './components/SelfRosterProfile';
import SelfRosterProfileDepartment from './components/SelfRosterProfileDepartment';
import SelfRosterProfileModal from './components/SelfRosterProfileModal';

import './selfRoster.scss';

let didCancel = false;

const SelfRoster = ({ companyId }) => {
  const [availableDepartments, setAvailableDepartments] = useState([]);
  const [selfRosterProfiles, setSelfRosterProfiles] = useState([]);
  const [departmentProfiles, setDepartmentProfiles] = useState([]);
  const [isModal1Open, setIsModal1Open] = useState(false);
  const [isModal2Open, setIsModal2Open] = useState(false);
  const [editProfile, setEditProfile] = useState(null);


  useEffect(() => {
    const getDepartments = async () => {
      const departments = await companyAdminService.getDepartments(companyId);
      if (departments) setAvailableDepartments(departments);
    };

    if (companyId) getDepartments();
  }, [companyId]);

  const switchModal = (modal1, modal2, profile) => {
    setIsModal1Open(modal1);
    setIsModal2Open(modal2);
    if (profile) setEditProfile(profile);
    if (!modal1 && !modal2) setEditProfile(null);
  };

  useEffect(() => {
    const getData = async () => {
      const profiles = await companyAdminService.getSelfRosterProfile(companyId);
      const departmentProfiles = await companyAdminService.getSelfRosterDepartmentProfile(companyId);

      if (profiles && !didCancel) setSelfRosterProfiles(profiles);
      if (departmentProfiles) setDepartmentProfiles(departmentProfiles);
    };

    didCancel = false;
    if (companyId) getData();
    return () => {
      didCancel = true;
    };
  }, [companyId]);

  const getData = async () => {
    const profiles = await companyAdminService.getSelfRosterProfile(companyId);
    const departmentProfiles = await companyAdminService.getSelfRosterDepartmentProfile(companyId);

    if (profiles && !didCancel) setSelfRosterProfiles(profiles);
    if (departmentProfiles && !didCancel) setDepartmentProfiles(departmentProfiles);
  };

  return (
    <div className="counters-tab self-roster-tab">
      <h4>{translations.getLabel('titleSelfRosterProfiles')}</h4>
      <p className="label">{translations.getLabel('lblConfigureSelfRosterProfiles')}</p>
      {selfRosterProfiles.map((profile) =>
        <SelfRosterProfile getData={getData} key={profile.id} companyId={companyId} profile={profile} switchModal={switchModal} />
      )}
      <Button theme="transparent" onClick={() => setIsModal1Open(true)}>
        <Icon tag="PlusSquareIcon" color="primary" />
        <p className="button-label">{translations.getLabel('btnAddProfile')}</p>
      </Button>
      <div className="hr" />
      <SelfRosterProfileDepartment companyId={companyId} profiles={selfRosterProfiles} departmentProfiles={departmentProfiles} getData={getData} />
      <SelfRosterProfileModal companyId={companyId} departments={availableDepartments} onSave={getData} profile={editProfile} isModal1Open={isModal1Open} isModal2Open={isModal2Open} switchModal={switchModal} />
    </div>
  );
};

SelfRoster.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default SelfRoster;
