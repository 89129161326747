import { dateSettings } from '../constants';
import translations from './translations';

export const convertRelativePeriodToDBString = (period) => {
    if (!period) {
        return '';
    }
    const { days, startPoint, type, offset, offsetType } = period;
    return `${days}/${startPoint}/${type}/${offset}/${offsetType}`;
};

export const convertRelativePeriodToLabel = (date) => {
    const [days, start, type, offset, offsetType] = date.split('/');

    return translations.getLabel(dateSettings.DAYS.find(d => d.value === days)?.label) + ', ' +
        translations.getLabel(dateSettings.STARTPOINTS.find(d => d.value === start)?.label) + ', ' +
        translations.getLabel(dateSettings.TYPE.find(d => d.value === type)?.label) + ', ' +
        `${translations.getLabel('lblOffset')}: ${offset} ` +
        translations.getLabel(dateSettings.OFFSET_TYPES.find(d => d.value === offsetType)?.label);
};