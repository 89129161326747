import React, { useEffect, useState } from 'react';
import { Icon, SelectPopup } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { translations } from '../../../../../../utils';

const VacationCountersEditItem = (props) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editingCounter, setEditingCounter] = useState();
  const [selectedCounters, setSelectedCounters] = useState([]);

  const openEditModal = (vacationTypeId) => {
    setEditingCounter(vacationTypeId);
    setSelectedCounters(props.vacationTypes?.find(vacationType => vacationType.id === vacationTypeId).counters.map(counter => counter.counterId));
    setIsEditOpen(true);
  };

  const closeEditModal = () => {
    setIsEditOpen(false);
  };

  const renderRow = (vacationType) => (
    <tr key={vacationType.holdingVacationTypeId}>
      <td className="vacation-type">{vacationType.descriptionEn}</td>
      <td>
        <div className="vacation-counters-container">
          <div className="vacation-counters">
            {vacationType.counters.length ? vacationType.counters.map((counter) =>
              <p key={counter.id}>
                {counter.description}
              </p>)
              :
              <i>
                {translations.getLabel('lblNoVacationCountersConfigured')}
              </i>
            }
          </div>
          <Icon tag="EditIcon" color="primary" onClick={() => openEditModal(vacationType.id)} />
        </div>
      </td>
    </tr>
  );

  const onSave = () => {
    setIsEditOpen(false);
    props.onSave(editingCounter, selectedCounters);
  };

  return (
    <div>
      <div className={classNames('edit-item-container', { 'single-item': !props.noBorder })}>
        <div>
          <div>
            {props.title && <h4>{props.title}</h4>}
            {props.label && <p>{props.label}</p>}
          </div>
          {(props.vacationTypes.length > 0 && !props.isLoading) &&
            <table className="table" >
              <thead>
                <tr>
                  <th>{translations.getLabel('lblVacationTypeCounters')}</th>
                  <th>{translations.getLabel('lblCounters')}</th>
                </tr>
              </thead>
              <tbody>
                {
                  props.isLoading
                    ?
                    <tr><td> <Skeleton width="100%" height="4rem" /></td></tr>
                    :
                    props.vacationTypes.map(renderRow)
                }
              </tbody>
            </table>
          }
          {!props.isLoading && !props.vacationTypes.length && <p>{props.emptyLabel}</p>}
          {props.isLoading && <p><Skeleton width="50%" height="2rem" /></p>}
        </div>
      </div >

      <SelectPopup
        data={props.availableCounters?.map(counter => ({ value: counter.id, label: counter.description }))}
        popupOpen={isEditOpen}
        isMultiple
        isModal
        selected={selectedCounters}
        title={props.title}
        requestClose={closeEditModal}
        onValueSelected={setSelectedCounters}
        onSave={onSave}
        acceptLabel={translations.getLabel('save')}
        cancelLabel={translations.getLabel('cancel')}
        isSearchEnabled={false}
        isAllowedEmpty
      />
    </div>
  );
};

VacationCountersEditItem.propTypes = {
  vacationTypes: PropTypes.array.isRequired,
  availableCounters: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  emptyLabel: PropTypes.string.isRequired,
};

export default VacationCountersEditItem;
