import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import './EditValue.scss';

class EditValue extends PureComponent {
  render() {
    return (
      <div className="edit-value-container">
        <p>{this.props.title}</p>
        <span className="value-description">{this.props.value}</span>
        {this.props.rightSpacing && <div className="filler" />}
      </div>
    );
  }
}

EditValue.propTypes = {
  rightSpacing: PropTypes.bool,
  title: PropTypes.string.isRequired,
  value: PropTypes.string,
};

EditValue.defaultProps = {
  rightSpacing: false,
  value: '',
};

export default EditValue;
