import React, { Component } from 'react';
import { IconButton, InputDateField,InputField, InputType, Modal } from 'react-ess-components';
import { addDays, addYears, parseISO } from 'date-fns';
import { PropTypes } from 'prop-types';

import { dateSettings } from '../../../../../../constants';
import { translations } from '../../../../../../utils';


const FROM = 'from';

class Counter extends Component {
  constructor(props) {
    super(props);

    this.state = this.defaultState;
  }

  defaultState = {
    counter: {
      externalId: '',
      description: '',
      fromDate: '',
      toDate: '',
    },
    counterId: '',
    isFromVisible: false,
    isLoading: false,
    isToVisible: false,
    relativeFrom: {
      days: dateSettings.DAYS[0].value,
      startPoint: dateSettings.STARTPOINTS[0].value,
      type: dateSettings.TYPE[0].value,
      offset: '',
      offsetType: dateSettings.OFFSET_TYPES[0].value,
    },
    relativeTo: {
      days: dateSettings.DAYS[0].value,
      startPoint: dateSettings.STARTPOINTS[0].value,
      type: dateSettings.TYPE[0].value,
      offset: '',
      offsetType: dateSettings.OFFSET_TYPES[0].value,
    },
  }

  componentDidMount() {
    const { editingCounter, editingCounterId } = this.props;
    let { fromDate, toDate } = editingCounter;
    const { relativeFrom, relativeTo } = this.defaultState;

    if (editingCounterId === '') {
      return null;
    } else {
      if (!fromDate.includes('/') && !toDate.includes('/')) {
        return this.setState({ counter: editingCounter, counterId: editingCounterId });
      } else {
        const isFromVisible = fromDate.includes('/');
        const isToVisible = toDate.includes('/');
        const convertedFromDate = isFromVisible ? this.convertRelativeDate(fromDate) : relativeFrom;
        const convertedToDate = isToVisible ? this.convertRelativeDate(toDate) : relativeTo;
        const counter = { ...editingCounter, fromDate: isFromVisible ? '' : fromDate, toDate: isToVisible ? '' : toDate };


        this.setState({ counter, counterId: editingCounterId, isFromVisible, isToVisible, relativeFrom: convertedFromDate, relativeTo: convertedToDate });
      }
    }
  }

  convertRelativeDate = (date) => {
    const relativeValues = date.split('/');
    return {
      days: relativeValues[0],
      startPoint: relativeValues[1],
      type: relativeValues[2],
      offset: relativeValues[3],
      offsetType: relativeValues[4],
    };
  }

  filterEndDate = date => {
    const { fromDate } = this.state.counter;

    const startDate = fromDate ? new Date(fromDate) : addYears(new Date(), -1);

    const marker = fromDate ? new Date(fromDate) : new Date();
    const daysAdded = addDays(marker, 365);

    return date <= daysAdded && date > startDate;
  };

  filterStartDate = date => {
    const { toDate } = this.state.counter;

    const endDate = toDate ? new Date(toDate) : addYears(new Date(), 1);

    const marker = toDate ? new Date(toDate) : new Date();
    const daySubstracted = addDays(marker, -365);

    return date > daySubstracted && date < endDate;
  };

  handleButtonDisable = () => {
    const { counter, isFromVisible, isToVisible, relativeFrom, relativeTo } = this.state;

    if (!isFromVisible && !isToVisible && Object.values(counter).some(value => value === '')) {
      return true;
    } else if (counter.description === '' || counter.externalId === '') {
      return true;
    } else if (isFromVisible) {
      return (Object.values(relativeFrom).some(value => value === '') || (counter.toDate === '' && Object.values(relativeTo).some(value => value === '')));
    } else if (isToVisible) {
      return (Object.values(relativeTo).some(value => value === '') || (counter.fromDate === '' && Object.values(relativeFrom).some(value => value === '')));
    }
  }

  handleModalInput = (key) => (value) => this.setState({ counter: { ...this.state.counter, [key]: value } });

  handleTimeInput = (key) => (value) => this.setState({ counter: { ...this.state.counter, [key]: typeof value.date === 'string' ? value.date : value.date.toISOString() } });


  handleSaveModal = async () => {
    this.setState({ isLoading: true });

    let { fromDate, toDate } = this.state.counter;
    const { counterId, relativeFrom, relativeTo } = this.state;

    if (relativeFrom.offset) {
      const { days, startPoint, type, offset, offsetType } = relativeFrom;

      fromDate = `${days}/${startPoint}/${type}/${offset}/${offsetType}`;
    }

    if (relativeTo.offset) {
      const { days, startPoint, type, offset, offsetType } = relativeTo;

      toDate = `${days}/${startPoint}/${type}/${offset}/${offsetType}`;
    }

    const counter = { ...this.state.counter, fromDate, toDate };

    await this.props.handleSave(counter, counterId);

    this.setState({ ...this.defaultState, isLoading: false });
    this.props.toggleModal();
  }


  handleSelect = (type, key) => (value) => {
    if (type === FROM) {
      this.setState({ relativeFrom: { ...this.state.relativeFrom, [key]: value } });
    } else {
      this.setState({ relativeTo: { ...this.state.relativeTo, [key]: value } });
    }
  }

  renderDateSelectors = (type) => {
    const { relativeFrom, relativeTo } = this.state;

    return (
      <div className="relative-date">
        <InputField onChange={this.handleSelect(type, 'days')} options={this.renderSelectOptions(dateSettings.DAYS)} type={InputType.Select} value={type === FROM ? relativeFrom.days : relativeTo.days} />
        <InputField onChange={this.handleSelect(type, 'startPoint')} options={this.renderSelectOptions(dateSettings.STARTPOINTS)} type={InputType.Select} value={type === FROM ? relativeFrom.startPoint : relativeTo.startPoint} />
        <InputField onChange={this.handleSelect(type, 'type')} options={this.renderSelectOptions(dateSettings.TYPE)} type={InputType.Select} value={type === FROM ? relativeFrom.type : relativeTo.type} />
        <p>{translations.getLabel('lblOffset')}</p>
        <InputField onChange={this.handleSelect(type, 'offset')} type={InputType.Text} value={type === FROM ? relativeFrom.offset : relativeTo.offset} />
        <InputField onChange={this.handleSelect(type, 'offsetType')} options={this.renderSelectOptions(dateSettings.OFFSET_TYPES)} type={InputType.Select} value={type === FROM ? relativeFrom.offsetType : relativeTo.offsetType} />
      </div >
    );
  }

  renderSelectOptions = (options) => {
    return options.map(option => {
      return { ...option, label: translations.getLabel(option.label) };
    });
  }

  toggleModal = () => () => {
    this.setState(this.defaultState);
    this.props.toggleModal();
  }

  toggleVisible = (key) => () => {
    if (key === FROM) {
      this.setState({ counter: { ...this.state.counter, fromDate: '' }, isFromVisible: !this.state.isFromVisible, relativeFrom: this.defaultState.relativeFrom });
    } else {
      this.setState({ counter: { ...this.state.counter, toDate: '' }, isToVisible: !this.state.isToVisible, relativeTo: this.defaultState.relativeTo });
    }
  }

  render() {
    const { counter, isFromVisible, isLoading, isToVisible } = this.state;
    const { isModalOpen } = this.props;

    return (
      <Modal
        requestClose={this.toggleModal()}
        open={isModalOpen}

        rightButtonProps={{ label: translations.getLabel('save'), onClick: this.handleSaveModal, isLoading, disabled: this.handleButtonDisable() }}
        leftButtonProps={{ label: translations.getLabel('cancel'), onClick: this.toggleModal() }}
        title={translations.getLabel('titleNewCounter')}
      >
        <p className="field-title">{translations.getLabel('lblDescription')}</p>
        <InputField autoFocus type={InputType.Text} emptyIcon onChange={this.handleModalInput('description')} value={counter.description} />
        <p className="field-title">{translations.getLabel('lblWFMID')}</p>
        <InputField type={InputType.Text} emptyIcon onChange={this.handleModalInput('externalId')} value={counter.externalId} />
        <div className="date-field">
          <div className="from-column">
            <p className="field-title">{translations.getLabel('lblFrom')}</p>
            <InputDateField dateFormat="dd/MM/yyyy" disabled={isFromVisible} emptyIcon filterDate={this.filterStartDate} onChange={this.handleTimeInput('fromDate')} value={{ date: counter.fromDate ? parseISO(counter.fromDate) : undefined }} />
            <div className="checkbox-tag">
              <IconButton color="primary" tag={isFromVisible ? 'CheckedIcon' : 'UncheckedIcon'} onClick={this.toggleVisible(FROM)} />
              <p>{translations.getLabel('lblRelativeDate')}</p>
            </div>
            <div className="date-selector">
              {isFromVisible && this.renderDateSelectors(FROM)}
            </div>
          </div>
          <div className="to-column">
            <p className="field-title">{translations.getLabel('lblTo')}</p>
            <InputDateField dateFormat="dd/MM/yyyy" disabled={isToVisible} emptyIcon filterDate={this.filterEndDate} onChange={this.handleTimeInput('toDate')} value={{ date: counter.toDate ? parseISO(counter.toDate) : undefined }} />
            <div className="checkbox-tag">
              <IconButton color="primary" tag={isToVisible ? 'CheckedIcon' : 'UncheckedIcon'} onClick={this.toggleVisible('to')} />
              <p>{translations.getLabel('lblRelativeDate')}</p>
            </div>
            <div className="date-selector">
              {isToVisible && this.renderDateSelectors('to')}
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

Counter.propTypes = {
  editingCounter: PropTypes.object,
  editingCounterId: PropTypes.string,
  handleSave: PropTypes.func,
  isModalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

const noop = () => { };

Counter.defaultProps = {
  editingCounter: {},
  editingCounterId: '',
  handleSave: noop,
};

export default Counter;
