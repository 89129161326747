import React, { useEffect, useState } from 'react';
import { IconButton, InputDateField, InputField, InputType, Modal } from 'react-ess-components';
import { addDays, addYears, parseISO } from 'date-fns';
import { PropTypes } from 'prop-types';

import { dateSettings } from '../../../../../constants';
import { dateUtils, translations } from '../../../../../utils';

import '../../../superAdmin/configurationTab/counters/CountersConfigurationTab.scss';

const FROM = 'from';
const defaultRelativeDate = {
  days: dateSettings.DAYS[0].value,
  startPoint: dateSettings.STARTPOINTS[0].value,
  type: dateSettings.TYPE[0].value,
  offset: '0',
  offsetType: dateSettings.OFFSET_TYPES[0].value,
};

const AdjustPeriodModal = ({ period, handleSave, title, label, isOpen, closeModal, isBeforeEnabled, maxDate }) => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [isFromVisible, setIsFromVisible] = useState(false);
  const [isToVisible, setIsToVisible] = useState(false);
  const [relativeFrom, setRelativeFrom] = useState(defaultRelativeDate);
  const [relativeTo, setRelativeTo] = useState(defaultRelativeDate);
  const [daysBefore, setDaysBefore] = useState(period.daysBefore || 0);

  useEffect(() => {
    getDates();
  }, [isOpen]);  //eslint-disable-line react-hooks/exhaustive-deps

  const getDates = () => {
    const { fromDate, toDate } = period;
    const isFromVisible = fromDate?.includes('/');
    const isToVisible = toDate?.includes('/');
    if (fromDate?.includes('/') || toDate?.includes('/')) {
      const convertedFromDate = isFromVisible ? convertRelativeDate(fromDate) : relativeFrom;
      const convertedToDate = isToVisible ? convertRelativeDate(toDate) : relativeTo;
      setFromDate(isFromVisible ? '' : fromDate);
      setToDate(isToVisible ? '' : toDate);
      setRelativeFrom(convertedFromDate);
      setRelativeTo(convertedToDate);
    } else {
      setFromDate(period.fromDate);
      setToDate(period.toDate);
    }
    setIsFromVisible(isFromVisible);
    setIsToVisible(isToVisible);
  };

  const convertRelativeDate = (date) => {
    const relativeValues = date.split('/');
    return {
      days: relativeValues[0],
      startPoint: relativeValues[1],
      type: relativeValues[2],
      offset: relativeValues[3],
      offsetType: relativeValues[4],
    };
  };

  const toggleVisible = (key) => () => {
    if (key === FROM) {
      setFromDate('');
      setIsFromVisible(!isFromVisible);
      setRelativeFrom(defaultRelativeDate);
    } else {
      setToDate('');
      setIsToVisible(!isToVisible);
      setRelativeTo(defaultRelativeDate);
    }
  };

  const handleSelect = (type, key) => (value) => {
    if (type === FROM) {
      setRelativeFrom({ ...relativeFrom, [key]: value });
    } else {
      setRelativeTo({ ...relativeTo, [key]: value });
    }
  };

  const filterEndDateWithBefore = date => {
    const startDate = fromDate ? new Date(fromDate) : addYears(new Date(), -1);
    const marker = fromDate ? new Date(fromDate) : new Date();
    const daysAdded = addDays(marker, 365);

    return date <= daysAdded && date > startDate;
  };

  const filterEndDateWithoutBefore = date => {
    const today = new Date();
    return date > today;
  };

  const filterStartDate = date => {
    const endDate = toDate ? new Date(toDate) : addYears(new Date(), 1);
    const marker = toDate ? new Date(toDate) : new Date();
    const daySubstracted = addDays(marker, -365);

    return date > daySubstracted && date < endDate;
  };

  const handleButtonDisable = () => {
    const isRelativeFromValid = Object.values(relativeFrom).some(value => value !== '') && !isNaN(parseInt(relativeFrom.offset));
    const isRelativeToValid = Object.values(relativeTo).some(value => value !== '') && !isNaN(parseInt(relativeTo.offset));

    if (isBeforeEnabled && isNaN(parseInt(daysBefore))) {
      return true;
    }
    if (period.fromDate && !period.toDate) {
      if (isFromVisible && !isRelativeFromValid) return true;
      if (!isFromVisible && !fromDate) return true;
      return false;
    } else if (period.toDate && !period.fromDate) {
      if (isToVisible && !isRelativeToValid) return true;
      if (!isToVisible && !toDate) return true;
      return false;
    } else {
      if (!isFromVisible && !isToVisible && fromDate && toDate) {
        return false;
      } else if (isFromVisible && !isToVisible) {
        if (isRelativeFromValid && toDate) {
          return false;
        }
        else {
          return true;
        }
      } else if (isToVisible && !isFromVisible) {
        if (isRelativeToValid && fromDate) {
          return false;
        }
        else {
          return true;
        }
      } else if (isFromVisible && isToVisible && isRelativeFromValid && isRelativeToValid) {
        return false;
      }
    }

    return true;
  };

  const handleTimeInput = (key) => (value) => {
    if (key === FROM) setFromDate(typeof value.date === 'string' ? value.date : value.date.toISOString());
    else setToDate(typeof value.date === 'string' ? value.date : value.date.toISOString());
  };

  const handleSaveModal = () => {
    let newFromDate = fromDate;
    let newToDate = toDate;

    if (isFromVisible) {
      newFromDate = dateUtils.convertRelativePeriodToDBString(relativeFrom);
    }

    if (isToVisible) {
      newToDate = dateUtils.convertRelativePeriodToDBString(relativeTo);
    }

    const newPeriod = { fromDate: newFromDate, toDate: newToDate, daysBefore };

    handleSave(newPeriod);
  };

  const renderDateSelectors = (type) => {
    return (
      <div className={'dates-column'}>
        <InputField
          onChange={handleSelect(type, 'days')}
          options={renderSelectOptions(dateSettings.DAYS)}
          type={InputType.Select}
          value={type === FROM ? relativeFrom.days : relativeTo.days}
        />
        <InputField
          onChange={handleSelect(type, 'startPoint')}
          options={renderSelectOptions(dateSettings.STARTPOINTS)}
          type={InputType.Select}
          value={type === FROM ? relativeFrom.startPoint : relativeTo.startPoint}
        />
        <InputField
          onChange={handleSelect(type, 'type')}
          options={renderSelectOptions(dateSettings.TYPE)}
          type={InputType.Select}
          value={type === FROM ? relativeFrom.type : relativeTo.type}
        />
        <p>{translations.getLabel('lblOffset')}</p>
        <InputField
          onChange={handleSelect(type, 'offset')}
          type={InputType.Number}
          value={type === FROM ? relativeFrom.offset : relativeTo.offset} />
        <InputField
          onChange={handleSelect(type, 'offsetType')}
          options={renderSelectOptions(dateSettings.OFFSET_TYPES)}
          type={InputType.Select}
          value={type === FROM ? relativeFrom.offsetType : relativeTo.offsetType}
        />
      </div >
    );
  };

  const renderSelectOptions = (options) => {
    return options.map(option => {
      return { ...option, label: translations.getLabel(option.label) };
    });
  };

  return (
    <Modal
      className="counter-container"
      requestClose={closeModal}
      open={isOpen}
      rightButtonProps={{ label: translations.getLabel('save'), onClick: handleSaveModal, disabled: handleButtonDisable() }}
      leftButtonProps={{ label: translations.getLabel('cancel'), onClick: closeModal }}
      title={title}
    >
      <div className="schedule-modal-label">{label}</div>
      <div className="date-field">
        {period.fromDate && <div className="from-column">
          <p className="field-title">{translations.getLabel('lblFrom')}</p>
          <InputDateField
            maxDate={maxDate}
            dateFormat="dd/MM/yyyy"
            disabled={isFromVisible}
            emptyIcon
            filterDate={filterStartDate}
            onChange={handleTimeInput(FROM)}
            value={{ date: fromDate ? parseISO(fromDate) : undefined }}
          />
          <div className="checkbox-tag">
            <IconButton color="primary" tag={isFromVisible ? 'CheckedIcon' : 'UncheckedIcon'} onClick={toggleVisible(FROM)} />
            <p>{translations.getLabel('lblRelativeDate')}</p>
          </div>
          <div className="date-selector">
            {isFromVisible && renderDateSelectors(FROM)}
          </div>
        </div>}
        {period.toDate && <div className="to-column">
          <p className="field-title">{translations.getLabel('lblTo')}</p>
          <InputDateField
            maxDate={maxDate}
            dateFormat="dd/MM/yyyy"
            disabled={isToVisible}
            emptyIcon
            filterDate={(isBeforeEnabled || maxDate) ? filterEndDateWithBefore : filterEndDateWithoutBefore}
            onChange={handleTimeInput('')}
            value={{ date: toDate ? parseISO(toDate) : undefined }}
          />
          <div className="checkbox-tag">
            <IconButton color="primary" tag={isToVisible ? 'CheckedIcon' : 'UncheckedIcon'} onClick={toggleVisible('to')} />
            <p>{translations.getLabel('lblRelativeDate')}</p>
          </div>
          <div className="date-selector">
            {isToVisible && renderDateSelectors('to')}
          </div>
        </div>}
      </div>
      {
        isBeforeEnabled &&
        <div className="advanced">
          {translations.getLabel('lblAtLeast')}
          <InputField
            onChange={setDaysBefore}
            type={InputType.Number}
            value={daysBefore} />
          {translations.getLabel('lblDaysInAdvance')}
        </div>
      }
    </Modal>
  );
};

AdjustPeriodModal.propTypes = {
  period: PropTypes.object,
  handleSave: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  isBeforeEnabled: PropTypes.bool,
  label: PropTypes.string,
  maxDate: PropTypes.instanceOf(Date),
};

AdjustPeriodModal.defaultProps = {
  period: {},
  isOpen: false,
  isBeforeEnabled: true,
  label: '',
  maxDate: null,
};

export default AdjustPeriodModal;
