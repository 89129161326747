export interface IDepartment {
  id: string;
  name: string;
}

export interface IDepartmentProfile {
  departmentId: string;
  departmentName: string;
  companySelfRosterProfileId: string;
  companySelfRosterProfileName: string;
}

export interface ISelfRosterProfile {
  checkIterationOne: boolean;
  checkIterationTwo: boolean;
  companyId: string;
  id: string;
  name: string;
  numberOfDepartments: number;
  showShifts: string;
  type: SelfRosterProfileType;
}

export enum SelfRosterProfileType {
  SelfRoster = 'SELF_ROSTER',
  PreferenceShifts = 'PREFERENCE_SHIFTS'
}