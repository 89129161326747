export const timeRegistrationType = {
  Informative: 'INFORMATIVE',
  Time: 'TIME',
};

export const timeRegistrationTypes = [
  { key: 'TIME', label: 'lblTime', version: 1 },
  { key: 'INFORMATIVE', label: 'lblInformative', version: 1 },
];

export const timeRegistrationCheckTypes = [
  { key: 'NONE', label: 'lblNone', version: 1 },
  { key: 'MILD', label: 'lblMild', version: 1 },
  { key: 'HARD', label: 'lblHard', version: 1 },
];

export const timeRegistrationLocationTypes = [
  { key: 'WIFI', label: 'lblWifiNetwork' },
  { key: 'BEACON', label: 'lblBeacon' },
  { key: 'GPS', label: 'lblGPSLocation' },
];

export const timeRegistrationLocationTypeKeys = {
  Wifi: 'WIFI',
  Beacon: 'BEACON',
  Gps: 'GPS',
};
