import React, { Component } from 'react';
import { IconButton } from 'react-ess-components';
import PropTypes from 'prop-types';

import TabNavigator from '../../components/tabNavigator/TabNavigator';
import { routes } from '../../constants';
import ConfigContext from '../../context/ConfigContext';
import { companyAdminTabStructure } from '../../routes/CompanyAdminTabStructure';
import { companyAdminService } from '../../services';

export default class CompanyAdminConfiguration extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
    };
  }

  goBack = () => {
    if (this.context.me.isHoldingAdmin) return this.props.history.replace(routes.holdingAdminCompanies);
    this.props.history.replace(routes.companies);
  }

  async componentDidMount() {
    let companies = this.context.companies;

    if (!companies.length) {
      companies = await companyAdminService.getCompanies();
      this.context.updateCompanies(companies);
    }

    const current = companies.find((company) => company.id === this.props.match.params.id);
    this.setState({ name: current ? current.name : '' });
  }

  render() {
    let tabs = [...companyAdminTabStructure];
    let companies = this.context.companies;

    const currentCompany = companies.find((company) => company.id === this.props.match.params.id);
    const hasPermissionsForThisCompany = !!this.context.me.companyPermissions.find(companyId => companyId === currentCompany?.id);
    if (!this.context.me.isHoldingAdmin) tabs = tabs.filter(tab => tab.url !== 'configuration' && tab.url !== 'company-admins');
    if (!this.context.me.isCompanyAdmin || !hasPermissionsForThisCompany) tabs = tabs.filter(tab => tab.url !== 'branding' && tab.url !== 'company-users' && tab.url !== 'extra-setup');

    return (
      <div className="page fixed-width">
        <div className="header">
          <h2><IconButton tag="ArrowLeft" color="primary" onClick={this.goBack} /> {this.state.name}</h2>
        </div>
        <TabNavigator {...this.props} holdingId={this.context.config.holdingId} tabsStructure={tabs} routePrefix={`/company-admin/${this.props.match.params.id}`} companyId={this.props.match.params.id} />
      </div>
    );
  }
}

CompanyAdminConfiguration.contextType = ConfigContext;

CompanyAdminConfiguration.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};
