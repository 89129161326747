import React, { useEffect, useState } from 'react';
import { FileUpload, IconButton, Modal } from 'react-ess-components';
import { toast } from 'react-toastify';

import { pdfIcon } from '../../../../../assets';
import { maxFileSizesInMb } from '../../../../../constants';
import { useToggle } from '../../../../../hooks';
import { holdingAdminService } from '../../../../../services';
import { fileUploadUtils, translations } from '../../../../../utils';

import './termsAndConditions.scss';

let didCancel = false;

const TermsAndConfitionsTab = () => {
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [fileSize, setFileSize] = useState(null);
  const [isConfirmationModalOpen, toggleModal] = useToggle(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);

  const getTermsAndConditions = async () => {
    if (!didCancel) setIsLoading(true);
    const result = await holdingAdminService.getTermsAndConditions();
    if (result && !didCancel) {
      setDownloadUrl(result.downloadUrl);
      setFileName(result.fileName);
      setFileSize(result.size);
    }
    if (!didCancel) setIsLoading(false);
  };

  useEffect(() => {
    didCancel = false;

    getTermsAndConditions();
    return () => {
      didCancel = true;
    };
  }, []);

  const handleUpload = async (file) => {
    if (file.type !== 'application/pdf') return toast.error(translations.getLabel('lblErrorFileTermsAndConditions'));
    setIsLoading(true);
    const url = await holdingAdminService.getPresignedUrlTAndA();
    if (url) {
      await fileUploadUtils.uploadFileToPresignedUrl(url, file);
      await getTermsAndConditions();
    }
    if (!didCancel) setIsLoading(false);
  };

  const toggleConfirmationModal = (event) => {
    if (event) event.preventDefault();
    toggleModal();
  };

  const removeFile = async (event) => {
    setIsRemoveLoading(true);
    await holdingAdminService.removeTermsAndConditions();
    if (!didCancel) {
      setIsRemoveLoading(false);
      setDownloadUrl(null);
      toggleConfirmationModal();
    }
  };

  return (
    <div className="terms-and-conditions-container">
      <div>
        <h4>{translations.getLabel('titleAddTermsAndConditions')}</h4>
        <p>{translations.getLabel('lblTermsAndConditionsDescription')}</p>
        <p className="restrictions">{translations.getLabel('lblTermsAndConditionsLimitations')}</p>
        <FileUpload
          acceptFileTypes="application/pdf"
          translationLabels={{
            lblDrag: translations.getLabel('lblDragDocument'),
            lblBtn: translations.getLabel('btnChooseDocument'),
            lblErrorFileUploadSize: translations.getLabel('lblErrorFileUploadSize', { maxFileSize: maxFileSizesInMb.termsAndConditions }),
          }}
          maxFileSize={fileUploadUtils.convertMBtoByte(maxFileSizesInMb.termsAndConditions)}
          isLoading={isLoading}
          showPreview={false}
          isModal={false}
          handleUpload={handleUpload}
        />
        {downloadUrl && <a href={downloadUrl} target="_blank" rel="noopener noreferrer" className="preview">
          <img src={pdfIcon} alt="doc-icon" />
          <p>{`${fileName} (${fileUploadUtils.convertFileSize(fileSize)})`}</p>
          <IconButton onClick={toggleConfirmationModal} tag="TrashIcon" color="error" />
        </a>}
        <Modal
          requestClose={toggleConfirmationModal}
          open={isConfirmationModalOpen}
          rightButtonProps={{ label: translations.getLabel('lblDelete'), onClick: removeFile, isLoading: isRemoveLoading }}
          leftButtonProps={{ label: translations.getLabel('cancel'), onClick: toggleConfirmationModal }}
          title={translations.getLabel('lblGenericWarning')}
        >
          <p className="confirmation-message">{translations.getLabel('lblDeleteTermsAndConditionsWarning')}</p>
        </Modal>
      </div>
    </div>
  );
};

export default TermsAndConfitionsTab;
