import React, { useContext,useEffect, useState } from 'react';
import { Avatar, Button, Icon, IconButton, Modal,SelectUserModal } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

import ConfigContext from '../../../../context/ConfigContext';
import { useToggle } from '../../../../hooks';
import { configService,holdingAdminService } from '../../../../services';
import { translations } from '../../../../utils';

import './HACompanyAdminsTab.scss';

let didCancel = false;

const HACompanyAdminsTab = ({ companyId }) => {
  const context = useContext(ConfigContext);

  const [company, setCompany] = useState(null);
  const [adminsByCompany, setAdminsByCompany] = useState(null);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isConfirmationModalOpen, toggleConfirmationModalOpen] = useToggle(false);
  const [isAddModalOpen, toggleAddModalOpen] = useToggle(false);
  const [userId, setUserId] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    didCancel = false;

    getCompanies();
    return () => {
      didCancel = true;
    };
  }, [companyId]); //eslint-disable-line react-hooks/exhaustive-deps


  const getCompanies = async () => {
    const companies = await holdingAdminService.getCompanies(context.config.holdingId);
    const adminsByCompanies = await holdingAdminService.getCompanyAdmins(context.config.holdingId);

    const adminsByCompany = adminsByCompanies?.find(c => c.id === companyId);
    const company = companies?.find(c => c.id === companyId);
    if (!didCancel) {
      if (company) setCompany(company);
      if (adminsByCompany) setAdminsByCompany(adminsByCompany);
    }
  };

  const toggleConfirmationModal = (id) => {
    toggleConfirmationModalOpen();
    setUserId(id);
  };

  const removeAdmin = () => {
    const selectedUsers = getSelectedUsers();
    const users = selectedUsers.filter((user) => user !== userId);

    saveAdmins(users, true);
  };

  const openCompanyAdminModal = (companyId) => async () => {
    setIsLoadingUsers(true);
    const users = await holdingAdminService.getUsersOfCompany(context.config.holdingId, companyId);
    const selectedUsers = getSelectedUsers();

    setIsLoadingUsers(false);
    setUsers(users);
    setSelectedUsers(selectedUsers);
    toggleAddModalOpen();
  };

  const saveAdmins = async (selectedUsers, isDelete) => {
    setIsSaving(true);
    setSelectedUsers(selectedUsers);
    await holdingAdminService.saveCompanyAdmins(adminsByCompany.holdingId, companyId, selectedUsers);
    if (!didCancel) {
      if (!isDelete) toggleAddModalOpen();
      if (isDelete) toggleConfirmationModal();
      setIsSaving(false);
    }

    await getCompanies();
    const getMe = await configService.getMe();
    context.updateMe(getMe.me);
  };

  const getSelectedUsers = () => {
    const activeCompanyWithAdmins = { ...adminsByCompany };
    if (activeCompanyWithAdmins) {
      return activeCompanyWithAdmins.admins.reduce((acc, user) => {
        return [...acc, user.userId];
      }, []);
    }
    return [];
  };

  const searchUser = async (search) => {
    setIsLoadingUsers(true);
    const users = await holdingAdminService.getUsersOfCompany(context.config.holdingId, companyId, search);
    const selectedUsers = getSelectedUsers();
    setIsLoadingUsers(false);
    setUsers(users);
    setSelectedUsers(selectedUsers);
  };

  const renderAdmin = (admin, editing) => {
    const { firstName, lastName, userId, profilePictureUrl } = admin;
    if (editing) {
      return (<div key={userId} className="edit-company-admins-row">
        <Avatar firstName={firstName} lastName={lastName} source={profilePictureUrl} />
        <p className="admin-name">{`${firstName} ${lastName}`}</p>
        <IconButton onClick={() => toggleConfirmationModal(userId)} tag="TrashIcon" color="error" className="delete-admin-button" />
      </div>);
    }
    return ` ${firstName} ${lastName}`;
  };

  const renderCompany = () => {
    if (company && adminsByCompany) {
      const { id } = company;
      const companyWithAdmins = { ...adminsByCompany };
      companyWithAdmins.admins.sort((a, b) => {
        if (!a) return -1;
        if (!b) return 1;
        const nameA = `${(a.firstName || '').toLowerCase()}${(a.lastName || '').toLowerCase()}`;
        const nameB = `${(b.firstName || '').toLowerCase()}${(b.lastName || '').toLowerCase()}`;

        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
        return 0;
      });
      return (
        <div className="extra-content-wrapper">
          <div className="admin-rows">
            {companyWithAdmins.admins.map(admin => renderAdmin(admin, true))}
            <div className="add-company-admin-container" >
              {!isLoadingUsers ? <Button theme="transparent" onClick={openCompanyAdminModal(id)}>
                <Icon tag="PlusSquareIcon" color="primary" />
                <p>{translations.getLabel('btnAddCompanyAdmin')}</p>
              </Button> : <div className="skeleton-wrapper"><Skeleton height="1rem" /></div>}
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="ha-companies-container" >
      <div>
        {renderCompany()}
      </div>
      {
        isAddModalOpen &&
        <SelectUserModal
          cancelLabel={translations.getLabel('cancel')}
          saveLabel={translations.getLabel('save')}
          isLoading={isLoadingUsers}
          isOpen={isAddModalOpen}
          isSaveLoading={isSaving}
          onSearch={searchUser}
          onSelect={saveAdmins}
          requestClose={toggleAddModalOpen}
          searchLabel={translations.getLabel('lblSearchEmployee')}
          selectedUsers={selectedUsers}
          title={translations.getLabel('lblSelectCompanyAdmins')}
          users={users}
        />
      }
      <Modal
        requestClose={toggleConfirmationModal}
        open={isConfirmationModalOpen}
        rightButtonProps={{ label: translations.getLabel('lblDelete'), onClick: removeAdmin, isLoading: isSaving }}
        leftButtonProps={{ label: translations.getLabel('cancel'), onClick: toggleConfirmationModal }}
        title={translations.getLabel('lblRemoveAdmin')}
      >
        <p className="confirmation-message">{translations.getLabel('lblDeleteCompanyAdmin')}</p>
      </Modal>
    </div>
  );
};

HACompanyAdminsTab.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default HACompanyAdminsTab;
