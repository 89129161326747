import React, { useEffect, useState } from 'react';
import { InputDateField,InputField, InputType, Modal, Select } from 'react-ess-components';
import { toast } from 'react-toastify';
import { isBefore } from 'date-fns';
import PropTypes from 'prop-types';

import { timeRegistrationCheckTypes, timeRegistrationTypes as timeRegistrationTypesConstants } from '../../../../../../constants';
import { useForm } from '../../../../../../hooks';
import { translations } from '../../../../../../utils';

const INFORMATIVE_TYPE_INDEX = 1;

const TimeRegistrationProfileWizard = ({ timeRegistrationTypes, timeRegistrationType, save, isModalOpen, toggleIsModalOpen }) => {
  const [wizardStep, setWizardStep] = useState(0);
  const [isValid, setIsValid] = useState(false);
  const [isInformativeType, setIsInformativeType] = useState(false);
  const { form, setForm, setFormAttribute } = useForm({});

  const checkTypes = timeRegistrationCheckTypes.map(type => ({ value: type.key, label: translations.getLabel(type.label) }));

  useEffect(() => {
    if (!isModalOpen) {
      setForm({
        typeId: null,
        controlStart: checkTypes[0].value,
        controlEnd: checkTypes[0].value,
      });
      setWizardStep(0);
    }
    if (isModalOpen && timeRegistrationType) {
      setForm({
        id: timeRegistrationType.id || null,
        typeId: timeRegistrationType.typeId || timeRegistrationType.timeRegistrationTypeId,
        controlStart: timeRegistrationType.controlStart,
        controlEnd: timeRegistrationType.controlEnd,
        startDate: new Date(timeRegistrationType.startDate),
        endDate: new Date(timeRegistrationType.endDate),
      });
      setIsInformativeType(timeRegistrationType?.type === timeRegistrationTypesConstants[INFORMATIVE_TYPE_INDEX].key);
      setWizardStep(1);
    }
    setIsValid(false);
  }, [isModalOpen]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (form.endDate && form.startDate) {
      if (isBefore(form.endDate, form.startDate)) {
        toast.error(translations.getLabel('lblErrStartBeforeEndDate'));
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    }
  }, [form.endDate, form.startDate]);//eslint-disable-line react-hooks/exhaustive-deps

  const onChangeType = (typeId) => {
    const isInformative = timeRegistrationTypes.find((type) => type.id === typeId).type === timeRegistrationTypesConstants[INFORMATIVE_TYPE_INDEX].key;
    setForm({
      typeId,
      startDate: null,
      endDate: null,
      controlStart: checkTypes[0].value,
      controlEnd: isInformative ? null : checkTypes[0].value,
    });

    setIsInformativeType(isInformative);
  };

  const step = (direction) => {
    const position = wizardStep + direction;
    if (position < 0) toggleIsModalOpen();
    if (position > 1) {
      toggleIsModalOpen();
      save(form);
    }
    else setWizardStep(position);
  };

  return (
    <Modal
      open={isModalOpen}
      title={translations.getLabel(timeRegistrationType ? 'titelEditTimeRegistrationType' : 'titelAddTimeRegistrationType')}
      requestClose={() => toggleIsModalOpen()}
      leftButtonProps={{
        label: translations.getLabel(wizardStep === 0 || timeRegistrationType ? 'cancel' : 'prev'),
        onClick: () => step(timeRegistrationType ? -2 : -1),
      }}
      rightButtonProps={{
        label: translations.getLabel(wizardStep === 1 ? 'save' : 'next'),
        isLoading: false,
        disabled: (wizardStep === 0 && !form.typeId) || (wizardStep === 1 && !isValid),
        onClick: () => step(1),
      }}
    >
      {
        wizardStep === 0 &&
        <div className="inner-modal-container">
          <p className="description">{translations.getLabel('lblAddTimeRegistrationType')}</p>
          <div className="scroll-container">
            {
              <Select values={timeRegistrationTypes.map((type) => ({ value: type.id, label: type.generalDescription }))} selected={form.typeId} onChange={onChangeType} />
            }
          </div>
        </div>
      }
      {
        wizardStep === 1 &&
        <div className="inner-modal-container">
          <p className="description">{translations.getLabel('lblAddTimeRegistrationPeriod')}</p>
          <p className="title">{timeRegistrationTypes.find(type => type.id === form.typeId)?.description}</p>
          <div className="form-row">
            <div className="input-container">
              <p>{translations.getLabel('lblFromInput')}</p>
              <InputDateField value={{ date: form.startDate }} emptyIcon name="startDate" onChange={({ date }) => setFormAttribute(date, 'startDate')} />
            </div>
            <div className="input-container">
              <p>{translations.getLabel('lblUntilInput')}</p>
              <InputDateField value={{ date: form.endDate }} emptyIcon name="endDate" onChange={({ date }) => setFormAttribute(date, 'endDate')} />
            </div>
          </div>
          <div className="form-row">
            <div className="input-container">
              <p>{translations.getLabel('lblStartCheck')}</p>
              <InputField type={InputType.Select} value={form.controlStart} emptyIcon name="controlStart" options={checkTypes} onChange={setFormAttribute} />
            </div>
            {!isInformativeType && (
              <div className="input-container">
                <p>{translations.getLabel('lblEndCheck')}</p>
                <InputField type={InputType.Select} value={form.controlEnd} emptyIcon name="controlEnd" options={checkTypes} onChange={setFormAttribute} />
              </div>
            )}
          </div>
        </div>
      }
    </Modal>
  );
};


TimeRegistrationProfileWizard.propTypes = {
  timeRegistrationType: PropTypes.object,
  timeRegistrationTypes: PropTypes.array.isRequired,
  save: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool,
  toggleIsModalOpen: PropTypes.func.isRequired,
};

TimeRegistrationProfileWizard.defaultProps = {
  isModalOpen: false,
  timeRegistrationType: null,
};

export default TimeRegistrationProfileWizard;
