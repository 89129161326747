import React from 'react';
import PropTypes from 'prop-types';

import { companyAdminService } from '../../../../../services';
import { translations } from '../../../../../utils';
import PeriodEditItem from './PeriodEditItem';

const VacationAvailability = (props) => {
  const onSave = async (period) => {
    const newVacationPeriod = period.type === 'VACATION_REQUEST_PERIOD' ? { ...period } : props.periods.filter(c => c.type === 'VACATION_REQUEST_PERIOD')?.[0];
    const newAvailabilityPeriod = period.type === 'AVAILABILITY_REQUEST_PERIOD' ? { ...period } : props.periods.filter(c => c.type === 'AVAILABILITY_REQUEST_PERIOD')?.[0];
    const newPeriods = [];
    if (newVacationPeriod) {
      delete newVacationPeriod.updatedAt;
      newPeriods.push(newVacationPeriod);
    }
    if (newAvailabilityPeriod) {
      delete newAvailabilityPeriod.updatedAt;
      newPeriods.push(newAvailabilityPeriod);
    }

    await companyAdminService.updatePeriods(props.companyId, newPeriods);
    props.onSaved();
  };

  return (
    <>
      {/* VacationPeriod */}
      <PeriodEditItem
        showToday={props.showToday}
        period={props.periods?.filter(c => c.type === 'VACATION_REQUEST_PERIOD')?.[0]}
        isLoading={props.isLoading}
        onSave={(period) => onSave({ ...period, type: 'VACATION_REQUEST_PERIOD' })}
        title={translations.getLabel('titleEditVacationPeriod')}
        modalTitle={translations.getLabel('titleEditVacationPeriod')}
        label={translations.getLabel('lblVacationPeriodCompanyScheduleDescription')}
        emptyLabel={translations.getLabel('lblNotConfigured')}
        showDaysInAdvance
      />
      {/* AvailabilityPeriod */}
      <PeriodEditItem
        showToday={props.showToday}
        period={props.periods?.filter(c => c.type === 'AVAILABILITY_REQUEST_PERIOD')?.[0]}
        isLoading={props.isLoading}
        onSave={(period) => onSave({ ...period, type: 'AVAILABILITY_REQUEST_PERIOD' })}
        title={translations.getLabel('titleEditAvailabilityPeriod')}
        modalTitle={translations.getLabel('titleEditAvailabilityPeriod')}
        label={translations.getLabel('lblAvailabilityPeriodCompanyScheduleDescription')}
        emptyLabel={translations.getLabel('lblNotConfigured')}
        showDaysInAdvance
      />
    </>
  );
};

VacationAvailability.propTypes = {
  showToday: PropTypes.bool,
  companyId: PropTypes.string.isRequired,
  holdingId: PropTypes.string.isRequired,
  periods: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  onSaved: PropTypes.func.isRequired,
};

VacationAvailability.defaultProps = {
  periods: null,
};

export default VacationAvailability;
