import { Network } from '../utils';

export const getHoldings = async (limit = 10, offset = 0) => {
  const result = await Network.get('/v1/management/superuser/holdings', { limit, offset });

  if (result) return { data: result.data, totalCount: result.meta.totalCount };
};

export const getHoldingAdmins = async (holdingId) => {
  const result = await Network.get(`/v1/management/superuser/holdings/${holdingId}/admins`);
  if (result) return result.data;
};

export const getCompanyConfig = async (holdingId, companyId) => {
  const result = await Network.get(`/v1/management/superuser/holdings/${holdingId}/companies/${companyId}/config`);
  if (result) return result.data;
};

export const getHoldingCounters = async (holdingId) => {
  const result = await Network.get(`/v1/management/superuser/holdings/${holdingId}/counters`);
  return result.data;
};

export const getHoldingTransactions = async (holdingId) => {
  const result = await Network.get(`/v1/management/superuser/holdings/${holdingId}/transaction-types`);
  return result.data;
};

export const getHoldingSelfRosterCounters = async (holdingId) => {
  const result = await Network.get(`/v1/management/superuser/holdings/${holdingId}/self-roster-counters`);
  return result.data;
};

export const getHoldingUsers = async (holdingId, search = '') => {
  const result = await Network.get(`/v1/management/superuser/holdings/${holdingId}/users`, { search });
  if (result) return result.data;
};

export const addHoldingAdmin = async (holdingId, userId) => {
  const result = await Network.post(`/v1/management/superuser/holdings/${holdingId}/admins`, { userId });
  if (result) return result.data;
};

export const addHoldingCounter = async (holdingId, counter) => {
  const result = await Network.post(`/v1/management/superuser/holdings/${holdingId}/counters`, counter);
  if (result) return result.data;
};

export const addHoldingTransaction = async (holdingId, transaction) => {
  const result = await Network.post(`/v1/management/superuser/holdings/${holdingId}/transaction-types`, transaction);
  if (result) return result.data;
};

export const deleteHoldingAdmin = async (holdingId, userId) => {
  const result = await Network.delete(`/v1/management/superuser/holdings/${holdingId}/admins`, { userId });
  if (result) return result.data;
};

export const deleteHoldingCounter = async (holdingId, counterId) => {
  const result = await Network.delete(`/v1/management/superuser/holdings/${holdingId}/counters/${counterId}`);
  if (result) return result.data;
};

export const deleteHoldingTransaction = async (holdingId, transactionId) => {
  const result = await Network.delete(`/v1/management/superuser/holdings/${holdingId}/transaction-types/${transactionId}`);
  if (result) return result.data;
};

export const deleteHoldingSelfRosterCounters = async (holdingId) => {
  const result = await Network.delete(`/v1/management/superuser/holdings/${holdingId}/self-roster-counters`);
  if (result) return result.data;
};

export const getCompaniesOfHolding = async (holdingId) => {
  const result = await Network.get(`/v1/management/superuser/holdings/${holdingId}/companies`);
  if (result) return result.data;
};

export const addCompany = async (holdingId, name) => {
  const result = await Network.post(`/v1/management/superuser/holdings/${holdingId}/companies`, { name });
  if (result) return result.data;
};

export const deleteCompany = async (holdingId, companyId) => {
  const result = await Network.delete(`/v1/management/superuser/holdings/${holdingId}/companies/${companyId}`);
  if (result) return result.data;
};

export const updateCompany = async (holdingId, companyId, name) => {
  const result = await Network.put(`/v1/management/superuser/holdings/${holdingId}/companies/${companyId}`, { name });
  if (result) return result.data;
};

export const updateCompanyConfig = async (holdingId, companyId, config) => {
  const result = await Network.patch(`/v1/management/superuser/holdings/${holdingId}/companies/${companyId}/config`, config);
  if (result) return result.data;
};

export const addHolding = async (name, url) => {
  const result = await Network.post('/v1/management/superuser/holdings', { name, url });
  if (result) return result.data;
};

export const updateHolding = async (holdingId, body) => {
  const result = await Network.put(`/v1/management/superuser/holdings/${holdingId}`, body);
  if (result) return result.data;
};

export const updateHoldingLogin = async (holdingId, body) => {
  const result = await Network.put(`/v1/management/superuser/holdings/${holdingId}/login`, body);
  if (result) return true;
};

export const updateHoldingCounter = async (holdingId, counterId, counter) => {
  const result = await Network.patch(`/v1/management/superuser/holdings/${holdingId}/counters/${counterId}`, counter);
  if (result) return result.data;
};

export const updateHoldingTransaction = async (holdingId, transactionId, transaction) => {
  const result = await Network.patch(`/v1/management/superuser/holdings/${holdingId}/transaction-types/${transactionId}`, transaction);
  if (result) return result.data;
};

export const postHoldingSelfRosterCounter = async (holdingId, selfRosterCounterId, selfRosterCounter) => {
  const result = await Network.post(`/v1/management/superuser/holdings/${holdingId}/self-roster-counters/${selfRosterCounterId}`, selfRosterCounter);
  if (result) return result.data;
};

export const updateHoldingSelfRosterCounter = async (holdingId, selfRosterCounterId, selfRosterCounter) => {
  const result = await Network.put(`/v1/management/superuser/holdings/${holdingId}/self-roster-counters/${selfRosterCounterId}`, selfRosterCounter);
  if (result) return result.data;
};

export const getVacationTypes = async (holdingId) => {
  const result = await Network.get(`/v1/management/superuser/holdings/${holdingId}/vacation-type`);
  if (result) return result.data;
};

export const addVacationType = async (holdingId, vacationType) => {
  return await Network.post(`/v1/management/superuser/holdings/${holdingId}/vacation-type`, vacationType);
};

export const deleteVacationType = async (holdingId, vacationTypeId) => {
  return await Network.delete(`/v1/management/superuser/holdings/${holdingId}/vacation-type/${vacationTypeId}`);
};

export const updateVacationType = async (holdingId, vacationTypeId, vacationType) => {
  return await Network.patch(`/v1/management/superuser/holdings/${holdingId}/vacation-type/${vacationTypeId}`, vacationType);
};

export const getTimeRegistrationTypes = async (holdingId) => {
  const result = await Network.get(`/v1/management/superuser/holdings/${holdingId}/time-registration-types`);
  if (result) return result.data;
};

export const addTimeRegistrationType = async (holdingId, timeRegistrationType) => {
  return await Network.post(`/v1/management/superuser/holdings/${holdingId}/time-registration-types`, timeRegistrationType);
};

export const deleteTimeRegistrationType = async (holdingId, timeRegistrationTypeId) => {
  return await Network.delete(`/v1/management/superuser/holdings/${holdingId}/time-registration-types/${timeRegistrationTypeId}`);
};

export const updateTimeRegistrationType = async (holdingId, timeRegistrationTypeId, timeRegistrationType) => {
  return await Network.patch(`/v1/management/superuser/holdings/${holdingId}/time-registration-types/${timeRegistrationTypeId}`, timeRegistrationType);
};

export const getSpeakapConfig = async (holdingId) => {
  const result = await Network.get(`/v1/management/superuser/holdings/${holdingId}/speakap-connections`);
  if (result) return result.data;
};

export const addSpeakapConfig = async (holdingId, config) => {
  return await Network.post(`/v1/management/superuser/holdings/${holdingId}/speakap-connections`, config);
};

export const updateSpeakapConfig = async (holdingId, connectionId, config) => {
  return await Network.patch(`/v1/management/superuser/holdings/${holdingId}/speakap-connections/${connectionId}`, config);
};

export const deleteSpeakapConfig = async (holdingId, connectionId) => {
  return await Network.delete(`/v1/management/superuser/holdings/${holdingId}/speakap-connections/${connectionId}`);
};
