import React, { useEffect, useState } from 'react';
import { IconButton, InputField, Modal, Search } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

import { companyAdminService } from '../../../../../services';
import { translations } from '../../../../../utils';

import './companyEmployeesTab.scss';

let didCancel = false;

const sortOptions = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  USERNAME: 'username',
};

const order = {
  ASC: 'asc',
  DESC: 'desc',
};

const CompanyEmployees = ({ companyId }) => {
  const [users, setUsers] = useState([]);
  const [isUsersLoading, setIsUsersLoading] = useState(true);
  const [search, setSearch] = useState('');
  const [sort, setSort] = useState(sortOptions.LAST_NAME);
  const [sortOrder, setSortOrder] = useState(order.ASC);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [editId, setEditId] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    didCancel = false;
    getUsers();
    return () => {
      didCancel = true;
    };
  }, [companyId]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    didCancel = false;
    getUsers(search);
    return () => {
      didCancel = true;
    };
  }, [search, sortOrder]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (editId) setIsModalOpen(true);
    else setIsModalOpen(false);
    setPassword('');
  }, [editId]); //eslint-disable-line react-hooks/exhaustive-deps

  const getUsers = async (search) => {
    const result = await companyAdminService.getCompanyUsers(companyId, search, sort, sortOrder);
    if (!didCancel) {
      setIsUsersLoading(false);
      setUsers(result || []);
    }
  };

  const editPassword = async () => {
    setIsEditLoading(true);
    await companyAdminService.setPassword(companyId, editId, password);
    setIsEditLoading(false);
    setEditId('');
  };

  const renderRow = (credentials, i) => {
    return (
      <tr key={`cred-${i}`}>
        <td>{credentials.firstName}</td>
        <td>{credentials.lastName}</td>
        <td>{credentials.username}</td>
        <td className="icon-cell">
          <IconButton tag="UnlockedIcon" color="primary" onClick={() => setEditId(credentials.id)} />
        </td>
      </tr>
    );
  };

  const renderSkeleton = (key) => <td key={key}><Skeleton width="100%" height="3rem" /></td>;

  const setSortAndOrder = (newSort) => {
    if (sort === newSort) {
      if (sortOrder === order.ASC) setSortOrder(order.DESC);
      else setSortOrder(order.ASC);
    }
    setSort(newSort);
  };

  return (
    <div className="counters-tab employee-tab">
      <div className="search-container">
        <Search submit={setSearch} startValue={search} placeholder={translations.getLabel('lblSearchEmployee')} label={translations.getLabel('lblSearchEmployee')} />
      </div>
      <table className="table" >
        <tbody>
          <tr>
            <th onClick={() => setSortAndOrder(sortOptions.FIRST_NAME)}>{translations.getLabel('lblFirstName')}
              {
                sortOrder === order.ASC ?
                  <IconButton className="sort-icon" tag="ChevronUpIcon" color={sort === sortOptions.FIRST_NAME ? 'primary' : 'transparent'} />
                  :
                  <IconButton className="sort-icon" tag="ChevronDownIcon" color={sort === sortOptions.FIRST_NAME ? 'primary' : 'transparent'} />
              }
            </th>
            <th onClick={() => setSortAndOrder(sortOptions.LAST_NAME)}>{translations.getLabel('lblLastName')}
              {
                sortOrder === order.ASC ?
                  <IconButton className="sort-icon" tag="ChevronUpIcon" color={sort === sortOptions.LAST_NAME ? 'primary' : 'transparent'} />
                  :
                  <IconButton className="sort-icon" tag="ChevronDownIcon" color={sort === sortOptions.LAST_NAME ? 'primary' : 'transparent'} />
              }
            </th>
            <th onClick={() => setSortAndOrder(sortOptions.USERNAME)}>{translations.getLabel('lblUsername')}
              {
                sortOrder === order.ASC ?
                  <IconButton className="sort-icon" tag="ChevronUpIcon" color={sort === sortOptions.USERNAME ? 'primary' : 'transparent'} />
                  :
                  <IconButton className="sort-icon" tag="ChevronDownIcon" color={sort === sortOptions.USERNAME ? 'primary' : 'transparent'} />
              }
            </th>
            <th className="empty-cell"></th>
          </tr>
          {
            !isUsersLoading ?
              users.map(renderRow)
              :
              [1, 2, 3, 4].map(renderSkeleton)
          }
        </tbody>
      </table>

      <Modal
        open={isModalOpen}
        title={translations.getLabel('lblEditPasswordEmployee')}
        requestClose={() => setEditId('')}
        leftButtonProps={{
          label: translations.getLabel('cancel'),
          onClick: () => setEditId(''),
        }}
        rightButtonProps={{
          label: translations.getLabel('save'),
          isLoading: isEditLoading,
          onClick: editPassword,
        }}>
        <p className="text">{translations.getLabel('lblNewPasswordEmployee', { name: users.find(u => u.id === editId)?.username })}</p>
        <InputField type="text" label={translations.getLabel('lblPassword')} onChange={setPassword} value={password} />
      </Modal>
    </div>
  );
};

CompanyEmployees.propTypes = {
  companyId: PropTypes.string.isRequired,
};


export default CompanyEmployees;
