import React, { useEffect,useState } from 'react';
import { Button, Icon } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

import { holdingAdminService } from '../../../../../services';
import { translations } from '../../../../../utils';
import TimeRegistrationProfile from './components/TimeRegistrationProfile';

import './TimeRegistrationTab.scss';

let didCancel = false;

const TimeRegistrationTab = ({ holdingId, companyId }) => {
  const [timeRegistrationTypes, setTimeRegistrationTypes] = useState([]);
  const [timeRegistrationProfiles, setTimeRegistrationProfiles] = useState([]);
  const [activeId, setActiveId] = useState('');
  const [isInitialLoading, setIsInitialLoading] = useState(false);

  useEffect(() => {
    didCancel = false;
    setIsInitialLoading(true);
    getData();

    return () => {
      didCancel = true;
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  const getData = (async () => {
    const types = await holdingAdminService.getTimeRegistrationTypes(holdingId);
    const profiles = await holdingAdminService.getTimeRegistrationProfiles(holdingId, companyId);
    if (!didCancel) {
      if (types) setTimeRegistrationTypes(types);
      if (profiles) setTimeRegistrationProfiles(profiles);
      setIsInitialLoading(false);
    }
  });

  const addProfile = () => {
    setActiveId('add');
  };

  const renderProfile = (profile) => {
    return <TimeRegistrationProfile key={profile.id} timeRegistrationTypes={timeRegistrationTypes} holdingId={holdingId} companyId={companyId} profile={profile} setActiveId={setActiveId} isActive={profile.id === activeId} getData={getData} />;
  };

  return (
    <div className="counters-tab time-registration-tab">
      <h4>{translations.getLabel('titelTimeRegistration')}</h4>
      <p className="label">{translations.getLabel('lblConfigureTimeRegistration')}</p>
      {isInitialLoading && <div className="loader"><Skeleton height={30} /></div>}
      {
        !isInitialLoading && (timeRegistrationProfiles.length > 0 ?
          timeRegistrationProfiles.map(renderProfile)
          :
          <p className="empty-types">{translations.getLabel('lblEmptyTimeRegistrationProfiles')}</p>

        )
      }
      {
        activeId === 'add' &&
        <div>
          <TimeRegistrationProfile timeRegistrationTypes={timeRegistrationTypes} holdingId={holdingId} companyId={companyId} activeId={activeId} setActiveId={setActiveId} isActive={true} getData={getData} />
        </div>
      }
      {
        !activeId &&
        <Button theme="transparent" onClick={addProfile}>
          <Icon tag="PlusSquareIcon" color="primary" />
          <p className="button-label">{translations.getLabel('btnAddProfile')}</p>
        </Button>
      }
    </div>
  );
};

TimeRegistrationTab.propTypes = {
  holdingId: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
};

export default TimeRegistrationTab;
