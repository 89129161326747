import React, { Component } from 'react';
import { Button, FormattedValue, Icon } from 'react-ess-components';
import PropTypes from 'prop-types';

import { translations } from '../../../../../../utils';
import Counter from '../counter/Counter';

import './CounterGroup.scss';

const noop = () => { };

export default class CounterGroup extends Component {
  constructor(props) {
    super(props);
    this.state = { isEditing: false };
  }

  onRemove = (id) => () => {
    this.props.onRemove(id);
  }

  renderValue = (counter, i) => {
    return counter && (
      <div className="counter" key={`${counter.description}-${i}`}>
        <p className="description">
          {counter.description}
        </p>
        <FormattedValue value={counter.value} type={counter.type} onRemove={this.onRemove(counter.id)} isEditing={this.props.isEditing} />
      </div>
    );
  }

  toggleEdit = () => {
    this.props.toggleEdit({ id: this.props.group.groupId });
  }

  checkValidity = () => !this.props.group.group;

  render() {
    return (
      <div className="counter-group">
        {this.props.group.counters &&
          <Counter title={this.props.group.group} onEdit={this.toggleEdit} isGroup isEditing={this.props.isEditing} onChangeTitle={this.props.onChangeTitle} isEditDisabled={this.props.isEditDisabled} onRemoveGroup={this.props.onRemoveGroup}>
            <div className="counters-container">
              {this.props.group.counters.map(this.renderValue)}
              {
                this.props.isEditing && this.props.shouldShowAdd &&
                <div className="counter add-counter" onClick={this.props.toggleSelect}>
                  <Icon tag="PlusIcon" large />
                </div>
              }
            </div>
            {
              this.props.isEditing &&
              <div className="button-container">
                <Button theme="inverse" onClick={this.props.onCancel}>{translations.getLabel('cancel')}</Button>
                <Button onClick={this.props.onSave} disabled={this.checkValidity()}>{translations.getLabel('save')}</Button>
              </div>
            }
          </Counter>
        }
      </div>
    );
  }
}

CounterGroup.propTypes = {
  group: PropTypes.object.isRequired,
  toggleSelect: PropTypes.func.isRequired,
  toggleEdit: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onRemoveGroup: PropTypes.func.isRequired,
  onChangeTitle: PropTypes.func,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  isEditing: PropTypes.bool,
  shouldShowAdd: PropTypes.bool,
  isEditDisabled: PropTypes.bool,
};

CounterGroup.defaultProps = {
  isEditing: false,
  shouldShowAdd: false,
  onChangeTitle: noop,
  onSave: noop,
  onCancel: noop,
  isEditDisabled: false,
  onRemoveGroup: noop,
};
