import Axios from 'axios';

import { routes,storageItems } from '../constants';

export const LOGGED_OUT_ERROR = 'LOGGED_OUT_ERROR';

export const setNetworkAuthInterceptor = (storageMethod) => {
  Axios.interceptors.response.use(function (response) {
    return response;
  }, async (error) => {
    if (error.response && error.response.status === 401) {
      const isSuperAdmin = localStorage.getItem(storageItems.IS_SUPER_ADMIN) === 'true';
      localStorage.setItem(storageItems.IS_LOGGED_IN, 'false');
      localStorage.setItem(storageItems.IS_SUPER_ADMIN, 'false');
      localStorage.setItem(storageItems.HOLDING, '');
      // If it's not the /me call, we redirect to login page. (failing of the /me call is handled in App.js itself)
      if (!error.response?.request?.responseURL.includes('/v1/me')) {
        window.location.pathname = isSuperAdmin ? routes.loginSuperAdmin : routes.login;
      }
      return Promise.reject({ response: LOGGED_OUT_ERROR });
    } else {
      return Promise.reject(error);
    }
  });
};
