import React, { useEffect, useState } from 'react';
import { IconButton, InputField, InputType, Modal, RadioButton } from 'react-ess-components';
import PropTypes from 'prop-types';

import { services } from '../../../../../constants/selfRosterProfileServices';
import { useForm, useSelectArray } from '../../../../../hooks';
import { SelfRosterProfileType } from '../../../../../models';
import { companyAdminService } from '../../../../../services';
import { translations } from '../../../../../utils';

import './../selfRoster.scss';

let didCancel = false;

const SelfRosterProfileModal = ({ isModal1Open, isModal2Open, switchModal, companyId, onSave, profile, departments }) => {
  const {
    selectArray: selectedDepartments,
    select: selectSelectedDepartments,
    clear: clearSelectedDepartments,
    set: setSelectedDepartments,
  } = useSelectArray([]);
  const [isLoading, setIsLoading] = useState(false);

  const { form, setForm, setFormAttribute, clearForm } = useForm({
    name: profile?.name || '',
    type: profile?.type || SelfRosterProfileType.SelfRoster,
    showShifts: profile?.showShifts || services.LARGER_THAN_ZERO,
    checkIterationOne: profile?.checkIterationOne || false,
    checkIterationTwo: profile?.checkIterationTwo || false,
  });

  useEffect(() => {
    setForm({
      name: profile?.name || '',
      type: profile?.type || SelfRosterProfileType.SelfRoster,
      showShifts: profile?.showShifts || services.LARGER_THAN_ZERO,
      checkIterationOne: profile?.checkIterationOne || false,
      checkIterationTwo: profile?.checkIterationTwo || false,
    });
    setSelectedDepartments(profile?.departmentIds || []);
  }, [profile]);

  const saveProfile = async () => {
    setIsLoading(true);

    if (profile) {
      await companyAdminService.updateSelfRosterProfile(companyId, profile.id, { ...form, departmentIds: selectedDepartments });
    } else {
      await companyAdminService.postSelfRosterProfile(companyId, { ...form, departmentIds: selectedDepartments });
    }

    if (!didCancel) {
      switchModal(false, false);
      setIsLoading(false);
      clearForm();
    }

    onSave();
  };

  const handleAddDepartment = (departmentId) => {
    if (departmentId === 'allDepartments') {
      if (selectedDepartments.length === departments?.length) clearSelectedDepartments();
      else setSelectedDepartments(departments?.map((department) => department.id));
    } else {
      selectSelectedDepartments(departmentId);
    }
  };

  const closeModal = () => {
    switchModal(false, false);
    clearForm();
  };

  return (
    <>
      <Modal
        title={translations.getLabel('titleConfigureSelfRosterProfile')}
        open={isModal1Open}
        requestClose={closeModal}
        rightButtonProps={{
          label: translations.getLabel('next'),
          onClick: () => switchModal(false, true),
          isLoading,
          disabled: (!form.name),
        }}
        leftButtonProps={{ label: translations.getLabel('cancel'), onClick: closeModal }}
      >
        <div className="add-profile-modal">
          <div className="tabs">
            <p className="label active">{translations.getLabel('lblConfiguration')}</p>
            <p className="label" onClick={() => switchModal(false, true)}>{translations.getLabel('lblDepartments')}</p>
          </div>
          <div className="input-field-container">
            <p className="title">{translations.getLabel('lblNameProfile')}</p>
            <InputField className="input-field-select" type={InputType.Text} value={form.name} emptyIcon onChange={(value) => setFormAttribute(value, 'name')} />
          </div>
          <div className="input-field-container">
            <p className="title">{translations.getLabel('lblRuleChecks')}</p>
            <div className="checkbox-tag">
              <IconButton color="primary" tag={form.checkIterationOne ? 'CheckedIcon' : 'UncheckedIcon'} onClick={() => setFormAttribute(!form.checkIterationOne, 'checkIterationOne')} />
              <p>{translations.getLabel('lblRuleChecksRound1')}</p>
            </div>
            <div className="checkbox-tag">
              <IconButton color="primary" tag={form.checkIterationTwo ? 'CheckedIcon' : 'UncheckedIcon'} onClick={() => setFormAttribute(!form.checkIterationTwo, 'checkIterationTwo')} />
              <p>{translations.getLabel('lblRuleChecksRound2')}</p>
            </div>
          </div>
          <div className="input-field-container">
            <p className="title">{translations.getLabel('lblShowServices')}</p>
            <div className="radio-button">
              <RadioButton
                name="radio"
                label={translations.getLabel('lblOccupanyGreater')}
                checked={form.showShifts === services.LARGER_THAN_ZERO}
                onChange={() => setFormAttribute(services.LARGER_THAN_ZERO, 'showShifts')}
              />
              <RadioButton
                name="radio"
                label={translations.getLabel('lblOccupanyGreaterAndFunction')}
                checked={form.showShifts === services.LARGER_THAN_ZERO_AND_FUNCTION}
                onChange={() => setFormAttribute(services.LARGER_THAN_ZERO_AND_FUNCTION, 'showShifts')}
              />
            </div>
          </div>
        </div>
      </Modal>

      <Modal
        title={translations.getLabel('titleConfigureSelfRosterProfile')}
        open={isModal2Open}
        requestClose={() => switchModal(false, false)}
        rightButtonProps={{
          label: translations.getLabel('save'),
          onClick: saveProfile,
          isLoading,
        }}
        leftButtonProps={{
          label: translations.getLabel('cancel'), onClick: () => switchModal(true, false),
        }}
      >
        <div className="add-profile-modal">
          <div className="tabs">
            <p className="label" onClick={() => switchModal(true, false)} >{translations.getLabel('lblConfiguration')}</p>
            <p className="label active">{translations.getLabel('lblDepartments')}</p>
          </div>
          <div className="input-field-container">
            <p className="title">{translations.getLabel('lblDepartmentsToUseProfile')}</p>
            {departments?.length > 0 ? <div className="departments-table">
              <div className="checkbox-tag">
                <IconButton color="primary" tag={selectedDepartments.length === departments?.length ? 'CheckedIcon' : 'UncheckedIcon'} onClick={() => handleAddDepartment('allDepartments')} />
                <p>{translations.getLabel('lblAllDepartments')}</p>
              </div>
              {departments?.map((department) =>
                <div key={department?.id} className="checkbox-tag">
                  <IconButton color="primary" tag={selectedDepartments.includes(department.id) ? 'CheckedIcon' : 'UncheckedIcon'} onClick={() => handleAddDepartment(department?.id)} />
                  <p>{department?.name}</p>
                </div>
              )}
            </div> : <p>{translations.getLabel('lblNoDepartments')}</p>
            }
          </div>
        </div>
      </Modal>
    </>

  );
};

SelfRosterProfileModal.propTypes = {
  isModal1Open: PropTypes.bool.isRequired,
  isModal2Open: PropTypes.bool.isRequired,
  switchModal: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  profile: PropTypes.object,
  companyId: PropTypes.string.isRequired,
};

export default SelfRosterProfileModal;
