import { toast } from 'react-toastify';
import Axios from 'axios';

import { translations } from '../utils/';

const excludedRoutes = ['auth/login', '/auth/login/superuser', '/auth/logout'];

export const setNetworkErrorInterceptor = () => {
  Axios.interceptors.response.use(function (response) {
    return response;
  }, async (error) => {
    if (error.response && error.response.config.url.includes(excludedRoutes)) throw error;

    let coveredScenario = false;
    if (error.message === 'CALL_CANCELLED') { return { error: true, errors: error.response }; }
    if (error.response?.status === 403) { toast.error(translations.getLabel('lblNoPermission')); coveredScenario = true; }
    if (!coveredScenario) {
      if (typeof error.response?.data?.errors?.[0].detail === 'string') {
        toast.error(error.response?.data?.errors?.[0].detail);
      } else {
        toast.error(translations.getLabel('lblGenericError'));
      }
      return { error: true, errors: error.response };
    }
  });
};
