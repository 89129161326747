import React, { useContext,useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import ConfigContext from '../../../../../context/ConfigContext';
import { holdingAdminService } from '../../../../../services';
import DashboardCounterGroup from '../components/dashboardCounterGroup/DashboardCounterGroup';
import VacationCounters from '../components/vacationCounters/VacationCounters';

import '../CountersTab.scss';

let didCancel = false;

const CountersTab = (props) => {
  const context = useContext(ConfigContext);

  const [allCounters, setAllCounters] = useState([]);
  const [isAllLoading, setIsAllLoading] = useState(true);


  const getCountersData = async () => {
    let allCounters = [];
    if (context.allCounters?.length) {
      allCounters = [...context.allCounters];
    } else {
      allCounters = await holdingAdminService.getAllCounters(context.config.holdingId);
      context.updateAllCounters(allCounters);
    }
    if (!didCancel) {
      setAllCounters(allCounters?.map(counter => ({ value: counter.id, label: counter.description })));
      setIsAllLoading(false);
    }
  };

  useEffect(() => {
    didCancel = false;

    getCountersData();

    return () => {
      didCancel = true;
    };
  }, [props.companyId]); //eslint-disable-line react-hooks/exhaustive-deps

  const closePopups = (cb) => async () => {
    cb();
  };

  return (
    <div className="counters-tab">
      <DashboardCounterGroup companyId={props.companyId} closePopups={closePopups} allCounters={allCounters} isAllLoading={isAllLoading} />
      <VacationCounters companyId={props.companyId} closePopups={closePopups} allCounters={allCounters} isAllLoading={isAllLoading} />
    </div>
  );
};

CountersTab.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default CountersTab;
