import React, { useContext, useEffect,useState } from 'react';
import { FormattedValue,SelectPopup } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import ConfigContext from '../../../../../../context/ConfigContext';
import { holdingAdminService } from '../../../../../../services';
import { translations } from '../../../../../../utils';
import Counter from '../counter/Counter';

export default function DashboardCounterGroup({ closePopups, companyId, allCounters: allCountersProp, isAllLoading }) {
  const context = useContext(ConfigContext);

  const [counters, setCounters] = useState([]);
  const [allCounters, setAllCounters] = useState([]);
  const [isAddingDashboard, setIsAddingDashboard] = useState(false);
  const [selectedCounters, setSelectedCounters] = useState([]);
  const [isCountersLoading, setIsCountersLoading] = useState(true);

  const getCountersData = async () => {
    const counters = await holdingAdminService.getDashboardCounters(context.config.holdingId, companyId);

    setCounters(counters ? counters.splice(0, 4) : []);
    setAllCounters(allCountersProp);
    setIsCountersLoading(false);
  };

  useEffect(() => {
    getCountersData();
  }, [allCountersProp]); //eslint-disable-line react-hooks/exhaustive-deps

  const renderValue = (counter, i) => <FormattedValue value={counter.value} type={counter.type} description={counter.description} key={`counter-${i}`} />;
  const renderSkeleton = (i) => <div key={`skeleton-${i}`} className="skeleton-wrapper"><Skeleton width="15rem" height="10.5rem" /></div>;


  const handleSaveDashboard = async () => {
    if (selectedCounters.length > 4) return toast.error(translations.getLabel('lblMaxSelection'));
    setIsCountersLoading(true);
    toggleSelectDashboard();
    await holdingAdminService.updateDashboardCounters(context.config.holdingId, companyId, { counters: selectedCounters });
    const counters = await holdingAdminService.getDashboardCounters(context.config.holdingId, companyId);
    setIsCountersLoading(false);
    setCounters(counters || []);
    setSelectedCounters([]);
  };

  const toggleSelectDashboard = () => {
    const selection = [];

    if (!isAddingDashboard) {
      counters.map(counter => selection.push(counter.id));
    }

    setIsAddingDashboard(!isAddingDashboard);
    setSelectedCounters(selection);
  };

  const onChangeCounters = (selectedCounters) => {
    setSelectedCounters(selectedCounters.splice(0, 4));
  };

  return (
    <div className="counter-container">
      <p className="label">{translations.getLabel('lblConfigureCounters')}</p>
      <Counter title={translations.getLabel('titleCounters')} onEdit={toggleSelectDashboard} >
        {
          isCountersLoading || isAllLoading
            ?
            <React.Fragment>
              {
                [0, 1, 2, 3].map(renderSkeleton)
              }
            </React.Fragment>
            :
            counters.length
              ?
              counters.map(renderValue)
              :
              <p>{translations.getLabel('lblNoCounters')}</p>
        }
      </Counter>

      <SelectPopup
        data={allCounters}
        popupOpen={isAddingDashboard}
        isMultiple
        isModal
        selected={selectedCounters}
        title={translations.getLabel('titleAddCountersDashboard')}
        requestClose={closePopups(toggleSelectDashboard)}
        onValueSelected={onChangeCounters}
        onSave={handleSaveDashboard}
        acceptLabel={translations.getLabel('save')}
        cancelLabel={translations.getLabel('cancel')}
        isSearchEnabled={false}
        isAllowedEmpty
      />
    </div>
  );
}

DashboardCounterGroup.propTypes = {
  companyId: PropTypes.string.isRequired,
  closePopups: PropTypes.func.isRequired,
  allCounters: PropTypes.array.isRequired,
  isAllLoading: PropTypes.bool,
};

DashboardCounterGroup.defaultProps = {
  isAllLoading: true,
};