import React, { PureComponent } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { OpenSiteButton } from '..';

import './tabNavigator.scss';

export default class TabNavigator extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tabsLeft: null,
      component: null,
      mainTab: null,
      verticalSelectedTab: null,
    };
  }

  componentDidMount() {
    this.getComponentFromRoute(this.props.match.params[0]);
  }

  getComponentFromRoute = (route) => {
    const splitRoute = route.split('/');
    const horizontalTab = splitRoute[0];
    const verticalTab = splitRoute[1];

    // Clear side tabs when other tab does not have sidetabs
    this.setState({ tabsLeft: null });

    const component = this.matchComponent(this.props.tabsStructure, horizontalTab, verticalTab);
    this.setState({ component });
  }

  matchComponent = (tabsStructure, horizontalTab, verticalTab) => {
    const Tab = tabsStructure.find(x => x.url === horizontalTab);
    if (!Tab) return; // TODO: show notfound component or redirect?
    this.setState({ mainTab: Tab });

    if (!Tab.sideTabs) return Tab.component;

    if (Tab.sideTabs) {
      this.setState({ tabsLeft: Tab.sideTabs });

      let correctSideTab = null;
      if (!verticalTab) correctSideTab = Tab.sideTabs[0];
      else correctSideTab = Tab.sideTabs.find(sideTab => sideTab.url === verticalTab);
      if (correctSideTab) {
        this.setState({ verticalSelectedTab: correctSideTab });
        return correctSideTab.component;
      }
    }
  }

  navigateTab = (route) => {
    // Set correct URL
    this.props.history.push(`${this.props.routePrefix}/${route}`);
    this.getComponentFromRoute(route);
  }

  renderHorizontalTab = (horizontalTab, index) => {
    const className = classnames('horizontal-tab', { 'selected-horizontal': this.state.mainTab && this.state.mainTab.url === horizontalTab.url });
    return (<div className={className} key={index} onClick={() => this.navigateTab(horizontalTab.url)}>{horizontalTab.label}</div>);
  }

  renderVerticalTab = (verticalTab, index) => {
    const className = classnames('left-tab ', { 'left-tab-selected': this.state.verticalSelectedTab && this.state.verticalSelectedTab.url === verticalTab.url });
    return (<div className={className} role="button" key={index} onClick={() => this.navigateTab(`${this.state.mainTab.url}/${verticalTab.url}`)}>{verticalTab.label}</div>);
  }

  render() {
    const Component = this.state.component;

    return (
      <div className="management-tab-container">
        <div className="horizontal-tabs-container">
          {this.props.tabsStructure.map((horizontalTab, index) => { return this.renderHorizontalTab(horizontalTab, index); })}
        </div>
        <div className="main-container">
          {this.state.tabsLeft &&
            <div className="vertical-tabs-container">
              {this.state.tabsLeft.map((tableft, index) => { return this.renderVerticalTab(tableft, index); })}
            </div>
          }
          <div className="component-wrapper">
            {Component ? <Component holdingId={this.props.holdingId} history={this.props.history} match={this.props.match} companyId={this.props.companyId} /> : null}
          </div>
        </div>
        {
          this.props.match.params[0].includes('branding') &&
          <OpenSiteButton />
        }
      </div >
    );
  }
}

TabNavigator.propTypes = {
  match: PropTypes.any.isRequired,
  params: PropTypes.any,
  tabsStructure: PropTypes.array.isRequired,
  routePrefix: PropTypes.string.isRequired,
  history: PropTypes.any.isRequired,
  holdingId: PropTypes.string,
  companyId: PropTypes.string,
};

TabNavigator.defaultProps = {
  params: {},
  companyId: '',
  holdingId: '',
};
