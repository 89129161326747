import React, { PureComponent } from 'react';
import { Button, Icon, IconButton, InputField, InputType,Modal } from 'react-ess-components';
import PropTypes from 'prop-types';

import { languages } from '../../../../../../constants/languages';
import { translations } from '../../../../../../utils';

import './Credentials.scss';

export default class Credentials extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      addOrEditCredentials: {},
      editIndex: null,
      isAdding: false,
      isConfirmationModalOpen: false,
      isRemoveLoading: false,
      removeIndex: '',
    };
  }

  getEditableLanguages = () => {
    let editableLanguages = this.getLanguages();

    if (this.state.editIndex !== null) {
      const language = this.props.credentials[this.state.editIndex].language;
      editableLanguages.push({ value: language, label: translations.getLabel(languages.find(lang => lang.value === language).label) });
    }

    return editableLanguages;
  }

  getLanguage = (credentialsLanguage) => {
    const language = languages.find(lang => lang.value === credentialsLanguage);


    if (language) return translations.getLabel(language.label);
    return '';
  }

  getLanguages = () => {
    const languages = this.props.getLanguages();
    return languages.filter(lang => !this.props.credentials.find(credential => credential.language === lang.value));
  }

  renderRow = (credentials, i) => {
    return (
      <tr key={`cred-${i}`}>
        <td>{this.getLanguage(credentials.language)}</td>
        <td>{credentials.username}</td>
        <td>***</td>
        <td className="icon-cell">
          <IconButton tag="EditIcon" color="primary" onClick={this.setEdit(i)} />
        </td>
        <td className="icon-cell">
          <IconButton tag="TrashIcon" color="error" onClick={this.toggleConfirmationModal(i)} />
        </td>
      </tr>
    );
  }

  toggleAdd = (shouldReset = true) => {
    if (shouldReset) return this.setState({ isAdding: !this.state.isAdding, addOrEditCredentials: {}, editIndex: null });
    this.setState({ isAdding: !this.state.isAdding });
  }

  setEdit = (i) => () => {
    this.setState({ editIndex: i, addOrEditCredentials: { ...this.props.credentials[i] } }, () => {
      this.toggleAdd(false);
    });
  }

  toggleConfirmationModal = (id) => () => this.setState({ isConfirmationModalOpen: !this.state.isConfirmationModalOpen, removeIndex: id });

  onChange = (key) => (data) => {
    const addOrEditCredentials = { ...this.state.addOrEditCredentials };
    addOrEditCredentials[key] = data;

    this.setState({ addOrEditCredentials });
  }

  checkValidity = () => {
    const { username, password, repeatPassword } = this.state.addOrEditCredentials;

    if (!username) return false;
    if (this.state.editIndex === null && !password) return false;
    if (this.state.editIndex === null && !repeatPassword) return false;
    if (password !== repeatPassword) return false;
    if ((password || repeatPassword) && password.length < 6) return false;

    return true;
  }

  onSave = () => {
    const { username, password, language } = this.state.addOrEditCredentials;

    if (this.state.editIndex !== null) {
      const items = [...this.props.credentials];
      const item = items[this.state.editIndex];
      items[this.state.editIndex] = {
        ...item,
        username: username || item.username,
        password: password || item.password,
        language: language || item.language,
      };

      this.props.save(items);
    } else {
      this.props.save([...this.props.credentials, { username, password, language: language || this.getLanguages()[0].value }]);
    }

    this.setState({ editIndex: null, addOrEditCredentials: {}, isAdding: false });
  }

  onDelete = async () => {
    this.setState({ isRemoveLoading: true });
    await this.props.save(this.props.credentials.filter((_, index) => this.state.removeIndex !== index));
    this.setState({ isRemoveLoading: false, removeIndex: '', isConfirmationModalOpen: false });
  }

  render() {
    return (
      <div className="credentials-container">
        <h4>{translations.getLabel('titleCredentials')}</h4>
        <table className="table" >
          <tbody>
            <tr>
              <th>{translations.getLabel('lblLanguage')}</th>
              <th>{translations.getLabel('lblUsername')}</th>
              <th>{translations.getLabel('lblPassword')}</th>
              <th />
              <th />
            </tr>
            {
              this.props.credentials.map(this.renderRow)
            }
          </tbody>
        </table>
        <div className="add">
          <Button theme="transparent" onClick={this.toggleAdd} disabled={this.getLanguages().length <= 0}>
            <Icon tag="PlusSquareIcon" color="primary" />
            <p>{translations.getLabel('btnAddCredentials')}</p>
          </Button>
        </div>
        <Modal
          requestClose={this.toggleAdd}
          open={this.state.isAdding}
          rightButtonProps={{ label: translations.getLabel('save'), onClick: this.onSave, disabled: !this.checkValidity() }}
          leftButtonProps={{ label: translations.getLabel('cancel'), onClick: this.toggleAdd }}
          title={translations.getLabel('titleCredentials')}
        >
          <div className="form-container">
            {
              this.getLanguages().length > 0 &&
              <InputField value={this.state.addOrEditCredentials.language || this.getEditableLanguages()[0].value} type={InputType.Select} onChange={this.onChange('language')} label={translations.getLabel('lblLanguage')} options={this.getEditableLanguages()} emptyIcon />
            }
            <InputField disabled={this.state.editIndex !== null} emptyIcon value={this.state.addOrEditCredentials.username} type={InputType.Text} onChange={this.onChange('username')} label={translations.getLabel('lblUsername')} />
            <InputField emptyIcon value={this.state.addOrEditCredentials.password} placeholder="******" type={InputType.Password} onChange={this.onChange('password')} label={translations.getLabel('lblPassword')} />
            <InputField emptyIcon value={this.state.addOrEditCredentials.repeatPassword} placeholder="******" type={InputType.Password} onChange={this.onChange('repeatPassword')} label={translations.getLabel('lblRepeatPassword')} />
            <p className="info-password">{translations.getLabel('api_error_password_too_short')}</p>
          </div>
        </Modal>

        <Modal
          requestClose={this.toggleConfirmationModal()}
          open={this.state.isConfirmationModalOpen}
          rightButtonProps={{ label: translations.getLabel('lblDelete'), onClick: this.onDelete, isLoading: this.state.isRemoveLoading }}
          leftButtonProps={{ label: translations.getLabel('cancel'), onClick: this.toggleConfirmationModal() }}
          title={translations.getLabel('lblDeleteCredentials')}
        >
          <p className="confirmation-message">{translations.getLabel('lblDeleteCredentialsWarning')}</p>
        </Modal>
      </div>
    );
  }
}

Credentials.propTypes = {
  credentials: PropTypes.array.isRequired,
  save: PropTypes.func.isRequired,
  getLanguages: PropTypes.func.isRequired,
};
