import React, { Component } from 'react';
import { Button } from 'react-ess-components';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import * as IMAGES from '../../assets';
import { storageItems } from '../../constants';
import ConfigContext from '../../context/ConfigContext';
import { configService,loginService } from '../../services';
import { translations } from '../../utils';

import './Login.scss';

class LoginView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      password: '',
      holding: '',
      isLoading: false,
      error: false,
    };

    this.ref = null;
  }

  onLoginUser = async (event) => {
    event.preventDefault();
    this.setState({ isLoading: true });
    try {
      const isSuperAdmin = this.isSuperAdmin();
      await loginService.login(this.state.username, this.state.password, isSuperAdmin, isSuperAdmin ? {} : { url: this.state.holding });
      let result;
      if (!isSuperAdmin) {
        const getMe = await configService.getMe();

        if (!getMe.me.isCompanyAdmin && !getMe.me.isHoldingAdmin) throw new Error();

        this.context.updateMe(getMe.me);
        result = await configService.getHoldingConfig(this.state.holding);
      }
      if (result) {
        this.context.updateConfig(result.config);
      }
      this.context.updateIsSuperUser(isSuperAdmin);
      localStorage.setItem(storageItems.IS_LOGGED_IN, true);
      localStorage.setItem(storageItems.IS_SUPER_ADMIN, isSuperAdmin);
      localStorage.setItem(storageItems.HOLDING, this.state.holding);
      return this.props.history.push('/');
    } catch (err) {
      this.setState({ error: true, isLoading: false, errorMsg: (err.errors && err.errors[0] && err.errors[0].title) || translations.getLabel('lblGenericError') });
    }
  };

  onChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      error: false,
    });
  };

  isSuperAdmin = () => {
    const location = this.props.location;
    return location.pathname.endsWith('sa');
  }

  getImages = () => {
    if (this.isSuperAdmin()) {
      return [IMAGES.superadminBackground, IMAGES.superadminLogo];
    }
    return [IMAGES.adminBackground, IMAGES.adminLogo];
  }

  componentDidMount() {
    this.ref.focus();
  }

  setRef = ref => this.ref = ref;

  render() {
    const className = classnames({ error: this.state.error });
    const [background, logo] = this.getImages();

    return (
      <div className="login-view">
        <form className="form-container" onSubmit={this.onLoginUser}>
          <img src={logo} alt="Admin logo" className="admin-logo" />
          <h1>{translations.getLabel('titleLoginAdmin')}</h1>
          <p className="paragraph">{translations.getLabel('lblLogin')}</p>
          <input ref={this.setRef} type="text" name="username" autoComplete="username" placeholder={translations.getLabel('lblUsername')} id="username" onChange={this.onChange} className={className} />
          <input type="password" name="password" autoComplete="current-password" placeholder={translations.getLabel('lblPassword')} id="password" onChange={this.onChange} className={className} />
          {!this.isSuperAdmin() && <input type="text" name="holding" placeholder={translations.getLabel('lblHolding')} id="holding" onChange={this.onChange} className={className} />}
          {
            this.state.errorMsg &&
            <p className="error-text">{this.state.errorMsg}</p>
          }

          <div className="actions">
            <Button type="submit" isLoading={this.state.isLoading} disabled={!this.state.username || !this.state.password || this.state.error} onClick={this.onLoginUser}>{translations.getLabel('btnLogin')}</Button>
          </div>
        </form>
        <aside style={{ backgroundImage: `url(${background})` }} />
      </div>
    );
  }
}

LoginView.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

LoginView.contextType = ConfigContext;

export default LoginView;
