import React from 'react';
import { colors } from 'react-ess-components';
import Switch from 'react-switch';
import PropTypes from 'prop-types';

import { planningTypes } from '../../../../../../constants';
import { translations } from '../../../../../../utils';
import { checkFlag } from '../../../../../../utils/versionUtils';

export default function Planning({ planning, isEditing, setPlanning }) {
  const updatePlanning = (id) => {
    const newPlanning = planning.map(item => {
      if (item.type === id) {
        return { ...item, showEmployees: !item.showEmployees };
      }
      return item;
    });
    setPlanning(newPlanning);
  };

  const renderRow = (item) => {
    if (!checkFlag(planningTypes.find(type => type.key === item.type)?.version)) return null;

    return (
      <tr key={item.type}>
        <td>{translations.getLabel(planningTypes.find(type => type.key === item.type)?.label)}</td>
        {
          !isEditing ?
            <td>{item.showEmployees ? translations.getLabel('yes') : translations.getLabel('no')}</td>
            :
            <td> <Switch onChange={() => updatePlanning(item.type)} checked={item.showEmployees} onColor={colors.success} uncheckedIcon={false} checkedIcon={false} height={28} width={51} /></td>
        }
      </tr>
    );
  };

  return (
    <table className="table" >
      <thead>
        <tr>
          <th>{translations.getLabel('lblDescription')}</th>
          <th>{translations.getLabel('lblShowEmployees')}</th>
        </tr>
      </thead>
      <tbody>
        {planning.map(renderRow)}
      </tbody>
    </table>
  );
}

Planning.propTypes = {
  planning: PropTypes.array,
  isEditing: PropTypes.bool,
  setPlanning: PropTypes.func.isRequired,
};

Planning.defaultProps = {
  planning: [],
  isEditing: false,
};