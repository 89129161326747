import React from 'react';
import { Avatar, colors, Icon, IconButton } from 'react-ess-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './listitem.scss';

const ListItem = (props) => {
  const className = classNames('list-item-wrapper', { clickable: props.onClick });
  const classNameActions = classNames('list-item-actions', { close: props.icon === 'CrossIcon' });
  const classNameActionLabel = classNames('list-item-action-label', { clickable: props.onIconClick });
  const IconComponent = props.onIconClick ? IconButton : Icon;
  return (
    <div className={className} onClick={props.onClick}>
      <div className="list-item-main">
        {!!props.avatarProps && <Avatar {...props.avatarProps} className="list-item-avatar" />}
        <div className="list-item-content">
          <p className="list-item-title">{props.title}</p>
          {!!props.description && <p className="list-item-description">{props.description}</p>}
        </div>
        <div className={classNameActions}>
          {!!props.actionLabel && <span className={classNameActionLabel} onClick={props.onIconClick} style={{ color: colors[props.iconColor] }}>{props.actionLabel}</span>}<IconComponent tag={props.icon} color={props.iconColor} onClick={props.onIconClick} />
        </div>
      </div>
      {props.children}
    </div>
  );
};

ListItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  icon: PropTypes.string,
  iconColor: PropTypes.string,
  onIconClick: PropTypes.func,
  onClick: PropTypes.func,
  avatarProps: PropTypes.object,
  actionLabel: PropTypes.string,
  children: PropTypes.any,
};

ListItem.defaultProps = {
  description: '',
  icon: 'ChevronRightIcon',
  iconColor: 'primary',
  onIconClick: null,
  onClick: null,
  avatarProps: null,
  actionLabel: null,
  children: null,
};


export default ListItem;
