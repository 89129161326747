export const routes = {
  companies: '/company-admin/companies',
  holdings: '/admin/holding',
  holding: '/admin/holding/:id/*',
  holdingAdmin: '/holding-admin/*',
  companyAdmin: '/company-admin/:id/*',
  holdingAdminStartPage: '/holding-admin/branding',
  holdingAdminCompanies: '/holding-admin/companies',

  login: '/auth/login',
  loginSuperAdmin: '/auth/login/sa',
};
