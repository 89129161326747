import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { holdingAdminService } from '../../../../../../services';
import { translations } from '../../../../../../utils';
import VacationCountersEditItem from './VacationCountersEditItem';

let didCancel = false;

const VacationCounters = (props) => {
  const [isEditLoading, setIsEditLoading] = useState(false);

  useEffect(() => {
    didCancel = false;
    return () => {
      didCancel = true;
    };
  }, []);

  const onSave = async (vacationTypeId, counters) => {
    setIsEditLoading(true);
    await holdingAdminService.updateCountersPerVacationType(props.holdingId, props.companyId, vacationTypeId, { counters });
    if (!didCancel) {
      props.getData();
      setIsEditLoading(false);
    }
  };

  return (
    <div className="vacation-container">
      <VacationCountersEditItem
        vacationTypes={props.vacationTypes}
        counters={props.counters}
        isLoading={props.isLoading || isEditLoading}
        onSave={onSave}
        title={translations.getLabel('titleEditVacationCounters')}
        label={translations.getLabel('lblVacationCountersCompanyScheduleDescription')}
        emptyLabel={translations.getLabel('lblNoVacationCounters')}
        availableCounters={props.availableCounters}
      />
    </div>
  );
};

VacationCounters.propTypes = {
  vacationTypes: PropTypes.array.isRequired,
  companyId: PropTypes.string.isRequired,
  holdingId: PropTypes.string.isRequired,
  counters: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  availableCounters: PropTypes.array.isRequired,
  getData: PropTypes.func.isRequired,
};


export default VacationCounters;
