import React, { PureComponent } from 'react';
import { Button, Icon, IconButton, InputField, InputType,Modal } from 'react-ess-components';
import PropTypes from 'prop-types';

import { translations } from '../../../../../../utils';

import './TimeTypes.scss';

const CODES = [{ label: 'SCHOOL', value: 'SCHOOL' }, { label: 'AVAILABLE', value: 'AVAILABLE' }, { label: 'NOT_AVAILABLE', value: 'NOT_AVAILABLE' }, { label: 'NORMAL_WORKTIME', value: 'NORMAL_WORKTIME' }];

export default class TimeTypes extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isAdding: false,
      addOrEditTimeType: {},
      isRemoveLoading: false,
      removeIndex: '',
      isConfirmationModalOpen: false,
    };
  }

  renderRow = (timeType, i) => {
    return (
      <tr key={`type-${i}`}>
        <td>
          <IconButton color="primary" tag={timeType.visible ? 'CheckedIcon' : 'UncheckedIcon'} onClick={this.toggleVisible(i)} />
        </td>
        <td>{timeType.descriptionEn}</td>
        <td className="wcf-id">{timeType.wcfId}</td>
        <td>{timeType.code}</td>
        <td>{timeType.order}</td>
        <td className="color-cell"><div className="color" style={{ backgroundColor: timeType.color }} /></td>
        <td className="icon-cell">
          <IconButton tag="EditIcon" color="primary" onClick={this.setEdit(i)} />
        </td>
        <td className="icon-cell">
          <IconButton tag="TrashIcon" color="error" onClick={this.toggleConfirmationModal(i)} />
        </td>
      </tr>
    );
  }

  toggleVisible = (i) => () => {
    this.setState({ editIndex: i, addOrEditTimeType: { ...this.props.timeTypes[i], visible: !this.props.timeTypes[i].visible } }, () => this.onSave());
  }

  toggleAdd = (shouldReset) => {
    if (shouldReset) return this.setState({ isAdding: !this.state.isAdding, addOrEditTimeType: {}, editIndex: null });
    this.setState({ isAdding: !this.state.isAdding });
  }

  toggleConfirmationModal = (id) => () => this.setState({ isConfirmationModalOpen: !this.state.isConfirmationModalOpen, removeIndex: id });

  setEdit = (i) => () => {
    this.setState({ editIndex: i, addOrEditTimeType: { ...this.props.timeTypes[i] } }, () => this.toggleAdd(false));
  }

  onChange = (key) => (data) => {
    const addOrEditTimeType = { ...this.state.addOrEditTimeType };
    addOrEditTimeType[key] = data;

    this.setState({ addOrEditTimeType });
  }

  checkValidity = () => {
    const { descriptionEn, descriptionNl, descriptionDe, descriptionFr, wcfId, order, color } = this.state.addOrEditTimeType;
    const colorRegex = new RegExp('^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$');

    if (!descriptionEn || !descriptionNl || !descriptionFr || !descriptionDe) return false;
    if (!wcfId) return false;
    if (!order || isNaN(order)) return false;
    if (!color || !color.match(colorRegex)) return false;

    return true;
  }

  onSave = () => {
    const { code, color } = this.state.addOrEditTimeType;

    if (this.state.editIndex !== null) {
      const items = [...this.props.timeTypes];
      items[this.state.editIndex] = {
        ...this.state.addOrEditTimeType,
      };

      this.props.save(items);
    } else {
      this.props.save([...this.props.timeTypes, { ...this.state.addOrEditTimeType, visible: true, color: color || '#000000', code: code || CODES[0].value }]);
    }

    this.setState({ addOrEditTimeType: {}, isAdding: false });
  }

  onDelete = async () => {
    this.setState({ isRemoveLoading: true });
    await this.props.save(this.props.timeTypes.filter((_, index) => this.state.removeIndex !== index));
    this.setState({ isRemoveLoading: false, removeIndex: '', isConfirmationModalOpen: false });
  }

  render() {
    return (
      <div className="availabilty-types-container">
        <h4>{translations.getLabel('titleAvailabilityTypes')}</h4>
        <p className="label">{translations.getLabel('lblAvailabilityTypes')}</p>
        <table className="table" >
          <thead>
            <tr>
              <th />
              <th>{translations.getLabel('lblDescriptionEN')}</th>
              <th>{translations.getLabel('lblWFMID')}</th>
              <th>{translations.getLabel('lblCode')}</th>
              <th>{translations.getLabel('lblNumber')}</th>
              <th>{translations.getLabel('lblColor')}</th>
              <th colSpan={2} />
            </tr>
          </thead>
          <tbody>
            {this.props.timeTypes.map(this.renderRow)}
          </tbody>
        </table>
        <div className="add-button">
          <Button theme="transparent" onClick={this.toggleAdd}>
            <Icon tag="PlusSquareIcon" color="primary" />
            <p>{translations.getLabel('lblAddAvailabilityType')}</p>
          </Button>
        </div>

        <Modal
          requestClose={this.toggleAdd}
          open={this.state.isAdding}
          rightButtonProps={{ label: translations.getLabel('save'), onClick: this.onSave, disabled: !this.checkValidity() }}
          leftButtonProps={{ label: translations.getLabel('cancel'), onClick: this.toggleAdd }}
          title={translations.getLabel('titleTimeType')}
        >
          <div className="form-container">
            <InputField autoFocus emptyIcon value={this.state.addOrEditTimeType.descriptionEn} type={InputType.Text} onChange={this.onChange('descriptionEn')} label={translations.getLabel('lblDescriptionEN')} />
            <InputField autoFocus emptyIcon value={this.state.addOrEditTimeType.descriptionNl} type={InputType.Text} onChange={this.onChange('descriptionNl')} label={translations.getLabel('lblDescriptionNL')} />
            <InputField autoFocus emptyIcon value={this.state.addOrEditTimeType.descriptionFr} type={InputType.Text} onChange={this.onChange('descriptionFr')} label={translations.getLabel('lblDescriptionFR')} />
            <InputField autoFocus emptyIcon value={this.state.addOrEditTimeType.descriptionDe} type={InputType.Text} onChange={this.onChange('descriptionDe')} label={translations.getLabel('lblDescriptionDE')} />
            <InputField emptyIcon value={this.state.addOrEditTimeType.wcfId} type={InputType.Text} onChange={this.onChange('wcfId')} label={translations.getLabel('lblWFMID')} />

            <InputField emptyIcon value={this.state.addOrEditTimeType.code || CODES[0].value} type={InputType.Select} onChange={this.onChange('code')} label={translations.getLabel('lblCode')} options={CODES} />

            <InputField emptyIcon value={this.state.addOrEditTimeType.order} type={InputType.Text} onChange={this.onChange('order')} label={translations.getLabel('lblNumber')} />
            <div className="edit-color">
              <InputField emptyIcon value={this.state.addOrEditTimeType.color || '#000000'} type={InputType.Text} onChange={this.onChange('color')} label={translations.getLabel('lblColor')} />
              <div className="color-input">
                <InputField emptyIcon value={this.state.addOrEditTimeType.color || '#000000'} type={InputType.Color} onChange={this.onChange('color')} />
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          requestClose={this.toggleConfirmationModal()}
          open={this.state.isConfirmationModalOpen}
          rightButtonProps={{ label: translations.getLabel('lblDelete'), onClick: this.onDelete, isLoading: this.state.isRemoveLoading }}
          leftButtonProps={{ label: translations.getLabel('cancel'), onClick: this.toggleConfirmationModal() }}
          title={translations.getLabel('titleConfirmationDeleteTimeType')}
        >
          <p className="confirmation-message">{translations.getLabel('lblConfirmationDeleteTimeType')}</p>
        </Modal>
      </div>

    );
  }
}

TimeTypes.propTypes = {
  timeTypes: PropTypes.array.isRequired,
  save: PropTypes.func.isRequired,
};
