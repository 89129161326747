import React, { PureComponent } from 'react';
import { Icon } from 'react-ess-components';

import { storageItems } from '../../constants';
import { translations } from '../../utils';

import './OpenSiteButton.scss';

export default class OpenSiteButton extends PureComponent {
  getLink = () => {
    if (window.location.hostname.includes('localhost')) {
      return window.location.origin.replace('3001', '3000');
    }
    return `https://${window.location.hostname.replace('admin', `${localStorage.getItem(storageItems.HOLDING)}`)}`;
  }

  render() {
    return (
      <a className="link-open-site" href={this.getLink()} target="_blank" rel="noopener noreferrer">
        <Icon tag="LinkToAppIcon" />
        <p>{translations.getLabel('lblOpenInNewTab')}</p>
      </a>
    );
  }
}
