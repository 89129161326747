import React, { useEffect,useState } from 'react';
import { Button, Icon, SelectPopup } from 'react-ess-components';
import PropTypes from 'prop-types';

import { useToggle } from '../../../../../../hooks';
import { translations } from '../../../../../../utils';

export default function OverviewCounters({ counters, isEditing, allCounters, saveCounters }) {
  const [isAdding, toggleAdding] = useToggle(false);
  const [selectedCounters, setSelectedCounters] = useState(counters.length > 0 ? [counters.map(c => c?.id)] : []);

  useEffect(() => {
    counters.length > 0 && setSelectedCounters(counters.map(c => c?.id));
  }, [isAdding]); //eslint-disable-line react-hooks/exhaustive-deps

  const renderRow = (item) => {
    return (
      <tr key={item?.id}>
        <td>{item?.description}</td>
      </tr>
    );
  };

  const onChangeCounters = (selection) => {
    setSelectedCounters(selection);
  };

  const handleSave = () => {
    saveCounters(allCounters.filter(counter => selectedCounters.find(selected => selected === counter?.id)));
    toggleAdding();
  };

  return (
    <div>
      {
        counters.length > 0 &&
        <table className="table" >
          <thead>
            <tr>
              <th>{translations.getLabel('lblExtraCounters')}</th>
            </tr>
          </thead>
          <tbody>
            {counters.map(renderRow)}
          </tbody>
        </table>
      }

      {
        isEditing
        &&
        <Button theme="transparent" onClick={toggleAdding}>
          <Icon tag="PlusSquareIcon" color="primary" />
          <p>{translations.getLabel('lblAddExtraCounters')}</p>
        </Button>
      }

      <SelectPopup
        data={allCounters?.map(counter => ({ value: counter.id, label: counter.description }))}
        popupOpen={isAdding}
        isMultiple
        isModal
        selected={selectedCounters}
        title={translations.getLabel('lblAddExtraCounters')}
        requestClose={toggleAdding}
        onValueSelected={onChangeCounters}
        onSave={handleSave}
        acceptLabel={translations.getLabel('save')}
        cancelLabel={translations.getLabel('cancel')}
        isSearchEnabled={false}
        isAllowedEmpty
      />
    </div>
  );
}

OverviewCounters.propTypes = {
  counters: PropTypes.array,
  allCounters: PropTypes.array,
  isEditing: PropTypes.bool,
  saveCounters: PropTypes.func.isRequired,
};

OverviewCounters.defaultProps = {
  counters: [],
  allCounters: [],
  isEditing: false,
};