import React, { PureComponent } from 'react';
import { Redirect,Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { storageItems } from '../constants';

class UnauthorizedRoute extends PureComponent {
  renderRoute = () => {
    const RouteComponent = this.props.component;
    return localStorage.getItem(storageItems.IS_LOGGED_IN) !== 'true' ? <RouteComponent {...this.props} /> : <Redirect to={'/'} />;
  };

  render() {
    const { component, ...otherProps } = this.props;

    return (
      <Route
        {...otherProps}
        render={this.renderRoute}
      />
    );
  }
}

UnauthorizedRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

export default UnauthorizedRoute;
