import React, { PureComponent } from 'react';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

import { ListItem } from '../../components';
import ConfigContext from '../../context/ConfigContext';
import { companyAdminService } from '../../services';
import { translations } from '../../utils';

export default class Companies extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      isLoadingCompanies: false,
    };
  }

  componentDidMount() {
    this.getCompanies();
  }

  getCompanies = async () => {
    this.setState({ isLoadingCompanies: true });
    const companies = await companyAdminService.getCompanies();
    if (companies) {
      this.context.updateCompanies(companies);
      this.setState({ companies, isLoadingCompanies: false });
    }
    else this.setState({ isLoadingCompanies: false });
  }

  goToDetailsPage = (companyId) => () => {
    this.props.history.push(`/company-admin/${companyId}/company-managers`);
  }

  renderSkeleton = (key) => <div key={key} className="loading-wrapper"><Skeleton width="100%" height="10.5rem" /></div>;

  renderCompany = (company) => {
    return (<ListItem key={company.id} avatarProps={{ isCompany: !company.logoUrl, source: company.logoUrl }} title={company.name} description={company.id} onClick={this.goToDetailsPage(company.id)} />);
  }

  render() {
    return (
      <div className="page fixed-width">
        <div className="header">
          <h2>{translations.getLabel('titleCompanies')}</h2>
        </div>
        <section className="app-content">
          {
            this.state.isLoadingCompanies
              ?
              [1, 2, 3].map(this.renderSkeleton)
              :
              <div>
                {this.state.companies.map(this.renderCompany)}
              </div>
          }
        </section>
      </div>
    );
  }
}

Companies.contextType = ConfigContext;

Companies.propTypes = {
  history: PropTypes.object.isRequired,
};
