import { whitelist } from '../constants/whitelist';

export const parseRoute = route => {
  if (route.indexOf('?') > -1) {
    return route.split('?')[0];
  }
  return route;
};

export const isWhitelisted = route => {
  return !!whitelist.find((entry) => route.includes(entry));
};

export const cancelPreviousRequestIfneeded = (route, tokensByRoutes, cancelToken) => {
  const parsedRoute = parseRoute(route);

  if (isWhitelisted(parsedRoute)) {
    tokensByRoutes.push({ route: parsedRoute, cancelToken });
  } else {
    const foundIndex = tokensByRoutes.findIndex(tokenByRoute => tokenByRoute.route === parsedRoute);

    if (foundIndex < 0) {
      tokensByRoutes.push({ route: parsedRoute, cancelToken });
    } else {
      tokensByRoutes[foundIndex].cancelToken.cancel(CANCEL_MESSAGE);
      tokensByRoutes[foundIndex] = { route: parsedRoute, cancelToken };
    }
  }

  return tokensByRoutes;
};

export const CANCEL_MESSAGE = 'CALL_CANCELLED';
