import React, { Fragment, useEffect, useState } from 'react';
import { Modal } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { superAdminService } from '../../../../../../services';
import { translations } from '../../../../../../utils';
import { EditItem } from '../../../../..';
import SelfRosterModal from './SelfRosterModal';

import '../CountersConfigurationTab.scss';

function SelfRosterConfiguration({ holdingId }) {
  const [selfRosterProfile, setSelfRosterProfile] = useState({});
  const [editingSelfRoster, setEditingSelfRoster] = useState({});
  const [editingSelfRosterId, setEditingSelfRosterId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const [removeId, setRemoveId] = useState('');
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    let selfRosterCounters = await superAdminService.getHoldingSelfRosterCounters(holdingId);

    setSelfRosterProfile(selfRosterCounters);
    setIsLoading(false);
  };

  const handleSave = async (selfRoster, id = '') => {
    setIsLoading(true);

    const selfRosterBody = {
      normCounterOne: selfRoster.normCounterOne || '',
      normCounterTwo: selfRoster.normCounterTwo || '',
      normCounterThree: selfRoster.normCounterThree || '',
    };

    if (selfRosterProfile) {
      await superAdminService.updateHoldingSelfRosterCounter(holdingId, id, selfRosterBody);
    }
    else {
      await superAdminService.postHoldingSelfRosterCounter(holdingId, id, selfRosterBody);
    }

    getData();
  };

  const toggleModal = (selfRoster = {}, selfRosterId = '') => () => {
    setIsModalOpen(!isModalOpen);
    setEditingSelfRoster(selfRoster);
    setEditingSelfRosterId(selfRosterId);
  };

  const deleteSelfRosterCounter = async () => {
    setIsRemoveLoading(true);
    await superAdminService.deleteHoldingSelfRosterCounters(holdingId, removeId);
    setSelfRosterProfile({});
    setIsConfirmationModalOpen(false);
    setIsRemoveLoading(false);
    getData();
  };

  const toggleConfirmationModal = (id) => () => {
    setIsConfirmationModalOpen(!isConfirmationModalOpen);
    setRemoveId(id);
  };

  return (
    <>
      <div className="item-container">
        <EditItem
          title={translations.getLabel('titleSelfRosteringCounters')}
          label={translations.getLabel('lblHoldingSelfRosterCounterConfiguration')}
          onClick={toggleModal(selfRosterProfile)}
          noBorder
          hasRemove={!!selfRosterProfile?.normCounterOne && !!selfRosterProfile?.normCounterTwo && !!selfRosterProfile?.normCounterThree}
          onClickRemove={toggleConfirmationModal()}
        />
        {!isLoading ? <div>
          {(selfRosterProfile?.normCounterOne && selfRosterProfile?.normCounterTwo && selfRosterProfile?.normCounterThree) ?
            <Fragment>
              <table className="table">
                <thead>
                  <tr>
                    <th>{translations.getLabel('lblCounter')}</th>
                    <th>{translations.getLabel('lblWFMID')}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{translations.getLabel('lblStandardHours1')}</td>
                    <td>{selfRosterProfile.normCounterOne}</td>
                  </tr>
                  <tr>
                    <td>{translations.getLabel('lblStandardHours2')}</td>
                    <td>{selfRosterProfile.normCounterTwo}</td>
                  </tr>
                  <tr>
                    <td>{translations.getLabel('lblStandardHours3')}</td>
                    <td>{selfRosterProfile.normCounterThree}</td>
                  </tr>
                </tbody>
              </table>
            </Fragment>
            : <p className="not-configured">{translations.getLabel('lblNoCountersConfigured')}</p>
          }
        </div> : <Skeleton width="100%" height={150} />}

        {isModalOpen && <SelfRosterModal editingSelfRosterCounter={editingSelfRoster} editingSelfRosterCounterId={editingSelfRosterId} handleSave={handleSave} isLoading={isLoading} isModalOpen={isModalOpen} toggleModal={toggleModal()} />}

        <Modal
          requestClose={toggleConfirmationModal()}
          open={isConfirmationModalOpen}
          rightButtonProps={{ label: translations.getLabel('lblDelete'), onClick: deleteSelfRosterCounter, isLoading: isRemoveLoading }}
          leftButtonProps={{ label: translations.getLabel('cancel'), onClick: toggleConfirmationModal() }}
          title={translations.getLabel('titleConfirmationDeleteSelfRosterCounter')}
        >
          <p className="confirmation-message">{translations.getLabel('lblConfirmationDeleteSelfRosterCounters')}</p>
        </Modal>
      </div>
    </>
  );
}

SelfRosterConfiguration.propTypes = {
  holdingId: PropTypes.string.isRequired,
};

export default withRouter(SelfRosterConfiguration);
