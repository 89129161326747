/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { Button, Checkbox, Icon, IconButton, InputField, InputType } from 'react-ess-components';
import PropTypes from 'prop-types';

import { rights as RIGHTS } from '../../../../../constants';
import { translations } from '../../../../../utils';

const ALL_DEPARTMENTS = { value: 'ALL', label: translations.getLabel('lblAllDepartments') };

const EditCompanyAdmin = props => {
  const { admin, departments, onSave, onCancel } = props;

  const [rights, setRights] = useState(props.admin.departmentRights);
  const [isLoading, setIsLoading] = useState(false);

  const getDepartments = (currentSelected) => {
    const existingDepartments = rights.map(right => right.departmentId);
    // Map to correct format, and filter the departments that are already available
    return [
      ALL_DEPARTMENTS,
      ...props.departments.map(department => ({ value: department.id, label: department.name })),
    ].filter(department => department.value === currentSelected || !existingDepartments.includes(department.value));
  };

  const addDepartment = () => {
    const availableDepartments = getDepartments();
    setRights([...rights, { departmentId: availableDepartments[0].value, departmentName: availableDepartments[0].label, actions: [] }]);
  };

  const removeDepartment = (i) => () => {
    const _rights = [...rights];
    _rights.splice(i, 1);
    setRights(_rights);
  };

  const checkRight = (index, right) => () => {
    let _rights = [...rights];
    // Fill an initial right, if we had added a new admin
    if (!rights.length && index === 0) _rights = [{ actions: [] }];

    const actions = _rights[index].actions.includes(right) ? _rights[index].actions.filter(_right => _right !== right) : [..._rights[index].actions, right];
    _rights[index] = { ..._rights[index], actions };
    setRights(_rights);
  };

  const onChangeDepartment = (index) => (departmentId) => {
    let _rights = [...rights];
    // Fill an initial right, if we had added a new admin
    if (rights.length === 0 && index === 0) _rights = [{ actions: [] }];
    _rights[index] = {
      ..._rights[index],
      departmentId,
      departmentName: departmentId === ALL_DEPARTMENTS.value ? ALL_DEPARTMENTS : departments.find(department => departmentId === department.id).name,
    };
    setRights(_rights);
  };

  const setTeam = (team, index) => {
    let _rights = [...rights];
    // Fill an initial right, if we had added a new admin
    if (rights.length === 0 && index === 0) _rights = [{ actions: [] }];
    _rights[index] = {
      ..._rights[index],
    };
    // default empty to null
    _rights[index].team = team || null;
    setRights(_rights);
  };

  const handleSave = async () => {
    setIsLoading(true);
    const result = await onSave(admin.userId, rights.map(right => ({ departmentId: right.departmentId, actions: right.actions, team: right.team })));
    if (!result) setIsLoading(false);
  };

  const renderDepartment = (department, i) => {
    return (
      <div className="admin-rights-department" key={department.departmentId}>
        <InputField type={InputType.Select} placeholder={translations.getLabel('lblPlaceholderSelectDepartment')} value={department.departmentId} emptyIcon options={getDepartments(department.departmentId)} onChange={onChangeDepartment(i)} />
        <div className="department-rights-options">
          {
            department.departmentId !== ALL_DEPARTMENTS.value &&
            <div className="team-input">
              <InputField type={InputType.Text} placeholder={translations.getLabel('lblPlaceholderSelectTeam')} value={department.team} emptyIcon name={i} onChange={setTeam} />
            </div>
          }
          {Object.values(RIGHTS).map(right => (
            <Checkbox key={right.value} onCheck={checkRight(i, right.value)} isChecked={department.actions.includes(right.value)} label={translations.getLabel(right.label)} />)
          )}
        </div>
        <IconButton tag="CrossIcon" color="error" onClick={removeDepartment(i)} />
      </div>
    );
  };

  return (
    <div className="admin-rights-edit">
      {rights.length === 0 ? renderDepartment({ actions: [] }, 0) : rights.map(renderDepartment)}
      {(departments.length > 0 && rights.length <= departments.length) && <Button theme="transparent" onClick={addDepartment}><Icon tag="PlusSquareIcon" color="primary" /> {translations.getLabel('btnAddDepartment')}</Button>}

      <div className="footer">
        <Button theme="inverse" onClick={onCancel(admin.userId)}>{translations.getLabel('cancel')}</Button>
        <Button disabled={!departments.length} isLoading={isLoading} onClick={handleSave}>{translations.getLabel('save')}</Button>
      </div>
    </div>
  );
};

EditCompanyAdmin.propTypes = {
  admin: PropTypes.object.isRequired,
  departments: PropTypes.array.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default EditCompanyAdmin;
