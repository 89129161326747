import React from 'react';
import PropTypes from 'prop-types';

import { companyAdminService } from '../../../../../services';
import { translations } from '../../../../../utils';
import PeriodEditItem from './PeriodEditItem';

const AdjustViewingPeriod = (props) => {
  const onSave = async (period) => {
    const newSchedulePeriod = period.type === 'SCHEDULE_VISIBILITY_PERIOD' ? { ...period } : props.periods.filter(c => c.type === 'SCHEDULE_VISIBILITY_PERIOD')?.[0];
    const newPeriods = [];
    if (newSchedulePeriod) {
      delete newSchedulePeriod.updatedAt;
      newPeriods.push(newSchedulePeriod);
    }

    await companyAdminService.updatePeriods(props.companyId, newPeriods);
    props.onSaved();
  };

  const schedulePeriod = props.periods?.filter(c => c.type === 'SCHEDULE_VISIBILITY_PERIOD')?.[0];

  return (
    <PeriodEditItem
      hideEndDate={props.hideEndDate}
      showTodayFrom={props.showTodayFrom}
      showTodayUntil={props.showTodayUntil}
      period={{ endDate: schedulePeriod?.endDate }}
      isLoading={props.isLoading}
      isBeforeEnabled={false}
      onSave={(period) => onSave({ ...period, type: 'SCHEDULE_VISIBILITY_PERIOD' })}
      title={translations.getLabel('titleSchedulePeriod')}
      modalTitle={translations.getLabel('titleScheduleModal')}
      label={translations.getLabel('lblSchedulePeriod')}
      modalLabel={translations.getLabel('lblScheduleModal')}
      noFromPeriod
      emptyLabel={translations.getLabel('lblNotConfigured')}
    />
  );
};

AdjustViewingPeriod.propTypes = {
  hideEndDate: PropTypes.bool,
  showTodayFrom: PropTypes.bool,
  showTodayUntil: PropTypes.bool,
  companyId: PropTypes.string.isRequired,
  holdingId: PropTypes.string.isRequired,
  periods: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  onSaved: PropTypes.func.isRequired,
};

AdjustViewingPeriod.defaultProps = {
  periods: null,
};

export default AdjustViewingPeriod;
