import React, { PureComponent } from 'react';
import { InputField, InputType,Modal, SelectPopup } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { timeZones } from '../../../../../constants';
import { languages } from '../../../../../constants/languages';
import ConfigContext from '../../../../../context/ConfigContext';
import { configService, superAdminService } from '../../../../../services';
import { translations } from '../../../../../utils';
import EditItem from '../../../../editItem/EditItem';
import Credentials from './credentials/Credentials';

import './GeneralConfigurationTab.scss';

class GeneralConfigurationTab extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isEditingUrl: false,
      isEditingWFM: false,
      isEditingLanguage: false,
      holdingUrl: null,
      WFM: null,
      language: null,
      isEditingTimeZone: false,
      timeZone: null,
    };
  }

  toggleHoldingModal = () => {
    this.setState({ isEditingUrl: !this.state.isEditingUrl, holdingUrl: null });
  }

  toggleWFMModal = () => {
    this.setState({ isEditingWFM: !this.state.isEditingWFM, WFM: null });
  }

  toggleLanguageModal = () => {
    this.setState({ isEditingLanguage: !this.state.isEditingLanguage, language: null });
  }

  toggleTimeZoneModal = () => {
    this.setState({ isEditingTimeZone: !this.state.isEditingTimeZone, timeZone: null });
  }

  handleSave = async () => {
    const saveResult = await configService.setURL(this.props.holdingId, this.state.holdingUrl);
    if (saveResult) {
      toast(translations.getLabel('lblGenericSuccessMessage'));
      const result = await superAdminService.getHoldings();
      if (result?.data) this.context.updateHoldings(result.data);
      this.setState({ isEditingUrl: !this.state.isEditingUrl, holdingUrl: null, url: null });
    }
  }

  handleSaveWFM = async () => {
    let config = {};
    config.url = this.state.WFM || this.context.config.wcf.url;
    config.defaultLanguage = this.state.language || this.context.config.wcf.defaultLanguage;
    config.timezone = this.state.timeZone || this.context.config.wcf.timezone;

    Object.keys(config).forEach((key) => (!config[key]) && delete config[key]);

    const result = await configService.updateConfig(this.props.holdingId, { wcf: config });

    if (result) {
      this.context.updateConfig(result.config);
      toast(translations.getLabel('lblGenericSuccessMessage'));
    }
    this.setState({ isEditingWFM: false, isEditingLanguage: false, isEditingTimeZone: false, holdingUrl: null, url: null, language: null, timeZone: null });
  }

  handleSaveCredentials = async (credentials) => {
    const result = await configService.updateConfig(this.props.holdingId, { wcf: { credentials } });
    if (result) this.context.updateConfig(result.config);
  }

  onChangeHoldingUrl = (holdingUrl) => this.setState({ holdingUrl });
  onChangeWFM = (WFM) => this.setState({ WFM })
  onChangeLanguage = (language) => this.setState({ language })
  onChangeTimeZone = (timeZone) => this.setState({ timeZone })

  getLanguages = () => languages.reduce((accu, language) => ([...accu, { ...language, label: translations.getLabel(language.label) }]), [])

  getLanguage = () => {
    const current = (this.context.config.wcf && this.context.config.wcf.defaultLanguage);
    const language = languages.find(lang => lang.value === current);

    if (language) return translations.getLabel(language.label);
    return null;
  }

  getUrl = () => {
    const holding = this.context.holdings.find(holding => holding.id === this.props.holdingId);
    if (holding) return holding.url;
    return null;
  }

  render() {
    if (!this.context.config || !this.context.config.wcf) {
      return (
        <div className="configuration-container">
          <Skeleton />
        </div>
      );
    }

    return (
      <div className="configuration-container">
        <EditItem label={`${this.getUrl()}${process.env.REACT_APP_DOMAIN}`} title={translations.getLabel('titleHoldingURL')} onClick={this.toggleHoldingModal} />
        <EditItem label={this.context.config.wcf?.url || ''} title={translations.getLabel('titleWFMURL')} onClick={this.toggleWFMModal} />

        <Credentials
          getLanguages={this.getLanguages}
          credentials={this.context.config.wcf.credentials}
          save={this.handleSaveCredentials}
        />

        <EditItem label={translations.getLabel('lblChangeLanguage')} title={translations.getLabel('titleLanguage')} onClick={this.toggleLanguageModal} >
          <p>{this.getLanguage()}</p>
        </EditItem>
        <EditItem label={translations.getLabel('lblTimeZone')} title={translations.getLabel('titleTimeZone')} onClick={this.toggleTimeZoneModal} >
          <p>{(this.context.config.wcf && this.context.config.wcf.timezone) || ''}</p>
        </EditItem>

        <Modal
          requestClose={this.toggleHoldingModal}
          open={this.state.isEditingUrl}
          rightButtonProps={{ label: translations.getLabel('save'), onClick: this.handleSave, disabled: !this.state.holdingUrl }}
          leftButtonProps={{ label: translations.getLabel('cancel'), onClick: this.toggleHoldingModal }}
          title={translations.getLabel('titleHoldingURL')}
        >
          <div className="row">
            <InputField autoFocus type={InputType.Text} emptyIcon onChange={this.onChangeHoldingUrl} label={translations.getLabel('lblHoldingURL')} value={this.state.holdingUrl || this.state.holdingUrl === '' ? this.state.holdingUrl : this.getUrl()} />
            <p>{process.env.REACT_APP_DOMAIN}</p>
          </div>
        </Modal>

        <Modal
          requestClose={this.toggleWFMModal}
          open={this.state.isEditingWFM}
          rightButtonProps={{ label: translations.getLabel('save'), onClick: this.handleSaveWFM, disabled: !this.state.WFM }}
          leftButtonProps={{ label: translations.getLabel('cancel'), onClick: this.toggleWFMModal }}
          title={translations.getLabel('titleWFMURL')}
        >
          <div className="row wfm-modal-content">
            <InputField autoFocus type={InputType.Text} emptyIcon onChange={this.onChangeWFM} label={translations.getLabel('lblWFMURL')} value={this.state.WFM || this.state.WFM === '' ? this.state.WFM : this.context.config.wcf.url} />
          </div>
        </Modal>

        <SelectPopup
          data={this.getLanguages()}
          popupOpen={this.state.isEditingLanguage}
          isSearchEnabled={false}
          isMultiple={false}
          isModal={true}
          selected={this.state.language || this.context.config.wcf.defaultLanguage}
          title={translations.getLabel('lblSelectLanguage')}
          requestClose={this.toggleLanguageModal}
          onValueSelected={this.onChangeLanguage}
          onSave={this.handleSaveWFM}
          acceptLabel={translations.getLabel('save')}
          cancelLabel={translations.getLabel('cancel')}
        />
        <SelectPopup
          data={timeZones}
          popupOpen={this.state.isEditingTimeZone}
          isSearchEnabled={false}
          isMultiple={false}
          isModal={true}
          selected={this.state.timeZone || this.context.config.wcf.timezone}
          title={translations.getLabel('titleTimeZone')}
          requestClose={this.toggleTimeZoneModal}
          onValueSelected={this.onChangeTimeZone}
          onSave={this.handleSaveWFM}
          acceptLabel={translations.getLabel('save')}
          cancelLabel={translations.getLabel('cancel')}
        />
      </div>
    );
  }
}

GeneralConfigurationTab.propTypes = {
  holdingId: PropTypes.string.isRequired,
};

GeneralConfigurationTab.contextType = ConfigContext;

export default GeneralConfigurationTab;
