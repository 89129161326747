import React, { useContext, useEffect, useState } from 'react';
import { Button, Icon, IconButton, InputField, InputType, Modal } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

import { planningTypes } from '../../../../../../constants';
import ConfigContext from '../../../../../../context/ConfigContext';
import { useToggle } from '../../../../../../hooks';
import { holdingAdminService } from '../../../../../../services';
import { translations } from '../../../../../../utils';
import { checkFlag } from '../../../../../../utils/versionUtils';
import OverviewCounters from '../overviewCounters/OverviewCounters';
import Planning from '../planning/Planning';

import './CountersConfiguration.scss';

const counterType = 'COUNTER';
const addId = 'add';

let didCancel = false;

export default function CountersConfiguration(props) {
  const context = useContext(ConfigContext);

  const [isInitialLoading, setInitialLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [initialCounterOverviews, setInitialCounterOverviews] = useState([]);
  const [counterOverviews, setCounterOverviews] = useState([]);
  const [editId, setEditId] = useState('');
  const [isAdding, toggleAdding] = useToggle(false);
  const [isDeleting, toggleDelete] = useToggle(false);

  useEffect(() => {
    didCancel = false;
    initData();

    return () => {
      didCancel = true;
    };
  }, [props.companyId, props.holdingId]);//eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isAdding) {
      setEditId(addId);
      const overviews = [...counterOverviews];
      overviews.push({ name: '', id: addId, planning: planningTypes.filter(type => checkFlag(type.version)).map(type => ({ type: type.key, showEmployees: true })) });
      setCounterOverviews(overviews);
    } else {
      const index = counterOverviews.findIndex(o => o.id === addId);
      setEditId('');

      if (index >= 0) {
        const overviews = [...counterOverviews];
        overviews.splice(index, 1);
        setCounterOverviews(overviews);
      }
    }
  }, [isAdding]);//eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!editId) {
      setCounterOverviews(initialCounterOverviews);
    }
  }, [editId]);//eslint-disable-line react-hooks/exhaustive-deps

  const initData = async () => {
    let allCounters = [];
    const counterOverviews = await holdingAdminService.getCounterOverview(props.holdingId, props.companyId);

    const enrich = (overviews) => {
      const getOriginalCounters = (counters) => counters.reduce((original, counter) => {
        return [...original, allCounters?.find(all => all.id === counter.counterId)];
      }, []);

      return overviews.reduce((accu, overview) => {
        return [...accu, { ...overview, counters: getOriginalCounters(overview.counters) }];
      }, []);
    };

    if (context.allCounters?.length) {
      allCounters = [...context.allCounters];
    } else {
      allCounters = await holdingAdminService.getAllCounters(props.holdingId);
      context.updateAllCounters(allCounters);
    }

    if (counterOverviews && !didCancel && allCounters) {
      const enrichedOverviews = enrich(counterOverviews);
      setCounterOverviews(enrichedOverviews);
      setInitialCounterOverviews(enrichedOverviews);
    }
    if (!didCancel) setInitialLoading(false);
  };

  const setPlanning = (updatedPlanning) => {
    const updatedOverviews = counterOverviews.reduce((accu, overview) => {
      if (overview.id !== editId) {
        return [...accu, overview];
      }
      return [...accu, { ...overview, planning: updatedPlanning }];
    }, []);
    setCounterOverviews(updatedOverviews);
  };

  const addOverview = async () => {
    setIsLoading(true);
    const overview = counterOverviews.find(overview => overview.id === addId);
    const counters = [];
    if (overview.counters) {
      overview.counters.map(counter => (
        counters.push({ type: counterType, showEmployees: true, counterId: counter.id })
      ));
    }

    await holdingAdminService.postCounterOverview(props.holdingId, props.companyId, { name: overview.name, counters, planning: overview.planning });
    if (!didCancel) {
      initData();
      setIsLoading(false);
      toggleAdding();
    }
  };

  const addCounters = (counters) => {
    const updatedOverviews = counterOverviews.reduce((accu, overview) => {
      if (overview.id !== editId) {
        return [...accu, overview];
      }
      return [...accu, { ...overview, counters }];
    }, []);

    setCounterOverviews(updatedOverviews);
  };

  const onChangeTitle = (name) => {
    const updatedOverviews = counterOverviews.reduce((accu, overview) => {
      if (overview.id !== editId) {
        return [...accu, overview];
      }
      return [...accu, { ...overview, name }];
    }, []);
    setCounterOverviews(updatedOverviews);
  };

  const deleteOverview = async () => {
    setIsLoading(true);
    await holdingAdminService.deleteCounterOverview(props.holdingId, props.companyId, editId);
    if (!didCancel) {
      setIsLoading(false);
      initData();
      setEditId('');
      toggleDelete();
    };
  };

  const updateOverview = async () => {
    setIsLoading(true);
    const overview = counterOverviews.find(overview => overview.id === editId);
    // Format to correct values to send to BE
    const counters = overview.counters.map(counter => ({ type: counterType, showEmployees: true, counterId: counter.id }));
    const planning = overview.planning.map(planning => ({ type: planning.type, showEmployees: planning.showEmployees }));

    await holdingAdminService.updateCounterOverview(props.holdingId, props.companyId, editId, { name: overview.name, counters, planning });
    if (!didCancel) {
      initData();
      setIsLoading(false);
      setEditId('');
    }
  };

  const renderOverview = (group) => {
    const isEditing = editId === group.id;

    return (
      <div className="counter-overview" key={group.id}>
        <div className="header">
          {!isEditing ? <h5>{group.name}</h5> : <InputField type={InputType.Text} emptyIcon value={group.name} placeholder={translations.getLabel('lblName')} onChange={onChangeTitle} />}
          {
            !isEditing ?
              <IconButton tag="EditIcon" color={!!editId ? 'primaryLight' : 'primary'} onClick={() => setEditId(group.id)} disabled={!!editId} />
              :
              <IconButton tag="TrashIcon" color="error" onClick={editId === addId ? toggleAdding : toggleDelete} />
          }
        </div>

        <Planning planning={group.planning} isEditing={isEditing} setPlanning={setPlanning} />
        <OverviewCounters counters={group.counters} isEditing={isEditing} allCounters={context.allCounters} saveCounters={addCounters} />

        {
          isEditing &&
          <div className="footer">
            <Button onClick={editId === addId ? toggleAdding : () => setEditId('')} theme="inverse">{translations.getLabel('cancel')}</Button>
            <Button disabled={!group.name} isLoading={isLoading} onClick={editId === addId ? addOverview : updateOverview}>{translations.getLabel('save')}</Button>
          </div >
        }
      </div >
    );
  };

  return (
    <div className="counter-overview-container">
      <h4 className="title">{translations.getLabel('lblCounterOverview')}</h4>
      <p className="sub-title">{translations.getLabel('lblConfigureCounterOverview')}</p>
      {
        !isInitialLoading ?
          counterOverviews.map(renderOverview)
          :
          <Skeleton width="100%" height="20rem" />
      }
      {
        !isAdding && !isInitialLoading &&
        <Button theme="transparent" onClick={toggleAdding}>
          <Icon tag="PlusSquareIcon" color="primary" />
          <p>{translations.getLabel('btnAddCounterConfiguration')}</p>
        </Button>
      }
      <Modal
        open={isDeleting}
        title={translations.getLabel('lblRemoveCounterOverview')}
        requestClose={toggleDelete}
        leftButtonProps={{
          label: translations.getLabel('cancel'),
          onClick: toggleDelete,
        }}
        rightButtonProps={{
          label: translations.getLabel('lblDelete'),
          isLoading: isLoading,
          onClick: deleteOverview,
        }}
      >
        <div className="warning-message">{translations.getLabel('lblConfirmationDeleteCounterOverview')}</div>
      </Modal>
    </div>
  );
}

CountersConfiguration.propTypes = {
  companyId: PropTypes.string.isRequired,
  holdingId: PropTypes.string.isRequired,
};
