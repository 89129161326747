/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

import ConfigContext from '../../../../../context/ConfigContext';
import { useToggle } from '../../../../../hooks';
import { companyAdminService } from '../../../../../services';
import { translations } from '../../../../../utils';
import EditItem from '../../../../editItem/EditItem';
import Switch from '../../../../switch/Switch';
import PeriodEditItem from './PeriodEditItem';

const ShiftTime = (props) => {
    const context = useContext(ConfigContext);
    const [booleanModalOpen, setBooleanModalOpen] = useToggle();
    const [isShiftTimeEnabled, setEnableShiftTime] = useState(false);
    const [isInitialLoading, setInitialLoading] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (Object.keys(context.companyConfig).length > 0) {
            setEnableShiftTime(context.companyConfig.enableShiftTimeAdjustments);
            setInitialLoading(false);
        }
    }, [props.companyId, context.companyConfig]);

    const onSavePeriod = async (period) => {
        const newShiftTimePeriod = period.type === 'SHIFT_TIME_ADJUSTMENTS_REQUEST_PERIOD' ? { ...period } : props.periods.filter(c => c.type === 'SHIFT_TIME_ADJUSTMENTS_REQUEST_PERIOD')?.[0];
        const newPeriods = [];
        if (newShiftTimePeriod) {
            delete newShiftTimePeriod.updatedAt;
            newPeriods.push(newShiftTimePeriod);
        }

        await companyAdminService.updatePeriods(props.companyId, newPeriods);
        props.onSaved();
    };

    const onSaveBoolean = async () => {
        setIsLoading(true);
        const result = await companyAdminService.updateCompanyConfig(props.companyId, { enableShiftTimeAdjustments: isShiftTimeEnabled });
        if (result) {
            context.updateCompanyConfig(result.config);
            setBooleanModalOpen();
        }
        setIsLoading(false);
    };

    const cancelModal = () => {
        setEnableShiftTime(context.companyConfig.enableShiftTimeAdjustments);
        setBooleanModalOpen();
    };

    const shiftTimePeriod = props.periods?.filter(c => c.type === 'SHIFT_TIME_ADJUSTMENTS_REQUEST_PERIOD')?.[0];

    return (
        <>
            <EditItem noBorder={context.companyConfig.enableShiftTimeAdjustments} title={translations.getLabel('titleAdjustedShiftTimes')} label={translations.getLabel('lblAdjustedShiftTimesBoolean')} onClick={setBooleanModalOpen}>
                <p className="weekday-value">{isInitialLoading ? <Skeleton width={150} /> : translations.getLabel(context.companyConfig.enableShiftTimeAdjustments ? 'yes' : 'no')}</p>
            </EditItem>
            {context.companyConfig.enableShiftTimeAdjustments &&
                <PeriodEditItem
                showTodayFrom={props.showTodayFrom}
                showTodayUntil={props.showTodayUntil}
                    hideEndDate={props.hideEndDate}
                    maxDate={new Date()}
                    isBeforeEnabled={false}
                    period={{ startDate: shiftTimePeriod?.startDate}}
                    isLoading={props.isLoading}
                    onSave={(period) => onSavePeriod({ ...period, type: 'SHIFT_TIME_ADJUSTMENTS_REQUEST_PERIOD' })}
                    modalTitle={translations.getLabel('titleAdjustedShiftTimes')}
                    label={translations.getLabel('lblShiftTimePeriodDescription')}
                    emptyLabel={translations.getLabel('lblNotConfigured')}
                />
            }
            <Modal
                title={translations.getLabel('titleAdjustedShiftTimes')}
                open={booleanModalOpen}
                requestClose={cancelModal}
                rightButtonProps={{ label: translations.getLabel('save'), onClick: onSaveBoolean, isLoading: isLoading }}
                leftButtonProps={{ label: translations.getLabel('cancel'), onClick: cancelModal }}
            >
                <div className="popup-schedule-config-container">
                    <p>{translations.getLabel('lblAdjustedShiftTimesBoolean')}</p>
                    <Switch isChecked={isShiftTimeEnabled} handleChange={(value) => setEnableShiftTime(value)} leftLabel={translations.getLabel('no')} rightLabel={translations.getLabel('yes')} />
                </div>
            </Modal>
        </>
    );
};

ShiftTime.propTypes = {
    showTodayUntil: PropTypes.bool,
    showTodayFrom: PropTypes.bool,
    hideEndDate: PropTypes.bool,
    companyId: PropTypes.string.isRequired,
    holdingId: PropTypes.string.isRequired,
    periods: PropTypes.array,
    isLoading: PropTypes.bool.isRequired,
    onSaved: PropTypes.func.isRequired,
};

ShiftTime.defaultProps = {
    periods: null,
};

export default ShiftTime;
