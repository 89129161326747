import React, { useContext,useEffect, useState } from 'react';
import { Button, Icon, IconButton, InputField, InputType,Modal } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { descriptionForLanguage, vacationTypeTypes } from '../../../../../constants';
import ConfigContext from '../../../../../context/ConfigContext';
import { useForm,useToggle } from '../../../../../hooks';
import { superAdminService } from '../../../../../services';
import { translations } from '../../../../../utils';

import './vacationTypes.scss';

let didCancel = false;

function initialFormFromTask(vacationType) {
  if (vacationType) {
    return {
      id: vacationType.id,
      externalId: vacationType.externalId,
      type: vacationType.type,
      descriptionNl: vacationType.descriptionNl,
      descriptionFr: vacationType.descriptionFr,
      descriptionEn: vacationType.descriptionEn,
      descriptionDe: vacationType.descriptionDe,
    };
  }
  return {
    externalId: '',
    type: '',
    descriptionNl: '',
    descriptionFr: '',
    descriptionEn: '',
    descriptionDe: '',
  };
}

const isFormValid = (vacationType) => {
  return !!vacationType.descriptionEn
    && !!vacationType.descriptionNl
    && !!vacationType.descriptionDe
    && !!vacationType.descriptionFr
    && !!vacationType.externalId
    && !!vacationType.type;
};

const VacationTypes = ({ match }) => {
  const context = useContext(ConfigContext);
  const [isLoading, setIsLoading] = useState(false);
  const [vacationTypes, setVacationTypes] = useState([]);
  const [isEditModalOpen, toggleEditModal] = useToggle(false);
  const [isConfirmodalOpen, toggleConfirmModal] = useToggle(false);
  const [isSaveLoading, setSaveLoading] = useState(false);
  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const { form, setForm, setFormAttribute } = useForm(initialFormFromTask());

  const getVacationTypes = async () => {
    didCancel = false;
    const result = await superAdminService.getVacationTypes(match.params.id);
    if (!didCancel) {
      if (result) {
        setVacationTypes(result);
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getVacationTypes();
    return () => {
      didCancel = true;
    };
  }, [match.params.id]); //eslint-disable-line react-hooks/exhaustive-deps

  const openEditModal = (vacationType) => {
    const initialValue = initialFormFromTask(vacationType);
    setForm(initialValue);
    toggleEditModal();
  };

  const saveVacationType = async () => {
    setSaveLoading(true);
    const { id, ...vacationType } = form;
    let result;
    didCancel = false;
    if (id) {
      result = await superAdminService.updateVacationType(match.params.id, id, vacationType);
    } else {
      result = await superAdminService.addVacationType(match.params.id, vacationType);
    }
    await getVacationTypes();
    if (!didCancel) {
      setSaveLoading(false);
      if (result) {
        toggleEditModal();
      }
    }
  };

  const deleteVacationType = async () => {
    setDeleteLoading(true);
    didCancel = false;
    const result = await superAdminService.deleteVacationType(match.params.id, deleteId);
    await getVacationTypes();

    if (!didCancel) {
      if (result) {
        toast(translations.getLabel('lblVacationTypeRemoved'));
      }

      setDeleteLoading(false);
      setDeleteId(null);
      toggleConfirmModal();
    }
  };

  const openDeleteModal = (id) => {
    setDeleteId(id);
    toggleConfirmModal();
  };

  const renderRow = (vacationType) => {
    const descriptionKey = descriptionForLanguage[context?.config?.wcf?.defaultLanguage || 'en_EN'];
    return (<tr key={vacationType.id}>
      <td>{vacationType[descriptionKey]}</td>
      <td>{vacationType.externalId}</td>
      <td>{vacationType.type}</td>
      <td className="icon-cell"><IconButton tag="EditIcon" color="primary" onClick={() => openEditModal(vacationType)} /></td>
      <td className="icon-cell"><IconButton tag="TrashIcon" color="error" onClick={() => openDeleteModal(vacationType.id)} /></td>
    </tr>);
  };

  return (
    <div className="vacation-types-container">
      <h4>{translations.getLabel('titleVacationTypesSchedule')}</h4>

      <p>{translations.getLabel('lblVacationTypesScheduleDescription')}</p>
      {isLoading && <Skeleton height={30} />}
      {vacationTypes.length > 0 &&
        <table className="table">
          <thead>
            <tr>
              <th>{translations.getLabel('lblVacationTypeDescription', { language: context?.config?.wcf?.defaultLanguage?.substr(0, 2)?.toUpperCase() || 'EN' })}</th>
              <th>{translations.getLabel('lblWFMID')}</th>
              <th>{translations.getLabel('lblType')}</th>
              <th colSpan={2} />
            </tr>
          </thead>
          <tbody>
            {vacationTypes.map(renderRow)}
          </tbody>
        </table>}

      <div className="add-button">
        <Button theme="transparent" onClick={() => openEditModal()}>
          <Icon tag="PlusSquareIcon" color="primary" />
          <p>{translations.getLabel('btnAddVacationType')}</p>
        </Button>
      </div>

      {isEditModalOpen && <Modal
        requestClose={() => openEditModal()}
        open={isEditModalOpen}
        rightButtonProps={{ label: translations.getLabel('save'), onClick: saveVacationType, isLoading: isSaveLoading, disabled: !isFormValid(form) }}
        leftButtonProps={{ label: translations.getLabel('cancel'), onClick: () => openEditModal() }}
        title={true ? translations.getLabel('titleEditVacationType') : translations.getLabel('titleNewVacationType')}
      >
        <InputField autoFocus name="descriptionEn" type={InputType.Text} emptyIcon onChange={setFormAttribute} label={translations.getLabel('lblVacationTypeDescription', { language: 'EN' })} value={form.descriptionEn} />
        <InputField name="descriptionNl" type={InputType.Text} emptyIcon onChange={setFormAttribute} label={translations.getLabel('lblVacationTypeDescription', { language: 'NL' })} value={form.descriptionNl} />
        <InputField name="descriptionFr" type={InputType.Text} emptyIcon onChange={setFormAttribute} label={translations.getLabel('lblVacationTypeDescription', { language: 'FR' })} value={form.descriptionFr} />
        <InputField name="descriptionDe" type={InputType.Text} emptyIcon onChange={setFormAttribute} label={translations.getLabel('lblVacationTypeDescription', { language: 'DE' })} value={form.descriptionDe} />
        <InputField name="externalId" type={InputType.Text} emptyIcon onChange={setFormAttribute} label={translations.getLabel('lblWFMID')} value={form.externalId} />
        <InputField
          name="type"
          type={InputType.Select}
          emptyIcon
          onChange={setFormAttribute}
          placeholder={translations.getLabel('lblPlaceholderVacationTypeType')}
          options={vacationTypeTypes.map(type => ({ ...type, label: translations.getLabel(type.label) }))}
          label={translations.getLabel('lblType')}
          value={form.type} />
      </Modal>}

      <Modal
        requestClose={toggleConfirmModal}
        open={isConfirmodalOpen}
        rightButtonProps={{ label: translations.getLabel('lblDelete'), onClick: deleteVacationType, isLoading: isDeleteLoading }}
        leftButtonProps={{ label: translations.getLabel('cancel'), onClick: toggleConfirmModal }}
        title={translations.getLabel('titleConfirmationDeleteVacationType')}
      >
        <p className="confirmation-message">{translations.getLabel('lblConfirmationDeleteVacationType')}</p>
      </Modal>
    </div>
  );
};

VacationTypes.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(VacationTypes);
