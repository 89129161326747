import React, { PureComponent } from 'react';
import { Button, InputField, InputType,Modal } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

import { ListItem } from '../../components';
import { routes } from '../../constants';
import ConfigContext from '../../context/ConfigContext';
import { superAdminService } from '../../services';
import { translations } from '../../utils';

import './holdings.scss';

class Holdings extends PureComponent {
  constructor(props, context) {
    super(props, context);
    this.amountToLoad = 15;
    this.loadCount = 1;
    this.scrollOffset = 150;
    this.totalCount = 0;

    this.state = {
      isLoading: context.holdings.length <= 0,
      creatingHolding: false,
      holdingName: '',
      holdingUrl: '',
      isAdding: false,
    };
  }

  async componentDidMount() {
    const result = await superAdminService.getHoldings(this.amountToLoad, 0);
    if (result && result.data) {
      this.context.updateHoldings(result.data);
      this.totalCount = result.totalCount;
    }
    window.addEventListener('scroll', this.onScroll);
    this.setState({ isLoading: false });
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  onScroll = async (ignoreOffset) => {
    if ((window.scrollY + window.innerHeight >= document.documentElement.scrollHeight - (ignoreOffset ? 0 : this.scrollOffset)) && !this.state.isLoading && this.context.holdings.length < this.totalCount) {
      const result = await superAdminService.getHoldings(this.amountToLoad, this.amountToLoad * this.loadCount);
      if (result && result.data) {
        this.context.updateHoldings([...this.context.holdings, ...result.data]);
        this.loadCount++;
      }
    }
  }


  gotoHolding = (id) => () => {
    this.props.history.push(`${routes.holdings}/${id}/configuration`);
  }

  renderHolding = holding => {
    const [firstName, lastName] = holding.name.split(' ');
    return <ListItem
      key={holding.id}
      onClick={this.gotoHolding(holding.id)}
      avatarProps={{ firstName, lastName, source: holding.logoUrl }}
      title={holding.name}
      description={holding.id}
    />;
  }

  renderLoading = key => {
    return <div key={key} className="loading-wrapper"><Skeleton width="100%" height="10.5rem" /></div>;
  }

  toggleCreateHoldingModal = () => {
    if (!this.state.creatingHolding) {
      this.setState({ creatingHolding: !this.state.creatingHolding, holdingName: '', holdingUrl: '' });
    } else {
      this.setState({ creatingHolding: !this.state.creatingHolding });
    }
  }

  handleHoldingUrl = (holdingUrl) => this.setState({ holdingUrl })

  handleHoldingName = (holdingName) => this.setState({ holdingName })

  addHolding = async () => {
    this.setState({ isAdding: true });
    const result = await superAdminService.addHolding(this.state.holdingName, this.state.holdingUrl);
    if (result) {
      const holdings = await superAdminService.getHoldings();
      if (!!holdings?.data) {
        this.context.updateHoldings(holdings.data);
        this.totalCount = holdings.totalCount;
      }
      this.toggleCreateHoldingModal();
    }
    this.setState({ isAdding: false });
  }

  render() {
    return (
      <div className="page fixed-width app-holdings">
        <div className="header">
          <h2>{translations.getLabel('titleHoldings')}</h2>
        </div>
        <section className="app-content holdings-container">
          <div className="holdings-controls">
            <Button onClick={this.toggleCreateHoldingModal}>{translations.getLabel('lblAddNewHolding')}</Button>
          </div>
          <div>
            {this.state.isLoading
              ? [0, 1, 2].map(this.renderLoading)
              : this.context.holdings.map(this.renderHolding)
            }
          </div>
        </section>
        <Modal
          open={this.state.creatingHolding}
          title={translations.getLabel('lblAddNewHolding')}
          requestClose={this.toggleCreateHoldingModal}
          leftButtonProps={{
            label: translations.getLabel('cancel'),
            onClick: this.toggleCreateHoldingModal,
          }}
          rightButtonProps={{
            label: translations.getLabel('lblAdd'),
            onClick: this.addHolding,
            disabled: !this.state.holdingName || !this.state.holdingUrl,
            isLoading: this.state.isAdding,
          }}
        >
          <div className="warning-message">
            <InputField autoFocus emptyIcon label={translations.getLabel('lblHoldingName')} onChange={this.handleHoldingName} value={this.state.holdingName} type={InputType.Text} />
            <InputField emptyIcon label={translations.getLabel('lblHoldingUrl')} onChange={this.handleHoldingUrl} value={this.state.holdingUrl} type={InputType.Text} />
          </div>
        </Modal>
      </div>
    );
  }
}

Holdings.contextType = ConfigContext;

Holdings.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Holdings;
