import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';

import { dateUtils, translations } from '../../../../../utils';
import { EditItem } from '../../../..';
import AdjustPeriodModal from './AdjustPeriodModal';

import './periodEditItem.scss';

const PeriodEditItem = (props) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [period, setPeriod] = useState(props.period);

  useEffect(() => {
    if (!props.isLoading) setPeriod(props.period);
  }, [props.period, props.isLoading]);

  const openEditModal = () => setIsEditOpen(true);

  const closeEditModal = () => {
    setPeriod(props.period);
    setIsEditOpen(false);
  };

  const renderDate = (date) => {
    if (!date) {
      return (<td className="italic">{props.emptyLabel}</td>);
    }
    else if (!date.includes('/')) {
      return (<td>
        {format(parseISO(date), 'dd-MM-yyyy')}
      </td>
      );
    }
    else if (date.includes('/')) {
      return (
        <td>
          {dateUtils.convertRelativePeriodToLabel(date)}
        </td>
      );
    };
  };

  const renderRow = (period) => (
    <tr>
      {period?.startDate ?
        renderDate(period?.startDate)
        : props.showTodayFrom ?
          <td>{translations.getLabel('lblToday')}</td>
          : <td className="italic">{props.emptyLabel}</td>}
      {period?.endDate ?
        renderDate(period?.endDate)
        : props.showTodayUntil ?
          <td>{translations.getLabel('lblToday')}</td>
          :
          <td className={'italic'}>{props.emptyLabel}</td>}
      {props.showDaysInAdvance && <td className="days-advance">{(period && period.daysBefore) || 0}</td>}
    </tr>
  );

  const getPeriod = () => {
    let newPeriod = { fromDate: period?.startDate || new Date().toISOString(), toDate: period?.endDate || new Date().toISOString(), daysBefore: period?.daysBefore };
    if (!period?.endDate && props.hideEndDate) {
      newPeriod = { fromDate: period?.startDate || new Date().toISOString(), daysBefore: period?.daysBefore };
    }
    if (props.noFromPeriod) {
      newPeriod = { toDate: period?.endDate || new Date().toISOString(), daysBefore: period?.daysBefore };
    }
    return newPeriod;
  };

  const onSave = (data) => {
    setIsEditOpen(false);
    props.onSave({ ...period, startDate: data.fromDate, endDate: data.toDate, daysBefore: data.daysBefore });
  };

  return (
    <div>
      <EditItem
        title={props.title}
        label={props.label}
        onClick={openEditModal}
      >
        <table className="table period-edit-item" >
          <thead>
            <tr>
              <th>{translations.getLabel('lblFromInput')}</th>
              <th>{translations.getLabel('lblUntilInput')}</th>
              {props.showDaysInAdvance && <th>{translations.getLabel('lblDaysInAdvance')}</th>}
            </tr>
          </thead>
          <tbody>
            {
              props.isLoading
                ?
                <tr>
                  <td><Skeleton width="100%" height="2rem" /></td>
                  <td><Skeleton width="100%" height="2rem" /></td>
                  <td className="days-advance"><Skeleton width="100%" height="2rem" /></td>
                </tr>
                :
                renderRow(props.period)
            }
          </tbody>
        </table>
      </EditItem>

      <AdjustPeriodModal
        maxDate={props.maxDate}
        isBeforeEnabled={props.isBeforeEnabled}
        period={getPeriod()}
        isOpen={isEditOpen}
        closeModal={closeEditModal}
        title={props.modalTitle}
        label={props.modalLabel}
        handleSave={onSave}
      />
    </div>
  );
};

PeriodEditItem.propTypes = {
  hideEndDate: PropTypes.bool,
  showTodayFrom: PropTypes.bool,
  showTodayUntil: PropTypes.bool,
  period: PropTypes.object,
  isLoading: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  noFromPeriod: PropTypes.bool,
  title: PropTypes.string,
  modalTitle: PropTypes.string,
  modalLabel: PropTypes.string,
  label: PropTypes.string.isRequired,
  emptyLabel: PropTypes.string.isRequired,
  showDaysInAdvance: PropTypes.bool,
  isBeforeEnabled: PropTypes.bool,
  maxDate: PropTypes.instanceOf(Date),
};

PeriodEditItem.defaultProps = {
  title: '',
  period: null,
  noFromPeriod: false,
  modalTitle: null,
  modalLabel: null,
  isBeforeEnabled: true,
  maxDate: null,
  showDaysInAdvance: false,
};

export default PeriodEditItem;
