import React from 'react';
import { Redirect,Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';

import { Navbar } from '../components';
import { routes } from '../constants/routes';
import { Companies,CompanyAdminConfiguration, HoldingAdminConfiguration, Holdings, SuperAdminConfiguration } from '../scenes';

class AuthorizedLayout extends React.PureComponent {
  getRoute = () => {
    if (this.props.isSuperUser) return routes.holdings;
    if (this.props.isHoldingAdmin) return routes.holdingAdminStartPage;
    return routes.companies;
  }

  render() {
    return (
      <div className="auth-layout">
        <Navbar />
        <div className="app-wrapper">
          <Switch>
            <Route path={routes.companies} exact component={Companies} />
            <Route path={routes.holdings} exact component={Holdings} />
            <Route path={routes.holding} exact component={SuperAdminConfiguration} />
            <Route path={routes.holdingAdmin} exact component={HoldingAdminConfiguration} />
            <Route path={routes.companyAdmin} exact component={CompanyAdminConfiguration} />
            <Redirect to={this.getRoute()} />
          </Switch>
        </div>
      </div>
    );
  }
}

AuthorizedLayout.propTypes = {
  isSuperUser: PropTypes.bool.isRequired,
  isHoldingAdmin: PropTypes.bool.isRequired,
};

export default AuthorizedLayout;
