export const rights = {
  // These rights are not yet functional in V1
  VACATION_REQUESTS: { value: 'VACATION_REQUESTS', label: 'lblRightsVacationRequests' },
  // SHIFT_SWAPS: { value: 'SHIFT_SWAPS', label: 'lblRightsShiftSwaps' },
  // OPEN_SHIFTS: { value: 'OPEN_SHIFTS', label: 'lblRightsOpenShifts' }, */
  NEWS: { value: 'NEWS', label: 'lblRightsNews' },
  DOCUMENTS: { value: 'DOCUMENTS', label: 'lblRightsDocuments' },
  CHAT: { value: 'CHAT', label: 'lblRightsChat' },
  AVAILABILITIES: { value: 'AVAILABILITIES', label: 'lblAvailabilitiesRight' },
  TIME_REGISTRATION: { value: 'TIME_REGISTRATION', label: 'lblTimeRegistrationRight' },
  SELF_ROSTER: { value: 'SELF_ROSTER', label: 'lblSelfRosteringRight' },
  SHIFT_OFFER: { value: 'SHIFT_OFFER', label: 'lblShiftOfferRight' },
  SHIFT_SWAP: { value: 'SHIFT_SWAP', label: 'lblShiftSwapRight' },
  TRANSACTIONS: { value: 'TRANSACTIONS', label: 'lblTransactionRight' },
  SHIFT_TIME_ADJUSTMENT_REQUESTS: { value: 'SHIFT_TIME_ADJUSTMENT_REQUESTS', label: 'lblShiftTimeAdjustmentRight' },
  /*
  **** This will probably not be used at all
  REQUESTS: { value: 'REQUESTS', label: 'lblRightsRequests' },
   */
};
