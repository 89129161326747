import React, { useState } from 'react';
import { InputField, InputType,Modal } from 'react-ess-components';
import PropTypes from 'prop-types';

import { useForm } from '../../../../../../hooks';
import { translations } from '../../../../../../utils';

import '../CountersConfigurationTab.scss';

function SelfRosterModal({ editingSelfRosterCounter, editingSelfRosterCounterId, handleSave, isModalOpen, toggleModal }) {
  const { form, setFormAttribute, clearForm } = useForm(editingSelfRosterCounter);
  const [isLoading, setIsLoading] = useState(false);

  const handleButtonDisable = () => {
    if (!form.normCounterOne || !form.normCounterTwo || !form.normCounterThree) {
      return true;
    }
  };

  // const handleModalInput = (key) => (value) => setFormAttribute(value, key);
  const handleModalInput = (value, name) => setFormAttribute(value, name);

  const handleSaveModal = async () => {
    setIsLoading(true);

    await handleSave(form, editingSelfRosterCounterId);

    setIsLoading(false);
    toggleModal();
  };

  const toggleSelfRosterCounterModal = () => () => {
    toggleModal();
    clearForm();
  };

  return (
    <Modal
      requestClose={toggleSelfRosterCounterModal()}
      open={isModalOpen}
      rightButtonProps={{ label: translations.getLabel('save'), onClick: handleSaveModal, isLoading, disabled: handleButtonDisable() }}
      leftButtonProps={{ label: translations.getLabel('cancel'), onClick: toggleSelfRosterCounterModal() }}
      title={translations.getLabel('titleSelfRosterCounters')}
    >
      <div className="form">
        <div className="form-column">
          <div className="input-container">
            <p className="input-container">{translations.getLabel('lblWFMIDStandardHours1')}</p>
            <InputField type={InputType.Text} emptyIcon onChange={(value) => handleModalInput(value, 'normCounterOne')} value={form.normCounterOne} />
          </div>
          <div className="input-container">
            <p className="input-container">{translations.getLabel('lblWFMIDStandardHours2')}</p>
            <InputField type={InputType.Text} emptyIcon onChange={(value) => handleModalInput(value, 'normCounterTwo')} value={form.normCounterTwo} />
          </div>
          <div className="input-container">
            <p className="input-container">{translations.getLabel('lblWFMIDStandardHours3')}</p>
            <InputField type={InputType.Text} emptyIcon onChange={(value) => handleModalInput(value, 'normCounterThree')} value={form.normCounterThree} />
          </div>
        </div>
      </div>
    </Modal>
  );
}

SelfRosterModal.propTypes = {
  editingSelfRosterCounter: PropTypes.object,
  editingSelfRosterCounterId: PropTypes.string,
  handleSave: PropTypes.func,
  isModalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

const noop = () => { };

SelfRosterModal.defaultProps = {
  editingSelfRosterCounter: {},
  editingSelfRosterCounterId: '',
  handleSave: noop,
};

export default SelfRosterModal;
