import React, { useContext,useEffect } from 'react';
import PropTypes from 'prop-types';

import ConfigContext from '../../../../../context/ConfigContext';
import { holdingAdminService } from '../../../../../services';
import TransactionTypes from './TransactionTypes';

export default function TransactionsTab(props) {
    const context = useContext(ConfigContext);

    useEffect(() => {
        if (props.companyId) {
            const initConfig = async () => {
                const result = await holdingAdminService.getCompanyConfig(props.companyId);
                if (result) {
                    context.updateCompanyConfig(result.config);
                }
            };
            initConfig();
        }
    }, [props.companyId]);  //eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className="counters-tab vacation-types-container">
            <TransactionTypes holdingId={props.holdingId} companyId={props.companyId} />
        </div>
    );
}

TransactionsTab.propTypes = {
    companyId: PropTypes.string.isRequired,
    holdingId: PropTypes.string.isRequired,
};
