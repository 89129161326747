import { Network } from '../utils';

export const getCompanies = async (holdingId) => {
  const result = await Network.get(`/v1/management/holdings/${holdingId}/companies`);
  return result && result.data;
};

export const getDashboardCounters = async (holdingId, companyId) => {
  const result = await Network.get(`/v1/management/holdings/${holdingId}/companies/${companyId}/counters/dashboard`);
  return result && result.data;
};

export const updateDashboardCounters = async (holdingId, companyId, body) => {
  const result = await Network.put(`/v1/management/holdings/${holdingId}/companies/${companyId}/counters/dashboard`, body);
  return result && result.data;
};

export const updateVacationCounters = async (holdingId, companyId, body) => {
  const result = await Network.put(`/v1/management/holdings/${holdingId}/companies/${companyId}/counters/vacation-counters`, body);
  return result && result.data;
};

export const updateCounterGroup = async (holdingId, companyId, groupId, body) => {
  const result = await Network.patch(`/v1/management/holdings/${holdingId}/companies/${companyId}/counters/groups/${groupId}`, body);
  return result && result.data;
};

export const addCounterGroup = async (holdingId, companyId, body) => {
  const result = await Network.post(`/v1/management/holdings/${holdingId}/companies/${companyId}/counters/groups`, body);
  return result && result.data;
};

export const deleteCounterGroup = async (holdingId, companyId, groupId) => {
  const result = await Network.delete(`/v1/management/holdings/${holdingId}/companies/${companyId}/counters/groups/${groupId}`);
  return result && result.data;
};

export const getAllCounters = async (holdingId) => {
  const result = await Network.get(`/v1/management/holdings/${holdingId}/counters?withValues=true`);
  return result && result.data;
};

export const getAvailableCounters = async (holdingId) => {
  const result = await Network.get(`/v1/management/holdings/${holdingId}/available-counters`);
  return result && result.data;
};

export const getVacationCounters = async (holdingId, companyId) => {
  const result = await Network.get(`/v1/management/holdings/${holdingId}/companies/${companyId}/counters/vacation-counters`);
  return result && result.data;
};

export const getCounterGroups = async (holdingId, companyId) => {
  const result = await Network.get(`/v1/management/holdings/${holdingId}/companies/${companyId}/counters/groups`);
  return result && result.data;
};

export const getCompanyAdmins = async (holdingId) => {
  const result = await Network.get(`/v1/management/holdings/${holdingId}/company-admins`);
  return result && result.data;
};

export const getHoldingPreferences = async (holdingId) => {
  const result = await Network.get(`/v1/management/holdings/${holdingId}/preferences`);
  return result.data;
};

export const getTermsAndConditions = async () => {
  const result = await Network.get('/v1/management/holdings/terms-and-conditions');
  return result && result.data;
};

export const removeTermsAndConditions = async () => {
  const result = await Network.delete('/v1/management/holdings/terms-and-conditions');
  return result;
};

export const getPresignedUrlTAndA = async () => {
  const result = await Network.post('/v1/management/holdings/terms-and-conditions');
  return result && result.data && result.data.uploadUrl;
};

export const getUsersOfCompany = async (holdingId, companyId, search = '') => {
  const result = await Network.get(`/v1/management/holdings/${holdingId}/companies/${companyId}/users`, { search });
  return result && result.data;
};

export const saveCompanyAdmins = async (holdingId, companyId, admins) => {
  await Network.put(`/v1/management/holdings/${holdingId}/company-admins?companyId=${companyId}`, { admins });
};

export const getPresignedUrl = async (holdingId, type) => {
  const result = await Network.patch(`/v1/management/holdings/${holdingId}/brandable-images`, { column: type });
  return result && result.data && result.data.uploadUrl;
};

export const deleteImage = async (holdingId, type) => {
  const result = await Network.delete(`/v1/management/holdings/${holdingId}/brandable-images`, { column: type });
  return result;
};

export const updateHoldingPreferences = async (holdingId, config) => {
  const result = await Network.patch(`/v1/management/holdings/${holdingId}/preferences`, config);
  return result && result.data;
};

export const getVacationTypes = async (holdingId, companyId) => {
  const result = await Network.get(`/v1/management/holdings/${holdingId}/companies/${companyId}/vacation-types`);
  return result && result.data;
};

export const getTransactionTypes = async (holdingId, companyId) => {
  const result = await Network.get(`/v1/management/holdings/${holdingId}/companies/${companyId}/transaction-types`);
  
  return result && result.data;
};

export const getCompanyConfig = async (companyId) => {
  const result = await Network.get(`/v1/company/${companyId}/config`);
  return result && result.data;
};

export const updateCompanyConfig = async (holdingId, companyId, config) => {
  const result = await Network.patch(`/v1/management/holdings/${holdingId}/companies/${companyId}/config`, config);
  return result && result.data;
};

export const getAllVacationTypes = async (holdingId) => {
  const result = await Network.get(`/v1/management/holdings/${holdingId}/vacation-types`);
  return result && result.data;
};

export const getAllTransactionTypes = async (holdingId) => {
  const result = await Network.get(`/v1/management/holdings/${holdingId}/transaction-types`);
  return result && result.data;
};

export const updateVacationTypes = async (holdingId, companyId, vacationTypes) => {
  const result = await Network.put(`/v1/management/holdings/${holdingId}/companies/${companyId}/vacation-types`, vacationTypes);
  return result && result.data;
};

export const updateTransactionTypes = async (holdingId, companyId, transactionTypes) => {
  const result = await Network.put(`/v1/management/holdings/${holdingId}/companies/${companyId}/transaction-types`, transactionTypes);
  return result && result.data;
};

export const getCounterOverview = async (holdingId, companyId) => {
  const result = await Network.get(`/v1/management/holdings/${holdingId}/companies/${companyId}/counter-overview`);
  return result && result.data;
};

export const postCounterOverview = async (holdingId, companyId, body) => {
  const result = await Network.post(`/v1/management/holdings/${holdingId}/companies/${companyId}/counter-overview`, body);
  return result && result.data;
};

export const updateCounterOverview = async (holdingId, companyId, id, body) => {
  const result = await Network.put(`/v1/management/holdings/${holdingId}/companies/${companyId}/counter-overview/${id}`, body);
  return result && result.data;
};

export const deleteCounterOverview = async (holdingId, companyId, overviewId) => {
  const result = await Network.delete(`/v1/management/holdings/${holdingId}/companies/${companyId}/counter-overview/${overviewId}`);
  return result && result.data;
};

export const getTimeRegistrationTypes = async (holdingId) => {
  const result = await Network.get(`/v1/management/holdings/${holdingId}/time-registration-types`);
  return result && result.data;
};

export const getTimeRegistrationProfiles = async (holdingId, companyId) => {
  const result = await Network.get(`/v1/management/holdings/${holdingId}/companies/${companyId}/time-registration/profiles`);
  return result && result.data;
};

export const addTimeRegistrationProfile = async (holdingId, companyId, body) => {
  const result = await Network.post(`/v1/management/holdings/${holdingId}/companies/${companyId}/time-registration/profiles`, body);
  return result && result.data;
};

export const updateTimeRegistrationProfile = async (holdingId, companyId, profileId, body) => {
  const result = await Network.put(`/v1/management/holdings/${holdingId}/companies/${companyId}/time-registration/profiles/${profileId}`, body);
  return result && result.data;
};

export const updateTimeRegistrationTypes = async (holdingId, companyId, profileId, body) => {
  const result = await Network.patch(`/v1/management/holdings/${holdingId}/companies/${companyId}/time-registration/profiles/${profileId}/types`, body);
  return result && result.data;
};

export const deleteTimeRegistrationProfile = async (holdingId, companyId, profileId) => {
  const result = await Network.delete(`/v1/management/holdings/${holdingId}/companies/${companyId}/time-registration/profiles/${profileId}`);
  return result && result.data;
};

export const getCounterVisibilities = async (holdingId, companyId) => {
  const result = await Network.get(`/v1/management/holdings/${holdingId}/companies/${companyId}/counter-visibilities`);
  return result && result.data;
};

export const updateCounterVisibilities = async (holdingId, companyId, type, body) => {
  const result = await Network.patch(`/v1/management/holdings/${holdingId}/companies/${companyId}/counter-visibilities/${type}`, body);
  return result && result.data;
};

export const updateCountersPerVacationType = async (holdingId, companyId, companyVacationTypeId, counters) => {
  const result = await Network.put(`/v1/management/holdings/${holdingId}/companies/${companyId}/vacation-types/${companyVacationTypeId}/counters`, counters);
  return result && result.data;
};

