import React, { useContext, useEffect, useState } from 'react';
import { InputField, InputType,Modal } from 'react-ess-components';
import PropTypes from 'prop-types';

import ConfigContext from '../../../../../context/ConfigContext';
import { configService } from '../../../../../services';
import { translations } from '../../../../../utils';
import EditItem from '../../../../editItem/EditItem';
import TimeTypes from './timeTypes/TimeTypes';

import './AvailabilitiesConfigurationTab.scss';

let didCancel = false;

const AvailabilitiesConfigurationTab = ({ holdingId }) => {
  const [isEdittingAvailabilityShiftId, setIsEdittingAvailabilityShiftId] = useState(false);
  const [isEdittingSchoolShiftId, setIsEdittingSchoolShiftId] = useState(false);
  const [availabilityShiftId, setAvailabilityShiftId] = useState('');
  const [schoolShiftId, setSchoolShiftId] = useState('');
  const context = useContext(ConfigContext);

  const config = context.config.wcf || {};
  const timeTypes = config.timeTypes || [];

  useEffect(() => {
    didCancel = false;

    return () => {
      didCancel = true;
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (context.config.wcf) {
      setAvailabilityShiftId(config.availabilityShiftId);
      setSchoolShiftId(config.schoolShiftId);
    }
  }, [config.availabilityShiftId, config.schoolShiftId, context.config]);

  const handleSaveShiftId = async () => {
    const result = await configService.updateConfig(holdingId, { wcf: { availabilityShiftId, schoolShiftId } });
    if (!didCancel) {
      if (result) context.updateConfig(result.config);

      setIsEdittingAvailabilityShiftId(false);
      setIsEdittingSchoolShiftId(false);
    }
  };

  const handleSaveTimeTypes = async (timeTypes) => {
    const result = await configService.updateConfig(holdingId, { wcf: { timeTypes } });
    if (result && !didCancel) return context.updateConfig(result.config);
    return null;
  };


  return (
    <div className="availabilities-container">
      <EditItem label={(context.config.wcf && context.config.wcf.availabilityShiftId) || ''} title={translations.getLabel('titleWFMID')} onClick={() => setIsEdittingAvailabilityShiftId(true)} />
      <EditItem label={(context.config.wcf?.schoolShiftId) || ''} title={translations.getLabel('titleWFMIDSchool')} onClick={() => setIsEdittingSchoolShiftId(true)} />
      <TimeTypes timeTypes={timeTypes} save={handleSaveTimeTypes} />
      <Modal
        requestClose={() => isEdittingAvailabilityShiftId ? setIsEdittingAvailabilityShiftId(false) : setIsEdittingSchoolShiftId(false)}
        open={isEdittingAvailabilityShiftId || isEdittingSchoolShiftId}
        rightButtonProps={{ label: translations.getLabel('save'), onClick: handleSaveShiftId, disabled: isEdittingAvailabilityShiftId ? !availabilityShiftId : !schoolShiftId }}
        leftButtonProps={{ label: translations.getLabel('cancel'), onClick: () => isEdittingAvailabilityShiftId ? setIsEdittingAvailabilityShiftId(false) : setIsEdittingSchoolShiftId(false) }}
        title={translations.getLabel(isEdittingAvailabilityShiftId ? 'titleWFMID' : 'titleWFMIDSchool')}
      >
        <div className="row wfm-id-content">
          <InputField autoFocus emptyIcon type={InputType.Text} onChange={isEdittingAvailabilityShiftId ? setAvailabilityShiftId : setSchoolShiftId} label={translations.getLabel('lblWFMShiftID')} value={isEdittingAvailabilityShiftId ? availabilityShiftId : schoolShiftId} />
        </div>
      </Modal>
    </div>
  );
};

AvailabilitiesConfigurationTab.propTypes = {
  holdingId: PropTypes.string.isRequired,
};

export default AvailabilitiesConfigurationTab;