import React, { FC } from 'react';

import { IDepartmentProfile, ISelfRosterProfile } from '../../../../../models';
import { translations } from '../../../../../utils';
import SelfRosterDepartmentItem from './SelfRosterDepartmentItem';

import './selfRosterProfileDepartment.scss';

type Props = {
  companyId: string,
  profiles: ISelfRosterProfile[],
  departmentProfiles: IDepartmentProfile[],
  getData: () => void,
}

const SelfRosterDepartment: FC<Props> = ({ companyId, profiles, departmentProfiles, getData }) => {
  const profileOptions = profiles?.map(profile => {
    return { value: profile.id.toString(), label: profile.name.toString() };
  });
  return (
    <div className="self-roster-profile-department">
      <h4>{translations.getLabel('titleSelfRosterDepartment')}</h4>
      {departmentProfiles?.length ? <div className="departments-table">
        {departmentProfiles?.map((profile: IDepartmentProfile) =>
          <SelfRosterDepartmentItem key={profile.departmentId} companyId={companyId} profile={profile} getData={getData} options={profileOptions} />
        )}
      </div> :
        <p>
          <i>{translations.getLabel('lblNoDepartments')}</i>
        </p>}
    </div>
  );
};

export default SelfRosterDepartment;
