import {
  HABackgroundTab,
  HAColorsTab,
  HACompaniesTab,
  HALogoTab,
  TermsAndConditionsTab,
} from '../components';
import { translations } from '../utils';

export const holdingAdminTabStructure = [
  {
    url: 'branding',
    label: translations.getLabel('titleHoldingBranding'),
    sideTabs: [
      {
        url: 'logo',
        label: translations.getLabel('lblLogo'),
        component: HALogoTab,
      },
      {
        url: 'colors',
        label: translations.getLabel('lblColors'),
        component: HAColorsTab,
      },
      {
        url: 'background',
        label: translations.getLabel('lblBackground'),
        component: HABackgroundTab,
      },
    ],
  },
  {
    url: 'configuration',
    label: translations.getLabel('titleHoldingConfiguration'),
    sideTabs: [
      {
        url: 'app-conditions',
        label: translations.getLabel('lblConditions'),
        component: TermsAndConditionsTab,
      },
    ],
  },
  {
    url: 'companies',
    label: translations.getLabel('titleCompanies'),
    component: HACompaniesTab,
  },
];
