import { Network } from '../utils';

export const getCompanies = async () => {
  const result = await Network.get('/v1/management/companies');
  if (result) return result.data;
};

export const getCompanyUsers = async (companyId, search = '', sort, order) => {
  let result = null;
  if (order) result = await Network.get(`/v1/management/companies/${companyId}/users`, { search, sortField: sort, sortOrder: order });
  else result = await Network.get(`/v1/management/companies/${companyId}/users`, {
    search: search || '', sortField: sort || 'lastName',
  });
  return result && result.data;
};

export const getCompanyAdmins = async (companyId) => {
  const result = await Network.get(`/v1/management/companies/${companyId}/department-managers`);
  return result && result.data;
};

export const postCompanyAdmin = async (companyId, userId) => {
  const result = await Network.post(`/v1/management/companies/${companyId}/department-managers`, { userId });
  return result && result.data;
};

export const getDepartments = async (companyId) => {
  const result = await Network.get(`/v1/management/companies/${companyId}/departments`);
  if (result) return result.data;
};

export const updateCompanyAdmin = async (companyId, userId, rights) => {
  const result = await Network.put(`/v1/management/companies/${companyId}/department-managers/${userId}`, { rights });
  return result;
};

export const removeCompanyAdmin = async (companyId, userId) => {
  await Network.delete(`/v1/management/companies/${companyId}/department-managers/${userId}`);
  return true;
};

export const getPresignedUrl = async (companyId, type) => {
  const result = await Network.patch(`/v1/management/companies/${companyId}/brandable-images`, { column: type });
  return result && result.data && result.data.uploadUrl;
};

export const deleteImage = async (companyId, type) => {
  const result = await Network.delete(`/v1/management/companies/${companyId}/brandable-images`, { column: type });
  return result;
};

export const getCompanyPreferences = async (companyId) => {
  const result = await Network.get(`/v1/management/companies/${companyId}/preferences`);
  if (result) return result.data;
};

export const patchCompanyPreferences = async (companyId, body) => {
  await Network.patch(`/v1/management/companies/${companyId}/preferences`, body);
};

export const updateCompanyConfig = async (companyId, body) => {
  const result = await Network.patch(`/v1/management/companies/${companyId}/config`, body);
  return result && result.data;
};

export const getLocations = async (companyId) => {
  const result = await Network.get(`/v1/management/companies/${companyId}/departments/locations`);
  if (result) return result.data;
};

export const postLocation = async (companyId, departmentId, location) => {
  const result = await Network.post(`/v1/management/companies/${companyId}/departments/${departmentId}/locations`, location);
  return result?.data;
};

export const updateLocation = async (companyId, departmentId, locationId, location) => {
  const result = await Network.patch(`/v1/management/companies/${companyId}/departments/${departmentId}/locations/${locationId}`, location);
  return result?.data;
};

export const deleteLocation = async (companyId, departmentId, locationId) => {
  const result = await Network.delete(`/v1/management/companies/${companyId}/departments/${departmentId}/locations/${locationId}`);
  return result;
};

export const getPeriods = async (companyId) => {
  const result = await Network.get(`/v1/management/companies/${companyId}/request-periods`);
  return result?.data;
};

export const updatePeriods = async (companyId, periods) => {
  const result = await Network.put(`/v1/management/companies/${companyId}/request-periods`, periods);
  return result?.data;
};

export const postSelfRosterProfile = async (companyId, profile) => {
  const result = await Network.post(`/v1/management/companies/${companyId}/self-roster-profiles`, profile);
  return result?.data;
};

export const updateSelfRosterProfile = async (companyId, profileId, profile) => {
  const result = await Network.patch(`/v1/management/companies/${companyId}/self-roster-profiles/${profileId}`, profile);
  return result?.data;
};

export const deleteSelfRosterProfile = async (companyId, profileId) => {
  const result = await Network.delete(`/v1/management/companies/${companyId}/self-roster-profiles/${profileId}`);
  return result;
};

export const getSelfRosterProfile = async (companyId) => {
  const result = await Network.get(`/v1/management/companies/${companyId}/self-roster-profiles`);
  return result?.data;
};

export const getSelfRosterDepartmentProfile = async (companyId) => {
  const result = await Network.get(`/v1/management/companies/${companyId}/self-roster-profile-departments`);
  return result?.data;
};

export const updateRosterDepartmentProfile = async (companyId, departmentId, profileId) => {
  const result = await Network.put(`/v1/management/companies/${companyId}/self-roster-profile-departments/${departmentId}`, {
    companySelfRosterProfileId: profileId,
  });
  return result?.data;
};

export const deleteRosterDepartmentProfile = async (companyId, departmentId) => {
  const result = await Network.delete(`/v1/management/companies/${companyId}/self-roster-profile-departments/${departmentId}`);
  return result;
};

export const setPassword = async (companyId, userId, password) => {
  const result = await Network.post(`/v1/management/companies/${companyId}/users/${userId}/reset-password`, { newPassword: password });
  return result?.data;
};
