import React, { PureComponent } from 'react';
import { Button, Modal,SelectUserModal } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import propTypes from 'prop-types';

import { superAdminService } from '../../../../services';
import { translations } from '../../../../utils';
import ListItem from '../../../listItem/ListItem';

import './holdingAdminsTab.scss';


class HoldingAdminsTab extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingHoldingAdmins: false,
      holdingAdmins: [],
      users: [],
      isAddingAdmin: false,
      isDeletingAdmin: false,
      userIdToDelete: null,
    };
  }
  async componentDidMount() {
    await this.getHoldingAdmins();
    this.getHoldingUsers();
  }

  getHoldingAdmins = async () => {
    this.setState({ isLoadingHoldingAdmins: true });
    const holdingAdmins = await superAdminService.getHoldingAdmins(this.props.holdingId);
    return this.setState({ holdingAdmins, isLoadingHoldingAdmins: false });
  }

  getHoldingUsers = async () => {
    this.setState({ isLoadingUsers: true });
    const users = await superAdminService.getHoldingUsers(this.props.holdingId);
    this.setState({ users, isLoadingUsers: false });
  }

  onSelect = async (selectedUsers) => {
    this.setState({ savingChanges: true });

    // Filter users that are already an admin
    const newAdminUsers = selectedUsers.filter((userId) => this.state.holdingAdmins.id !== userId);
    // Get all the users that need to be removed
    const adminUsersToBeRemoved = this.state.holdingAdmins.filter((admin) => !selectedUsers.includes(admin.id)).map(admin => admin.id);

    if (selectedUsers) {
      await Promise.all([
        ...newAdminUsers.map(async (userId) => {
          await superAdminService.addHoldingAdmin(this.props.holdingId, userId);
        }),
        ...adminUsersToBeRemoved.map(async (userId) => {
          await superAdminService.deleteHoldingAdmin(this.props.holdingId, userId);
        }),
    ]);
      await this.getHoldingAdmins();
      this.getHoldingUsers();
    }
    this.handleAddAdminModal();
    this.setState({ savingChanges: false });
  }

  handleAddAdminModal = () => this.setState({ isAddingAdmin: !this.state.isAddingAdmin })

  search = async (search) => {
    this.setState({ isLoadingUsers: true });
    const users = await superAdminService.getHoldingUsers(this.props.holdingId, search);
    this.setState({ users, isLoadingUsers: false });
  }

  selectAdminForRemoval = (userId) => () => this.setState({ isDeletingAdmin: true, userIdToDelete: userId });

  deleteAdmin = async () => {
    await superAdminService.deleteHoldingAdmin(this.props.holdingId, this.state.userIdToDelete);
    this.handleAdminRemovalModal();
    this.getHoldingAdmins();
  }

  handleAdminRemovalModal = () => this.setState({ isDeletingAdmin: !this.state.isDeletingAdmin })

  renderHoldingAdmin = (holdingAdmin) => {
    const { id, firstName, lastName, profilePictureUrl, email } = holdingAdmin;
    return <ListItem key={id} title={`${firstName} ${lastName}`} description={email} avatarProps={{ firstName, lastName, source: profilePictureUrl }} icon="TrashIcon" iconColor="error" onIconClick={this.selectAdminForRemoval(id)} />;
  }

  renderSkeleton = (key) => <div key={key} className="loading-wrapper"><Skeleton width="100%" height="10.5rem" /></div>;

  render() {
    return (
      <div className="holding-admins-container">
        <div className="holding-admin-controls">
          <Button onClick={this.handleAddAdminModal}>{translations.getLabel('lblAddNewHoldingAdmin')}</Button>
        </div>
        {this.state.isLoadingHoldingAdmins
          ?
          [1, 2, 3].map(this.renderSkeleton)
          :
          <div>
            {this.state.holdingAdmins.map(this.renderHoldingAdmin)}
          </div>}
        {
          this.state.isAddingAdmin &&
          <SelectUserModal
            cancelLabel={translations.getLabel('cancel')}
            selectedUsers={this.state.holdingAdmins.map(admin => admin.id)}
            isOpen={this.state.isAddingAdmin}
            isSaveLoading={this.state.savingChanges}
            onSearch={this.search}
            onSelect={this.onSelect}
            requestClose={this.handleAddAdminModal}
            saveLabel={translations.getLabel('lblAdd')}
            searchLabel={translations.getLabel('lblSearchContact')}
            title={translations.getLabel('lblNewHoldingAdmin')}
            users={this.state.users}
            isLoading={this.state.isLoadingUsers}
          />
        }
        <Modal
          open={this.state.isDeletingAdmin}
          title={translations.getLabel('lblGenericWarning')}
          requestClose={this.handleAdminRemovalModal}
          leftButtonProps={{
            label: translations.getLabel('cancel'),
            onClick: this.handleAdminRemovalModal,
          }}
          rightButtonProps={{
            label: translations.getLabel('lblRevoke'),
            onClick: this.deleteAdmin,
          }}
        >
          <div className="warning-message">{translations.getLabel('lblDeleteHoldingAdmin')}</div>
        </Modal>
      </div>
    );
  }
}

export default HoldingAdminsTab;

HoldingAdminsTab.propTypes = {
  holdingId: propTypes.string.isRequired,
};
