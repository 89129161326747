import React, { useState } from 'react';
import { InputField, InputType,Modal } from 'react-ess-components';
import PropTypes from 'prop-types';

import { transactionTypeTypes } from '../../../../../../constants/transactionTypes';
import { useForm } from '../../../../../../hooks';
import { translations } from '../../../../../../utils';

import '../CountersConfigurationTab.scss';

function TransactionModal({ editingTransaction, editingTransactionId, handleSave, isModalOpen, toggleModal }) {
  const { form, setFormAttribute, clearForm } = useForm(editingTransaction);
  const [isLoading, setIsLoading] = useState(false);

  const handleButtonDisable = () => {
    if (!form.externalIdFrom || !form.externalIdTo || !form.transactionType) {
      return true;
    }
  };

  const handleModalInput = (key) => (value) => setFormAttribute(value, key);

  const handleSaveModal = async () => {
    setIsLoading(true);

    await handleSave(form, editingTransactionId);

    setIsLoading(false);
    toggleModal();
  };

  const toggleTransactionModal = () => () => {
    toggleModal();
    clearForm();
  };

  return (
    <Modal
      requestClose={toggleTransactionModal()}
      open={isModalOpen}

      rightButtonProps={{ label: translations.getLabel('save'), onClick: handleSaveModal, isLoading, disabled: handleButtonDisable() }}
      leftButtonProps={{ label: translations.getLabel('cancel'), onClick: toggleTransactionModal() }}
      title={translations.getLabel('titleNewTransaction')}
    >
      <div className="form">
        <div className="form-row">
          <div className="input-container">
            <p className="input-container">{translations.getLabel('lblWFMIDFrom')}</p>
            <InputField type={InputType.Text} emptyIcon onChange={handleModalInput('externalIdFrom')} value={form.externalIdFrom} />
          </div>
          <div className="input-container">
            <p className="input-container">{translations.getLabel('lblWFMIDTo')}</p>
            <InputField type={InputType.Text} emptyIcon onChange={handleModalInput('externalIdTo')} value={form.externalIdTo} />
          </div>
          <div className="input-container">
            <p className="input-container">{translations.getLabel('lblType')}</p>
            <InputField
                type={InputType.Select}
                options={transactionTypeTypes.map(type => ({ ...type, label: translations.getLabel(type.label) }))}
                placeholder={translations.getLabel('lblPlaceholderTransactionTypeType')}
                emptyIcon
                onChange={handleModalInput('transactionType')}
                value={form.transactionType} />
          </div>
        </div>
        <div className="form-row">
          <div className="input-container">
            <p className="input-container">{translations.getLabel('lblNameNL')}</p>
            <InputField autoFocus type={InputType.Text} emptyIcon onChange={handleModalInput('nameNl')} value={form.nameNl} />
          </div>
          {form.transactionType === 'BOOLEAN' &&
            <>
              <div className="input-container">
                <p className="input-container">{translations.getLabel('lblDescriptionTrueNL')}</p>
                <InputField autoFocus type={InputType.Text} emptyIcon onChange={handleModalInput('descriptionTrueNl')} value={form.descriptionTrueNl} />
              </div>
              <div className="input-container">
                <p className="input-container">{translations.getLabel('lblDescriptionFalseNL')}</p>
                <InputField autoFocus type={InputType.Text} emptyIcon onChange={handleModalInput('descriptionFalseNl')} value={form.descriptionFalseNl} />
              </div>
            </>
          }
        </div>
        <div className="form-row">
          <div className="input-container">
            <p className="input-container">{translations.getLabel('lblNameDE')}</p>
            <InputField autoFocus type={InputType.Text} emptyIcon onChange={handleModalInput('nameDe')} value={form.nameDe} />
          </div>
          {form.transactionType === 'BOOLEAN' &&
            <>
              <div className="input-container">
                <p className="input-container">{translations.getLabel('lblDescriptionTrueDE')}</p>
                <InputField autoFocus type={InputType.Text} emptyIcon onChange={handleModalInput('descriptionTrueDe')} value={form.descriptionTrueDe} />
              </div>
              <div className="input-container">
                <p className="input-container">{translations.getLabel('lblDescriptionFalseDE')}</p>
                <InputField autoFocus type={InputType.Text} emptyIcon onChange={handleModalInput('descriptionFalseDe')} value={form.descriptionFalseDe} />
              </div>
            </>
          }
        </div>
        <div className="form-row">
          <div className="input-container">
            <p className="input-container">{translations.getLabel('lblNameFR')}</p>
            <InputField autoFocus type={InputType.Text} emptyIcon onChange={handleModalInput('nameFr')} value={form.nameFr} />
          </div>
          {form.transactionType === 'BOOLEAN' &&
            <>
              <div className="input-container">
                <p className="input-container">{translations.getLabel('lblDescriptionTrueFR')}</p>
                <InputField autoFocus type={InputType.Text} emptyIcon onChange={handleModalInput('descriptionTrueFr')} value={form.descriptionTrueFr} />
              </div>
              <div className="input-container">
                <p className="input-container">{translations.getLabel('lblDescriptionFalseFR')}</p>
                <InputField autoFocus type={InputType.Text} emptyIcon onChange={handleModalInput('descriptionFalseFr')} value={form.descriptionFalseFr} />
              </div>
            </>
            }
        </div>
        <div className="form-row">
          <div className="input-container">
            <p className="input-container">{translations.getLabel('lblNameEN')}</p>
            <InputField autoFocus type={InputType.Text} emptyIcon onChange={handleModalInput('nameEn')} value={form.nameEn} />
          </div>
          {form.transactionType === 'BOOLEAN' &&
            <>
              <div className="input-container">
                <p className="input-container">{translations.getLabel('lblDescriptionTrueEN')}</p>
                <InputField autoFocus type={InputType.Text} emptyIcon onChange={handleModalInput('descriptionTrueEn')} value={form.descriptionTrueEn} />
              </div>
              <div className="input-container">
                <p className="input-container">{translations.getLabel('lblDescriptionFalseEN')}</p>
                <InputField autoFocus type={InputType.Text} emptyIcon onChange={handleModalInput('descriptionFalseEn')} value={form.descriptionFalseEn} />
              </div>
            </>
          }
        </div>
      </div>
    </Modal>
  );
}

TransactionModal.propTypes = {
  editingTransaction: PropTypes.object,
  editingTransactionId: PropTypes.string,
  handleSave: PropTypes.func,
  isModalOpen: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
};

const noop = () => { };

TransactionModal.defaultProps = {
  editingTransaction: {},
  editingTransactionId: '',
  handleSave: noop,
};

export default TransactionModal;
