import Network from './Network';

// Source: https://stackoverflow.com/a/20732091
export const convertFileSize = (size) => {
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB'][i];
};

export const convertMBtoByte = numberOfMb => 1024 * 1024 * numberOfMb;

export const convertFileToArrayBuffer = file => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsArrayBuffer(file);

    reader.onload = function () {
      resolve(reader.result);
    };
    reader.onerror = function (error) {
      reject(error);
    };
  });
};

export const uploadFileToPresignedUrl = async (url, file, contentType) => {
  const buffer = await convertFileToArrayBuffer(file);
  return Network.put(url, buffer, { 'Content-Type': contentType || file.type, 'Content-Disposition': `inline; rfilename=${file.name}` });
};

