import React from 'react';
import { InputField, InputType } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { validation } from '../../../../../utils';

const ColorInput = ({ title, description, color, onChange, isLoading, isEditing }) => {
  const colorInputClassname = classnames('color-input', { 'cursor': isEditing });

  return (<div className="color-setting">
    <div className="color-setting-description">
      <p>{title}</p>
      <p>{description}</p>
    </div>
    {!isLoading ? <div className="color-preview-tool">
      <div className={colorInputClassname}>
        <InputField disabled={!isEditing} emptyIcon value={color} type={InputType.Color} onChange={onChange} />
      </div>
      <InputField className={isEditing ? '' : 'hidden'} emptyIcon value={color} error={!validation.isColor(color)} type={InputType.Text} onChange={onChange} />
    </div>
      : <Skeleton width="3.9rem" height="4.2rem" />}
  </div >);
};

ColorInput.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  color: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  isEditing: PropTypes.bool,
};

ColorInput.defaultProps = {
  setting: {},
  color: '#000000',
  isLoading: false,
  isEditing: false,
};

export default ColorInput;
