import { Network } from '../utils';

export const login = async (username, password, isSuperAdmin, holding = {}) => {
  const result = await Network.post(`/v1/auth/login${isSuperAdmin ? '/superuser' : ''}`, { username, password }, holding);
  if (!isSuperAdmin) {
    // only for development purposes
    const employmentId = result.data.employments[0].id;
    await Network.post(`/v1/employments/${employmentId}/switch`);
  }
  return result.data;
};


export const logout = async (isSuperAdmin) => {
  const result = await Network.post(`/v1/auth/logout${isSuperAdmin ? '/superuser' : ''}`);
  return result.data;
};
