import React, { useContext, useEffect, useState } from 'react';
import { InputField, InputType, Modal } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

import ConfigContext from '../../../../../../context/ConfigContext';
import { useToggle } from '../../../../../../hooks';
import { holdingAdminService } from '../../../../../../services';
import { translations } from '../../../../../../utils';
import EditItem from '../../../../../editItem/EditItem';
import Switch from '../../../../../switch/Switch';

const VacationApprovalSetting = (props) => {
  const context = useContext(ConfigContext);
  const [isVacationApprovalSettingModalOpen, toggleVacationApprovalSettingModalOpen] = useToggle();
  const [enableSpxcVacationRequests, setEnableSpxcVacationRequests] = useState(false);
  const [isCountersLoading, setIsCountersLoading] = useToggle(true);
  const [counters, setCounters] = useState([]);
  const [counterId, setCounterId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    if (Object.keys(context.companyConfig).length > 0) {
      setEnableSpxcVacationRequests(context.companyConfig.enableSpxcVacationRequests);
      setCounterId(context.companyConfig.spxVacationStatusCounterId);
      setInitialLoading(false);
      initCounters();
    }
  }, [props.companyId, context.companyConfig]);

  const initCounters = async () => {
    if (context.config.holdingId && !counters.length) {
      const counters = await holdingAdminService.getAllCounters(context.config.holdingId);
      setCounters(counters.map(counter => ({ value: counter.id, label: counter.description })));
      setIsCountersLoading(false);
    }
  };

  const onSave = async () => {
    setIsLoading(true);
    const result = await holdingAdminService.updateCompanyConfig(props.holdingId, props.companyId, { enableSpxcVacationRequests, spxVacationStatusCounterId: counterId });
    if (result) {
      context.updateCompanyConfig(result.config);
      toggleVacationApprovalSettingModalOpen();
    }
    setIsLoading(false);
  };

  const cancelModal = () => {
    setEnableSpxcVacationRequests(context.companyConfig.enableSpxcVacationRequests);
    toggleVacationApprovalSettingModalOpen();
  };

  return (
    <>
      <EditItem title={translations.getLabel('titleVacationApprovalSetting')} label={translations.getLabel('lblVacationApprovalSetting')} onClick={toggleVacationApprovalSettingModalOpen}>
        <p className="weekday-value">{isInitialLoading ? <Skeleton width={150} /> : translations.getLabel(context.companyConfig.enableSpxcVacationRequests ? 'yes' : 'no')}</p>
      </EditItem>
      <Modal
        title={translations.getLabel('titleVacationApprovalSetting')}
        open={isVacationApprovalSettingModalOpen}
        requestClose={cancelModal}
        rightButtonProps={{ label: translations.getLabel('save'), onClick: onSave, isLoading: isLoading }}
        leftButtonProps={{ label: translations.getLabel('cancel'), onClick: cancelModal }}
      >
        <div className="popup-schedule-config-container with-rows">
          <div className="row">
            <p>{translations.getLabel('lblVacationApprovalSetting')}</p>
            <Switch isChecked={enableSpxcVacationRequests} handleChange={(value) => setEnableSpxcVacationRequests(value)} leftLabel={translations.getLabel('no')} rightLabel={translations.getLabel('yes')} />
          </div>
          {!enableSpxcVacationRequests && <div className="row">
            <p>{translations.getLabel('lblExternalVacationCounterSetting')}</p>
            {isCountersLoading ? <Skeleton width={150} height={20} /> :
              <InputField
                type={InputType.Select}
                options={counters}
                value={counterId}
                onChange={setCounterId}
              />
            }
          </div>}
        </div>
      </Modal>
    </>
  );
};

VacationApprovalSetting.propTypes = {
  companyId: PropTypes.string.isRequired,
  holdingId: PropTypes.string.isRequired,
};

export default VacationApprovalSetting;
