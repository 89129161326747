import React, { Fragment, useEffect, useState } from 'react';
import { Button, Icon, IconButton, Modal } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import { superAdminService } from '../../../../../../services';
import { translations } from '../../../../../../utils';
import TransactionModal from '../transactions/TransactionModal';

import '../CountersConfigurationTab.scss';


function TransactionConfiguration({ holdingId }) {
  const [transactions, setTransactions] = useState([]);
  const [editingTransaction, setEditingTransaction] = useState({});
  const [editingTransactionId, setEditingTransactionId] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const [removeId, setRemoveId] = useState('');

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    let transactions = await superAdminService.getHoldingTransactions(holdingId);

    transactions = sortTransactions(transactions);

    setTransactions(transactions);
    setIsLoading(false);
  };

  const deleteTransaction = async () => {
    setIsRemoveLoading(true);
    await superAdminService.deleteHoldingTransaction(holdingId, removeId);

    setIsConfirmationModalOpen(false);
    setIsRemoveLoading(false);
    getData();
  };

  const handleSave = async (transaction, id = '') => {
    setIsLoading(true);

    const transactionBody = {
      nameNl: transaction.nameNl || '',
      nameEn: transaction.nameEn || '',
      nameFr: transaction.nameFr || '',
      nameDe: transaction.nameDe || '',
      descriptionTrueNl: transaction.descriptionTrueNl || '',
      descriptionTrueEn: transaction.descriptionTrueEn || '',
      descriptionTrueFr: transaction.descriptionTrueFr || '',
      descriptionTrueDe: transaction.descriptionTrueDe || '',
      descriptionFalseNl: transaction.descriptionFalseNl || '',
      descriptionFalseEn: transaction.descriptionFalseEn || '',
      descriptionFalseFr: transaction.descriptionFalseFr || '',
      descriptionFalseDe: transaction.descriptionFalseDe || '',
      externalIdFrom: transaction.externalIdFrom || '',
      externalIdTo: transaction.externalIdTo || '',
      transactionType: transaction.transactionType || '',
    };

    if (id) {
      await superAdminService.updateHoldingTransaction(holdingId, id, transactionBody);
    } else {
      await superAdminService.addHoldingTransaction(holdingId, transactionBody);
    }

    getData();
  };

  const renderLoading = key => {
    return <div key={key} className="loading-wrapper"><Skeleton width="100%" count={15} /></div>;
  };

  const renderRowTransactions = (transaction) => {
    return (
      <tr key={transaction.id}>
        <td>{transaction.nameEn}</td>
        <td>{transaction.externalIdFrom}</td>
        <td>{transaction.externalIdTo}</td>
        <td>{transaction.transactionType}</td>
        <td className="icon-cell">
          <IconButton
            color="primary"
            onClick={toggleModal(transaction, transaction.id)}
            tag="EditIcon"
          />
        </td>
        <td className="icon-cell">
          <IconButton
            color="error"
            onClick={toggleConfirmationModal(transaction.id)}
            tag="TrashIcon"
          />
        </td>
      </tr>
    );
  };

  const toggleModal = (transaction = {}, transactionId = '') => () => {
    setIsModalOpen(!isModalOpen);
    setEditingTransaction(transaction);
    setEditingTransactionId(transactionId);
  };

  const toggleConfirmationModal = (id) => () => {
    setIsConfirmationModalOpen(!isConfirmationModalOpen);
    setRemoveId(id);
  };

  const sortTransactions = (transactions) => {
    return transactions.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateA - dateB;
    }
    );
  };

  return (
    <>
      <div className="item-container">
        <h4>{translations.getLabel('titleTransactions')}</h4>
        <p>{translations.getLabel('lblHoldingTransactionConfiguration')}</p>
        {isLoading
          ? renderLoading()
          : <Fragment>
            {transactions.length > 0 ? (
              <table className="table">
                <thead>
                  <tr>
                    <th>{translations.getLabel('lblNameEN')}</th>
                    <th>{translations.getLabel('lblWFMIDFrom')}</th>
                    <th>{translations.getLabel('lblWFMIDTo')}</th>
                    <th>{translations.getLabel('lblType')}</th>
                    <th colSpan={3} />
                  </tr>
                </thead>
                <tbody>{transactions.map(renderRowTransactions)}</tbody>
              </table>

            ) : <div />}
          </Fragment>
        }

        <div className="add-button">
          <Button theme="transparent" onClick={toggleModal()}>
            <Icon tag="PlusSquareIcon" color="primary" />
            <p>{translations.getLabel('btnAddTransaction')}</p>
          </Button>
        </div>

        <div className="hr" />

        {isModalOpen && <TransactionModal editingTransaction={editingTransaction} editingTransactionId={editingTransactionId} handleSave={handleSave} isLoading={isLoading} isModalOpen={isModalOpen} toggleModal={toggleModal()} />}
        <Modal
          requestClose={toggleConfirmationModal()}
          open={isConfirmationModalOpen}
          rightButtonProps={{ label: translations.getLabel('lblDelete'), onClick: deleteTransaction, isLoading: isRemoveLoading }}
          leftButtonProps={{ label: translations.getLabel('cancel'), onClick: toggleConfirmationModal() }}
          title={translations.getLabel('titleConfirmationDeleteTransaction')}
        >
          <p className="confirmation-message">{translations.getLabel('lblConfirmationDeleteTransaction')}</p>
        </Modal>
      </div>
    </>
  );
}

TransactionConfiguration.propTypes = {
  holdingId: PropTypes.string.isRequired,
};



export default withRouter(TransactionConfiguration);
