/* eslint-disable no-throw-literal */
import axios from 'axios';

import { setNetworkAuthInterceptor } from './authInterceptor';
import { setNetworkErrorInterceptor } from './errorInterceptor';
import { cancelPreviousRequestIfneeded } from './NetworkUtils';

let authToken = null;
setNetworkAuthInterceptor();
setNetworkErrorInterceptor();

let tokensByRoutes = [];

class Network {
  static setAuthToken(accessToken) {
    authToken = accessToken;
  }

  static getAuthToken() {
    return authToken;
  }

  static getUrl(route) {
    if (route.indexOf('http://') === 0 || route.indexOf('https://') === 0 || route.indexOf('www.') === 0) {
      return route;
    }
    return `${process.env.REACT_APP_API_HOST}${route}`;
  }

  static getUrlWithParams(route, params) {
    let url = Network.getUrl(route);
    if (params) {
      for (const property in params) {
        if (params.hasOwnProperty(property)) {
          url = Network.addQueryStringParameter(url, property, params[property]);
        }
      }
    }
    return url;
  }

  static addQueryStringParameter(uri, key, value) {
    const re = new RegExp(`([?&])${key}=.*?(&|$)`, 'i');
    const separator = uri.indexOf('?') !== -1 ? '&' : '?';
    if (uri.match(re)) {
      return uri.replace(re, `$1${key}=${value}$2`);
    }
    return `${uri + separator + key}=${value}`;
  }

  static getBasicHeaders() {
    const headers = {};

    headers['Content-Type'] = 'application/json';
    headers['app-version'] = `${process.env.REACT_APP_VERSION_NR} ${process.env.HEROKU_RELEASE_VERSION}`;
    headers['app-platform'] = 'Admin';

    headers.Accept = 'application/json';

    if (process.env.REACT_APP_API_KEY) {
      headers['api-secret'] = process.env.REACT_APP_API_KEY;
    }

    if (authToken) {
      headers.Authorization = `Bearer ${authToken}`;
    }

    return headers;
  }

  static errorHandler(error) {
    if (error.response) {
      throw {
        errors: (error.response.data && error.response.data.errors) || [
          {
            code: '0',
            status: 500,
            title: 'Unknown error',
            meta: error.response,
          },
        ],
      };
    } else if (error.request) {
      // The request was made but no response was received
      throw {
        errors: [
          {
            code: '0',
            status: 500,
            title: 'Unknown error',
            meta: error.request,
          },
        ],
      };
    } else {
      // Something happened in setting up the request that triggered an Error
      throw {
        errors: [
          {
            code: '0',
            status: 500,
            title: 'Unknown error',
            meta: error.message,
          },
        ],
      };
    }
  }

  static async get(route, params = {}, headers = {}) {
    try {
      const cancelToken = axios.CancelToken.source();
      tokensByRoutes = cancelPreviousRequestIfneeded(route, tokensByRoutes, cancelToken);

      const result = await axios.get(this.getUrlWithParams(route, params), { headers: { ...this.getBasicHeaders(), ...headers }, cancelToken: cancelToken.token, withCredentials: true });
      return result.data;
    } catch (err) {
      this.errorHandler(err);
    }
  }

  static async put(route, body = {}, headers = {}, params = {}) {
    try {
      const result = await axios.put(this.getUrlWithParams(route, params), body, { headers: { ...this.getBasicHeaders(), ...headers }, withCredentials: true });
      return result.data;
    } catch (err) {
      this.errorHandler(err);
    }
  }

  static async patch(route, body = {}, headers = {}) {
    try {
      const result = await axios.patch(this.getUrl(route), body, { headers: { ...this.getBasicHeaders(), ...headers }, withCredentials: true });
      return result.data;
    } catch (err) {
      this.errorHandler(err);
    }
  }

  static async post(route, body = {}, headers = {}) {
    try {
      const result = await axios.post(this.getUrl(route), body, { headers: { ...this.getBasicHeaders(), ...headers }, withCredentials: true });
      return result.data;
    } catch (err) {
      this.errorHandler(err);
    }
  }

  static async delete(route, body = {}, headers = {}) {
    try {
      const result = await axios.delete(this.getUrl(route), { headers: { ...this.getBasicHeaders(), ...headers }, data: body, withCredentials: true });
      return result.data || !result.error;
    } catch (err) {
      this.errorHandler(err);
    }
  }
}

export default Network;
