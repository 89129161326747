import {
  AvailabilitiesConfigurationTab,
  CompaniesTab,
  CountersConfigurationTab,
  GeneralConfigurationTab,
  HoldingAdminsTab,
  LoginTab,
  ScheduleTab,
  TimeRegistrationTypesTab,
} from '../components';
import { translations } from '../utils';

export const superAdminTabStructure = [
  {
    url: 'configuration',
    label: translations.getLabel('titleHoldingConfiguration'),
    sideTabs: [
      {
        url: 'general',
        label: translations.getLabel('btnGeneral'),
        component: GeneralConfigurationTab,
      },
      {
        url: 'login',
        label: translations.getLabel('btnLoginTab'),
        component: LoginTab,
      },
      {
        url: 'schedule',
        label: translations.getLabel('btnSchedule'),
        component: ScheduleTab,
      },
      {
        url: 'availabilities',
        label: translations.getLabel('btnAvailabilities'),
        component: AvailabilitiesConfigurationTab,
      },
      {
        url: 'counters',
        label: translations.getLabel('btnCounters'),
        component: CountersConfigurationTab,
      },
      {
        url: 'timeRegistrationTypes',
        label: translations.getLabel('titleTimeRegistration'),
        component: TimeRegistrationTypesTab,
      },
    ],
  },
  {
    url: 'holding-admins',
    label: translations.getLabel('titleHoldingAdmins'),
    sideTabs: null,
    component: HoldingAdminsTab,
  },
  {
    url: 'companies',
    label: translations.getLabel('titleCompanies'),
    sideTabs: null,
    component: CompaniesTab,
  },
];
