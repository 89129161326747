import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { requestTypes } from '../../../../../../constants';
import { holdingAdminService } from '../../../../../../services';
import { translations } from '../../../../../../utils';
import CountersEditItem from './CountersEditItem';

let didCancel = false;

const ShiftOfferCounters = (props) => {
  const [isEditLoading, setIsEditLoading] = useState(false);

  useEffect(() => {
    didCancel = false;
    return () => {
      didCancel = true;
    };
  }, []);

  const onSave = async (counterIds) => {
    setIsEditLoading(true);
    await holdingAdminService.updateCounterVisibilities(props.holdingId, props.companyId, requestTypes.shiftOffer, { counterIds });
    if (!didCancel) {
      props.getData();
      setIsEditLoading(false);
    }
  };

  return (
    <CountersEditItem
      counters={props.counters}
      isLoading={props.isLoading || isEditLoading}
      onSave={onSave}
      title={translations.getLabel('titleShiftOfferCounters')}
      label={translations.getLabel('lblShiftOfferCountersCompanyScheduleDescription')}
      emptyLabel={translations.getLabel('lblNoShiftOfferCounters')}
      availableCounters={props.availableCounters}
    />
  );
};

ShiftOfferCounters.propTypes = {
  companyId: PropTypes.string.isRequired,
  holdingId: PropTypes.string.isRequired,
  counters: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  availableCounters: PropTypes.array.isRequired,
  getData: PropTypes.func.isRequired,
};

export default ShiftOfferCounters;
