import React, { PureComponent } from 'react';
import { Button, FileUpload, Modal } from 'react-ess-components';
import PropTypes from 'prop-types';

import ConfigContext from '../../../../../context/ConfigContext';
import { companyAdminService } from '../../../../../services';
import { fileUploadUtils, translations, urlUtils } from '../../../../../utils';

import './LogoTab.scss';

export default class Logo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      downloadUrl: null,
      isLoading: false,
      isRemoveLoading: false,
      isConfirmationModalOpen: false,
    };
  }

  componentDidMount() {
    this.getCompanyPreferences();
  }

  getCompanyPreferences = async () => {
    const result = await companyAdminService.getCompanyPreferences(this.props.companyId);
    if (result) {
      result.logoUrl = urlUtils.addCacheBreaker(result.logoUrl);
      this.context.updateCompanyConfig(result);
    }
  }

  handleUpload = async (file) => {
    this.setState({ isLoading: true });
    const url = await companyAdminService.getPresignedUrl(this.props.companyId, 'logoUrl');
    if (url) {
      await fileUploadUtils.uploadFileToPresignedUrl(url, file, 'image/png');
      this.getCompanyPreferences();
      return this.setState({ isLoading: false });
    }
    this.setState({ isLoading: false });
  }

  onDelete = async () => {
    this.setState({ isRemoveLoading: true });
    await companyAdminService.deleteImage(this.props.companyId, 'logoUrl');
    this.setState({ isRemoveLoading: false, isConfirmationModalOpen: false });
    this.getCompanyPreferences();
  }

  toggleConfirmationModal = () => this.setState({ isConfirmationModalOpen: !this.state.isConfirmationModalOpen });

  render() {
    return (
      <React.Fragment>
        <div className="logo-container">
          <p>{translations.getLabel('lblAddLogo')}</p>
          <div className="image-upload">
            <div className="file">
              <FileUpload
                acceptFileTypes="image/png, image/jpg, image/jpeg"
                translationLabels={{
                  lblDrag: translations.getLabel('lblDragDocument'),
                  lblBtn: translations.getLabel('btnChooseDocument'),
                }}
                isLoading={this.state.isLoading}
                showPreview={false}
                isModal={false}
                handleUpload={this.handleUpload}
              />
            </div>
            <div className="logo" >
              {
                this.context.companyConfig && this.context.companyConfig.logoUrl &&
                <React.Fragment>
                  <img src={this.context.companyConfig.logoUrl} alt="logo" />
                  <Button theme="transparent" onClick={this.toggleConfirmationModal} >{translations.getLabel('lblRemoveImage')}</Button>
                </React.Fragment>
              }
            </div>
          </div>
        </div>
        <Modal
          requestClose={this.toggleConfirmationModal}
          open={this.state.isConfirmationModalOpen}
          rightButtonProps={{ label: translations.getLabel('lblDelete'), onClick: this.onDelete, isLoading: this.state.isRemoveLoading }}
          leftButtonProps={{ label: translations.getLabel('cancel'), onClick: this.toggleConfirmationModal }}
          title={translations.getLabel('titleConfirmationDeleteLogo')}
        >
          <p className="confirmation-message">{translations.getLabel('lblConfirmationDeleteLogo')}</p>
        </Modal>
      </React.Fragment>
    );
  }
}

Logo.contextType = ConfigContext;

Logo.propTypes = {
  companyId: PropTypes.string.isRequired,
};
