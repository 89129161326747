import I18n from 'i18n-js';

import de from '../locals/de';
import en from '../locals/en';
import fr from '../locals/fr';
import nl from '../locals/nl';

const getLabel = (key, inserts = {}) => {
  if (process.env.REACT_APP_ENV !== 'production' && I18n.t(key, inserts) === '') {
    return `[[${key}]]`;
  }
  return I18n.t(key, inserts);
};

// Export specific function to get a label
// If the key is part of the label and not starting from index 0, we know it's a label that isn't found
// Since labels that are not found are formatted as [missing "<lang>.<key>" translation] not found
const doesLabelExist = key => getLabel(key).indexOf(key) <= 0;

I18n.fallbacks = true;
I18n.locale = 'en';
I18n.missingTranslation = (translation) => `[[${translation}]]`;

// Make translations object with all the labels
const translations = {
  en,
  nl,
  de,
  fr,
};
I18n.translations = translations;

export default {
  doesLabelExist,
  getLabel,
  I18n,
};
