import React from 'react';
import ReactSwitch from 'react-switch';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './Switch.scss';

const Switch = (props) => {
  const classLeft = classNames('switch-label', { inactive: props.isChecked });
  const classRight = classNames('switch-label', { inactive: !props.isChecked });

  return (
    <label className="switch-container">
      {props.leftLabel && <span className={classLeft}>{props.leftLabel}</span>}
      <ReactSwitch
        className="switch"
        onChange={props.handleChange}
        uncheckedIcon={false}
        checkedIcon={false}
        checked={props.isChecked}
      />
      {props.rightLabel && <span className={classRight}>{props.rightLabel}</span>}
    </label>
  );
};

Switch.propTypes = {
  handleChange: PropTypes.func.isRequired,
  isChecked: PropTypes.bool.isRequired,
  leftLabel: PropTypes.string,
  rightLabel: PropTypes.string,
};

Switch.defaultProps = {
  leftLabel: '',
  rightLabel: '',
};

export default Switch;