import React, { useEffect, useState } from 'react';
import { Button, Icon, IconButton, InputField, InputType, Modal } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

import { superAdminService } from '../../../../../../services';
import { translations } from '../../../../../../utils';

import '../loginTab.scss';

let didCancel = false;

const SpeakapSettings = ({ holdingId, holding }) => {
  const [addOrEditSetting, setAddOrEditSetting] = useState({});
  const [editId, setEditId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const [removeId, setRemoveId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [config, setConfig] = useState([]);

  useEffect(() => {
    didCancel = false;

    if (holdingId) getData();

    return () => {
      didCancel = true;
    };
  }, [holdingId]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    //TODO implement didcancel
    if (editId) {
      setIsModalOpen(true);
      const setting = { ...config.find(c => c.id === editId) };
      delete setting.secret;
      setAddOrEditSetting(setting);
    }
  }, [editId]); //eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    setIsLoading(true);
    const result = await superAdminService.getSpeakapConfig(holdingId);
    if (!didCancel) {
      if (result) {
        setConfig(result);
      }
      setIsLoading(false);
    }
  };

  const renderRow = (setting, i) => {
    return (
      <tr key={`cred-${i}`}>
        <td>{setting.description}</td>
        <td>{setting.appEid}</td>
        <td>******</td>
        <td>{setting.networkEid}</td>
        <td className="icon-cell">
          <IconButton tag="EditIcon" color="primary" onClick={() => setEditId(setting.id)} />
        </td>
        <td className="icon-cell">
          <IconButton tag="TrashIcon" color="error" onClick={() => toggleRemoveModal(setting.id)} />
        </td>
      </tr>
    );
  };

  const toggleModal = () => {
    if (isModalOpen) {
      setAddOrEditSetting({});
      setEditId('');
    }
    setIsModalOpen(!isModalOpen);
  };

  const toggleRemoveModal = (id) => {
    if (!isRemoveModalOpen) setRemoveId(id);
    setIsRemoveModalOpen(!isRemoveModalOpen);
  };

  const onChange = (key) => (data) => {
    const newItem = { ...addOrEditSetting };
    newItem[key] = data;
    setAddOrEditSetting(newItem);
  };

  const checkValidity = () => {
    const { description, appEid, secret, networkEid } = addOrEditSetting;
    if (description && appEid && (editId || secret) && networkEid) return true;
    else return false;
  };

  const onSave = async () => {
    let result = null;
    setIsSaveLoading(true);
    if (!editId) {
      result = await superAdminService.addSpeakapConfig(holdingId, addOrEditSetting);
    } else {
      const newSetting = { ...addOrEditSetting };
      delete newSetting.id;
      result = await superAdminService.updateSpeakapConfig(holdingId, editId, newSetting);
    }
    if (result) {
      if (!didCancel) {
        setIsSaveLoading(false);
        toggleModal();
        getData();
      }
    }
  };

  const onDelete = async (i) => {
    setIsRemoveLoading(true);
    await superAdminService.deleteSpeakapConfig(holdingId, removeId);
    if (!didCancel) {
      setIsRemoveLoading(false);
      toggleRemoveModal();
      getData();
    }
  };

  return (
    <div className="credentials-container">
      <h4 className="title">{translations.getLabel('titleSpeakap')}</h4>
      <p>{translations.getLabel('lblSpeakap')}</p>
      {
        !isLoading && config?.length ?
          <table className="table" >
            <tbody>
              <tr>
                <th>{translations.getLabel('lblDescription')}</th>
                <th>{translations.getLabel('lblEID')}</th>
                <th>{translations.getLabel('lblSecret')}</th>
                <th>{translations.getLabel('lblNetworkEID')}</th>
                <th />
                <th />
              </tr>
              {
                config.map(renderRow)
              }
            </tbody>
          </table>
          :
          <p className="empty-label">{translations.getLabel('lblEmptySpeakapConfig')}</p>
      }

      {
        isLoading &&
        <div className="empty-label">
          <Skeleton height={30} />
        </div>
      }

      <div className="add">
        <Button theme="transparent" onClick={toggleModal}>
          <Icon tag="PlusSquareIcon" color="primary" />
          <p>{translations.getLabel('btnAddSpeakap')}</p>
        </Button>
      </div>
      <Modal
        requestClose={toggleModal}
        open={isModalOpen}
        rightButtonProps={{ label: translations.getLabel('save'), onClick: onSave, disabled: !checkValidity(), isLoading: isSaveLoading }}
        leftButtonProps={{ label: translations.getLabel('cancel'), onClick: toggleModal }}
        title={translations.getLabel('titleSpeakap')}
      >
        <div className="form-container">
          <InputField emptyIcon value={addOrEditSetting.description} type={InputType.Text} onChange={onChange('description')} label={translations.getLabel('lblDescription')} />
          <InputField emptyIcon value={addOrEditSetting.appEid} type={InputType.Text} onChange={onChange('appEid')} label={translations.getLabel('lblEID')} />
          <InputField emptyIcon placeholder={editId && '******'} value={addOrEditSetting.secret} type={InputType.Text} onChange={onChange('secret')} label={translations.getLabel('lblSecret')} />
          <InputField emptyIcon value={addOrEditSetting.networkEid} type={InputType.Text} onChange={onChange('networkEid')} label={translations.getLabel('lblNetworkEID')} />
        </div>
      </Modal>

      <Modal
        requestClose={toggleRemoveModal}
        open={isRemoveModalOpen}
        rightButtonProps={{ label: translations.getLabel('lblDelete'), onClick: onDelete, isLoading: isRemoveLoading }}
        leftButtonProps={{ label: translations.getLabel('cancel'), onClick: toggleRemoveModal }}
        title={translations.getLabel('lblDeleteSpeakap')}
      >
        <p className="confirmation-message">{translations.getLabel('lblDeleteSpeakapWarning')}</p>
      </Modal>
    </div>
  );
};

SpeakapSettings.propTypes = {
  holdingId: PropTypes.string.isRequired,
  holding: PropTypes.object.isRequired,
};

export default SpeakapSettings;
