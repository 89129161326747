export const dateSettings = {
  DAYS: [
    { value: 'FIRST_DAY', label: 'lblFirstDay' },
    { value: 'LAST_DAY', label: 'lblLastDay' },
    { value: 'BEFORE_LAST_DAY', label: 'lblBeforeLastDay' },
    { value: 'CURRENT_DAY', label: 'lblCurrentDay' },
    { value: 'PREVIOUS_DAY', label: 'lblPreviousDay' },
    { value: 'NEXT_DAY', label: 'lblNextDay' },
  ],
  STARTPOINTS: [
    { value: 'BEFORE_LAST', label: 'lblBeforeLast' },
    { value: 'PREVIOUS', label: 'prev' },
    { value: 'CURRENT', label: 'lblCurrent' },
    { value: 'NEXT', label: 'next' },
    { value: 'AFTER_NEXT', label: 'lblAfterNext' },
  ],
  TYPE: [
    { value: 'week', label: 'lblWeek' },
    { value: 'month', label: 'lblMonth' },
    { value: 'year', label: 'lblYear' },
    { value: 'quarter', label: 'lblQuarter' },

  ],
  OFFSET_TYPES: [
    { value: 'DAYS', label: 'lblDays' },
    { value: 'WEEKS', label: 'lblWeeks' },
    { value: 'MONTHS', label: 'lblMonths' },
    { value: 'YEARS', label: 'lblYears' },
  ],
};