import React from 'react';
import { InputField, InputType } from 'react-ess-components';
import PropTypes from 'prop-types';

import { translations } from '../../../../../utils';

export const TimeRegistrationBeaconForm = ({ setFormAttribute, identifier, locationId }) => {
  return (
    <div>
      <div className="input-container">
        <p>{translations.getLabel('lblBeaconId')}</p>
        <InputField type={InputType.Text} value={identifier} emptyIcon name="identifier" onChange={setFormAttribute} />
      </div>
      <div className="input-container">
        <p>{translations.getLabel('lblLocationIdLocationWizard')}</p>
        <InputField type={InputType.Text} value={locationId} emptyIcon name="locationId" onChange={setFormAttribute} />
      </div>

    </div>
  );
};

TimeRegistrationBeaconForm.propTypes = {
  setFormAttribute: PropTypes.func.isRequired,
  identifier: PropTypes.string,
  locationId: PropTypes.string,
};

TimeRegistrationBeaconForm.defaultProps = {
  identifier: '',
  locationId: '',
};

export default TimeRegistrationBeaconForm;
