import React, { useContext,useEffect, useState } from 'react';
import { Modal } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

import ConfigContext from '../../../../../../context/ConfigContext';
import { useToggle } from '../../../../../../hooks';
import { holdingAdminService } from '../../../../../../services';
import { translations } from '../../../../../../utils';
import EditItem from '../../../../../editItem/EditItem';

const StartOfWeek = (props) => {
  const context = useContext(ConfigContext);

  const [isStartOfWeekModalOpen, toggleStartOfWeekModalOpen] = useToggle();
  const [editWeekday, setEditWeekday] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    if (Object.values(context.companyConfig).length > 0) {
      const weekday = context.companyConfig.startOfWeek === 'MONDAY' ? 1 : 0;
      setEditWeekday(weekday);
      setInitialLoading(false);
    }
  }, [context.companyConfig, props.companyId]);


  const onSave = async () => {
    setIsLoading(true);
    const result = await holdingAdminService.updateCompanyConfig(props.holdingId, props.companyId, { startOfWeek: editWeekday === 0 ? 'SUNDAY' : 'MONDAY' });
    if (result) {
      toggleStartOfWeekModalOpen();
      context.updateCompanyConfig(result.config);
    }
    setIsLoading(false);
  };

  const cancelModal = () => {
    toggleStartOfWeekModalOpen();
  };

  return (
    <>
      <EditItem title={translations.getLabel('titleStartOfWeek')} label={translations.getLabel('lblStartOfWeekCompany')} onClick={toggleStartOfWeekModalOpen}>
        <p className="weekday-value">{isInitialLoading ? <Skeleton width={150} /> : translations.getLabel(context.companyConfig.startOfWeek === 'MONDAY' ? 'lblMonday' : 'lblSunday')}</p>
      </EditItem>
      <Modal
        title={translations.getLabel('titleStartOfWeek')}
        open={isStartOfWeekModalOpen}
        requestClose={cancelModal}
        rightButtonProps={{ label: translations.getLabel('save'), onClick: onSave, isLoading }}
        leftButtonProps={{ label: translations.getLabel('cancel'), onClick: cancelModal }}
      >
        <div className="popup-schedule-config-container">
          <p>{translations.getLabel('lblSelectWhichDayOfWeek')}</p>
          <label htmlFor="monday">
            <input type="radio" onChange={() => setEditWeekday(1)} id="monday" checked={editWeekday === 1} /> {translations.getLabel('lblMonday')}
          </label>
          <label htmlFor="sunday">
            <input type="radio" onChange={() => setEditWeekday(0)} id="sunday" checked={editWeekday === 0} /> {translations.getLabel('lblSunday')}
          </label>
        </div>
      </Modal>
    </>
  );
};

StartOfWeek.propTypes = {
  companyId: PropTypes.string.isRequired,
  holdingId: PropTypes.string.isRequired,
};

export default StartOfWeek;
