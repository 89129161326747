import React, { PureComponent } from 'react';
import { Button, FileUpload } from 'react-ess-components';
import PropTypes from 'prop-types';

import { backgroundImages } from '../../../../../../constants';
import ConfigContext from '../../../../../../context/ConfigContext';
import { translations, urlUtils } from '../../../../../../utils';

export default class AppBackgroundTab extends PureComponent {
  handleUpload = (type) => (file) => {
    this.props.handleUpload(file, type);
  }

  toggleConfirmationModal = (type) => () => {
    this.props.toggleConfirmationModal(type);
  }

  render() {
    return (
      <div>
        <div className="image-upload">
          <div className="file">
            <h4>{translations.getLabel('titleAppBackgrouind')}</h4>
            <p>{translations.getLabel('lblAddAppBackground')}</p>
            <p className="small">{translations.getLabel('lblAppImageFormat')}</p>
            <FileUpload
              acceptFileTypes="image/png, image/jpg, image/jpeg"
              translationLabels={{
                lblDrag: translations.getLabel('lblDragDocument'),
                lblBtn: translations.getLabel('btnChooseDocument'),
              }}
              isLoading={this.props.isLoadingApp}
              showPreview={false}
              isModal={false}
              handleUpload={this.handleUpload(backgroundImages.app)}
            />
          </div>
          <div className="background" >
            <div className="mockup app-mockup">
              <div
                className="header"
                style={this.context.companyConfig && this.context.companyConfig.phoneHeaderImageUrl ?
                  { backgroundImage: `url(${urlUtils.addCacheBreaker(this.context.companyConfig.phoneHeaderImageUrl)}` } : { backgroundColor: this.context.companyConfig && this.context.companyConfig.primaryColor }}
              />
              <div className="mock-section" />
              <div className="mock-section" />
            </div>
            <Button className="remove" theme="transparent" onClick={this.toggleConfirmationModal(backgroundImages.app)} >{translations.getLabel('lblRemoveImage')}</Button>
          </div>
        </div>
      </div>
    );
  }
}

AppBackgroundTab.contextType = ConfigContext;

AppBackgroundTab.propTypes = {
  isLoadingApp: PropTypes.bool.isRequired,
  handleUpload: PropTypes.func.isRequired,
  toggleConfirmationModal: PropTypes.func.isRequired,
};
