import React, { FC, useEffect, useState } from 'react';
import { IconButton } from 'react-ess-components';
import Select from 'react-select';

import { IDepartmentProfile } from '../../../../../models';
import { companyAdminService } from '../../../../../services';

import './selfRosterProfileDepartment.scss';

type Props = {
  companyId: string,
  profile: IDepartmentProfile,
  getData: () => void;
  options: { value: string, label: string }[];
}

const SelfRosterDepartment: FC<Props> = ({ companyId, profile, getData, options }) => {
  const [selectedProfile, setSelectedProfile] = useState(null);

  useEffect(() => {
    setSelectedProfile({ value: profile?.companySelfRosterProfileId, label: profile?.companySelfRosterProfileName || '-' });
  }, [profile]);

  const handleSelect = async (companySelfRosterProfileId: string, optionLabel: string, departmentId: string,) => {
    await companyAdminService.updateRosterDepartmentProfile(companyId, departmentId, companySelfRosterProfileId);
    setSelectedProfile({ value: companySelfRosterProfileId, label: optionLabel });
    getData();
  };

  const deleteProfile = async (departmentId: string) => {
    await companyAdminService.deleteRosterDepartmentProfile(companyId, departmentId);
    setSelectedProfile(null);
    getData();
  };

  return (
    <>
      <div className="list-item" key={profile?.departmentId}>
        <div className="department-name">
          <p>{profile?.departmentName}</p>
        </div>
        <div className="select-container">
          <Select
            placeholder={'-'}
            components={{ IndicatorSeparator: () => null }}
            classNamePrefix="select"
            options={options}
            value={selectedProfile}
            onChange={(option) => handleSelect(option.value, option.label, profile.departmentId)}
          />
        </div>
        <div className="delete">
          <IconButton title="" tag="TrashIcon" color="error" onClick={() => deleteProfile(profile?.departmentId)} />
        </div>
      </div>
    </>);
};

export default SelfRosterDepartment;
