import React, { PureComponent } from 'react';
import { Button, FileUpload,Modal } from 'react-ess-components';
import PropTypes from 'prop-types';

import { storageItems } from '../../../../../constants';
import ConfigContext from '../../../../../context/ConfigContext';
import { configService,holdingAdminService } from '../../../../../services';
import { fileUploadUtils,translations, urlUtils } from '../../../../../utils';

import './BackgroundTab.scss';

export default class Background extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      downloadUrl: null,
      isLoading: false,
      isLoadingWebStartPage: false,
      isRemoveLoading: false,
      isConfirmationModalOpen: false,
    };
  }

  handleUpload = async (file) => {
    this.setState({ isLoading: true });
    const url = await holdingAdminService.getPresignedUrl(this.context.config.holdingId, 'webStartpageImageUrl');
    if (url) {
      await fileUploadUtils.uploadFileToPresignedUrl(url, file, 'image/png');
      const result = await configService.getHoldingConfig(localStorage.getItem(storageItems.HOLDING));
      if (result) {
        result.config.webStartpageImageUrl = urlUtils.addCacheBreaker(result.config.webStartpageImageUrl);
        this.context.updateConfig(result.config);
      }
    }
    this.setState({ isLoading: false });
  }

  onDelete = async () => {
    this.setState({ isRemoveLoading: true });
    await holdingAdminService.deleteImage(this.context.config.holdingId, 'webStartpageImageUrl');
    const result = await configService.getHoldingConfig(localStorage.getItem(storageItems.HOLDING));
    if (result) this.context.updateConfig(result.config);
    this.setState({ isRemoveLoading: false, isConfirmationModalOpen: false });
  }

  toggleConfirmationModal = () => this.setState({ isConfirmationModalOpen: !this.state.isConfirmationModalOpen });

  render() {
    return (
      <React.Fragment>
        <div className="background-container">
          <h4>{translations.getLabel('lblStartPage')}</h4>
          <p>{translations.getLabel('lblAddStartPageBackground')}</p>
          <p className="small">{translations.getLabel('lblImageFormat')}</p>
          <div className="image-upload">
            <div className="file">
              <FileUpload
                acceptFileTypes="image/png, image/jpg, image/jpeg"
                translationLabels={{
                  lblDrag: translations.getLabel('lblDragDocument'),
                  lblBtn: translations.getLabel('btnChooseDocument'),
                }}
                isLoading={this.state.isLoading}
                showPreview={false}
                isModal={false}
                handleUpload={this.handleUpload}
              />
            </div>
            <div className="background" >
              {
                <>
                  <div className="mockup">
                    <div className="mock-section mock-section-left" />
                    <div className="mock-section mock-section-right" style={this.context.config && this.context.config.webStartpageImageUrl ? { backgroundImage: `url(${this.context.config.webStartpageImageUrl})` } : { backgroundColor: this.context.config && this.context.config.backgroundColor }} />
                  </div>
                  <Button className="remove" theme="transparent" onClick={this.toggleConfirmationModal} >{translations.getLabel('lblRemoveImage')}</Button>
                </>
              }
            </div>
          </div>
        </div>
        <Modal
          requestClose={this.toggleConfirmationModal}
          open={this.state.isConfirmationModalOpen}
          rightButtonProps={{ label: translations.getLabel('lblDelete'), onClick: this.onDelete, isLoading: this.state.isRemoveLoading }}
          leftButtonProps={{ label: translations.getLabel('cancel'), onClick: this.toggleConfirmationModal }}
          title={translations.getLabel('titleDeleteImage')}
        >
          <p className="confirmation-message">{translations.getLabel('lblDeleteImage')}</p>
        </Modal>
      </React.Fragment>
    );
  }
}

Background.contextType = ConfigContext;

Background.propTypes = {
  holdingId: PropTypes.string.isRequired,
  match: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
};
