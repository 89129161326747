import React from 'react';
import { IconButton } from 'react-ess-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import './EditItem.scss';

const EditItem = (props) => {
  const onRemove = (e) => {
    e.stopPropagation();
    props.onClickRemove();
  };

  const onClick = (e) => {
    e.stopPropagation();
    props.onClick();
  };

  return (
    <div className={classNames('edit-item-container', { 'single-item': !props.noBorder })} onClick={onClick}>
      <div className="edit-row">
        <div>
          {props.title && <h4>{props.title}</h4>}
          {props.label && <p>{props.label}</p>}
        </div>
        {props.children}
      </div>
      <div className="buttons">
        <IconButton tag="EditIcon" color="primary" onClick={onClick} />
        {props.hasRemove && <IconButton className="remove" tag="TrashIcon" color="error" onClick={onRemove} />}
      </div>
    </div>
  );
};

EditItem.propTypes = {
  label: PropTypes.string,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onClickRemove: PropTypes.func,
  children: PropTypes.any,
  noBorder: PropTypes.bool,
  hasRemove: PropTypes.bool,
};

EditItem.defaultProps = {
  label: '',
  children: null,
  noBorder: false,
  onClickRemove: null,
  hasRemove: false,
};

export default EditItem;
