import React, { Fragment, PureComponent } from 'react';
import { IconButton, InputField, InputType,Modal } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import propTypes from 'prop-types';

import { companySettings } from '../../../../../constants';
import ConfigContext from '../../../../../context/ConfigContext';
import { superAdminService } from '../../../../../services';
import { translations } from '../../../../../utils';
import { EditItem, EditValue, Switch } from '../../../../index';

import './CompanyDetailsTab.scss';

const scheduleLevels = {
  PREFERENCES: 'PREFERENCES',
  CURRENT: 'CURRENT',
};
export default class CompanyDetailsTab extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      config: {
        approveOwnRequests: false,
        modules: {},
        showColleaguesSchedules: true,
        vacationTypes: [],
        vacationRequestScheduleLevel: null,
      },
      companyName: this.props.companyName,
      companyNameEdit: this.props.companyName,
      isEditing: {
        approveOwnRequests: false,
        deleteCompany: false,
        modules: false,
        showColleaguesSchedules: false,
        updateCompany: false,
      },
      isLoading: true,
      renderScheduleSetting: null,
    };
  }

  componentDidMount() {
    this.getCompanyConfig();
  }

  editCompany = async () => {
    await superAdminService.updateCompany(this.props.holdingId, this.props.companyId, this.state.companyNameEdit);
    this.getCompanyConfig();
    this.setState({
      isEditing: {
        ...this.state.isEditing,
        updateCompany: false,
      },
    });
  };

  deleteCompany = async () => {
    await superAdminService.deleteCompany(
      this.props.holdingId,
      this.props.companyId
    );
    this.props.goBack('reload');
  };


  isButtonDisable = setting => {
    return this.state.config[setting.tag] === this.context.companyConfig[setting.tag];
  }

  getCompanyConfig = async () => {
    const result = await superAdminService.getCompanyConfig(this.props.holdingId, this.props.companyId);
    // updateCompanyConfig is also used to store the company preferences as normal admin. As SA operates on a different 'instance' of the admin panel, there should be no overlap. If there is, the result's id can be used to prevent overwriting the context config.
    let config = {};
    if (result.config) {
      config = {
        approveOwnRequests: result.config.approveOwnRequests,
        modules: result.config.modules,
        showColleaguesSchedules: result.config.showColleaguesSchedules,
        vacationTypes: result.config.vacationTypes,
        vacationRequestScheduleLevel: result.config.vacationRequestScheduleLevel,
      };
      this.context.updateCompanyConfig(config);
    }

    this.setState({ config: config, isLoading: false, renderScheduleSetting: result.config.modules.schedule, companyName: result.name });
  }

  handleCompanyName = companyName => this.setState({ companyNameEdit: companyName });

  onCancel = setting => () => {
    this.setState({ config: { ...this.state.config, [setting.tag]: this.context.companyConfig[setting.tag] }, isEditing: { ...this.state.isEditing, [setting.tag]: !this.state.isEditing[setting.tag] } });
  }

  onSave = setting => async () => {
    this.setState({ isLoading: true });

    const result = await superAdminService.updateCompanyConfig(this.props.holdingId, this.props.companyId, this.state.config);

    if (result.config) this.context.updateCompanyConfig(result.config);

    this.setState({ isLoading: false, renderScheduleSetting: result.config.modules.schedule }, this.toggleModal(setting));
  }

  renderModal = (setting) => {
    return (
      <Modal
        open
        title={translations.getLabel(setting.labelTitle)}
        requestClose={this.onCancel(setting)}
        leftButtonProps={{
          label: translations.getLabel('cancel'),
          onClick: this.onCancel(setting),
        }}
        rightButtonProps={{
          label: translations.getLabel('save'),
          isLoading: this.state.isLoading,
          disabled: this.isButtonDisable(setting),
          onClick: this.onSave(setting),
        }}
      >
        <div className="settings-toggle-container" >
          <span className="settings-description">{translations.getLabel(setting.labelDescription)}</span>
          <Switch isChecked={this.state.config[setting.tag]} handleChange={this.toggleSetting(setting)} leftLabel={translations.getLabel('no')} rightLabel={translations.getLabel('yes')} />
        </div>
      </Modal>
    );
  }

  renderModulesModal = (setting) => {
    const { library, news, schedule, counters, timeRegistration, shiftOffer, shiftSwap, selfRoster } = setting.types;
    const { modules } = this.state.config;

    return (
      <Modal
        open
        title={translations.getLabel(setting.labelTitle)}
        requestClose={this.onCancel(setting)}
        leftButtonProps={{
          label: translations.getLabel('cancel'),
          onClick: this.onCancel(setting),
        }}
        rightButtonProps={{
          label: translations.getLabel('save'),
          isLoading: this.state.isLoading,
          disabled: this.isButtonDisable(setting),
          onClick: this.onSave(setting),
        }}
      >
        <div className="settings-container">
          <span className="settings-description-title">{translations.getLabel(companySettings.modules.labelDescription)}</span>
          <div className="settings-toggle-container">
            <p className="settings-description">{translations.getLabel(counters.labelTitle)}</p>
            <Switch isChecked={modules.counters} handleChange={this.toggleSetting(counters)} leftLabel={translations.getLabel('no')} rightLabel={translations.getLabel('yes')} />
          </div>
          <div className="settings-toggle-container">
            <p className="settings-description">{translations.getLabel(library.labelTitle)}</p>
            <Switch isChecked={modules.library} handleChange={this.toggleSetting(library)} leftLabel={translations.getLabel('no')} rightLabel={translations.getLabel('yes')} />
          </div>
          <div className="settings-toggle-container">
            <p className="settings-description">{translations.getLabel(news.labelTitle)}</p>
            <Switch isChecked={modules.news} handleChange={this.toggleSetting(news)} leftLabel={translations.getLabel('no')} rightLabel={translations.getLabel('yes')} />
          </div>
          <div className="settings-toggle-container">
            <p className="settings-description">{translations.getLabel(schedule.labelTitle)}</p>
            <Switch isChecked={modules.schedule} handleChange={this.toggleSetting(schedule)} leftLabel={translations.getLabel('no')} rightLabel={translations.getLabel('yes')} />
          </div>
          <div className="settings-toggle-container">
            <p className="settings-description">{translations.getLabel(selfRoster.labelTitle)}</p>
            <Switch isChecked={modules.selfRoster} handleChange={this.toggleSetting(selfRoster)} leftLabel={translations.getLabel('no')} rightLabel={translations.getLabel('yes')} />
          </div>
          <div className="settings-toggle-container">
            <p className="settings-description">{translations.getLabel(shiftOffer.labelTitle)}</p>
            <Switch isChecked={modules.shiftOffer} handleChange={this.toggleSetting(shiftOffer)} leftLabel={translations.getLabel('no')} rightLabel={translations.getLabel('yes')} />
          </div>
          <div className="settings-toggle-container">
            <p className="settings-description">{translations.getLabel(shiftSwap.labelTitle)}</p>
            <Switch isChecked={modules.shiftSwap} handleChange={this.toggleSetting(shiftSwap)} leftLabel={translations.getLabel('no')} rightLabel={translations.getLabel('yes')} />
          </div>
          <div className="settings-toggle-container">
            <p className="settings-description">{translations.getLabel(timeRegistration.labelTitle)}</p>
            <Switch isChecked={modules.timeRegistration} handleChange={this.toggleSetting(timeRegistration)} leftLabel={translations.getLabel('no')} rightLabel={translations.getLabel('yes')} />
          </div>
        </div>
      </Modal>
    );
  }

  renderLoading = key => {
    return <div className="company-body" key={key}>
      <Skeleton width="100%" count={10} />
    </div>;
  }

  toggleModal = (setting) => () => {
    this.setState({ isEditing: { ...this.state.isEditing, [setting.tag]: !this.state.isEditing[setting.tag] } });
  }

  toggleSetting = (setting) => () => {
    if (Object.keys(this.state.config.modules).includes(setting.tag)) {
      this.setState({
        config: {
          ...this.state.config,
          modules: { ...this.state.config.modules, [setting.tag]: !this.state.config.modules[setting.tag] },
        },
      });
    } else {
      this.setState({ config: { ...this.state.config, [setting.tag]: !this.state.config[setting.tag] } });
    }
  }

  setScheduleLevel = (key, value) => {
    this.setState({ config: { ...this.state.config, [key]: value } });
  }

  getLevelItem = () => {
    if (this.state.isEditing.vacationRequestScheduleLevel) return 'vacationRequestScheduleLevel';
    return '';
  }

  render() {
    const { config, isLoading, isEditing } = this.state;

    const modulesArray = Object.keys(config.modules).filter(module => Object.keys(companySettings.modules.types).includes(module)).sort();

    const scheduleLevelItem = this.getLevelItem();

    return (
      <div className="company-details-tab">
        <div className="company-heading">
          <div className="navigation-back">
            <IconButton
              color="primary"
              tag="ArrowLeft"
              onClick={this.props.goBack}
            />
          </div>
          <div className="company-details">
            <p>{this.state.companyName}</p>
            <p>{this.props.companyId}</p>
          </div>
          <div className="company-control-icons">
            <IconButton
              onClick={this.toggleModal(companySettings.updateCompany)}
              className="company-control-icon"
              color="primary"
              tag="EditIcon"
            />
            <IconButton
              onClick={this.toggleModal(companySettings.deleteCompany)}
              className="company-control-icon"
              color="error"
              tag="TrashIcon"
            />
          </div>
        </div>
        <div className="company-body">
          {isLoading ? [1, 2].map(this.renderLoading) :
            <Fragment>
              <EditItem
                onClick={this.toggleModal(companySettings.modules)}
                title={translations.getLabel(companySettings.modules.labelTitle)}
              >
                <EditValue
                  title={translations.getLabel(
                    companySettings.modules.labelDescription
                  )}
                />
                {modulesArray.map(setting => {
                  return (
                    <EditValue
                      key={setting}
                      rightSpacing
                      title={translations.getLabel(
                        companySettings.modules.types[setting].labelTitle
                      )}
                      value={this.context.companyConfig?.modules[setting] ? translations.getLabel('yes') : translations.getLabel('no')}
                    />
                  );
                })}
              </EditItem>
              {this.state.renderScheduleSetting && !this.context.companyConfig?.modules?.shiftSwap && <EditItem
                onClick={this.toggleModal(companySettings.showSchedule)}
                title={translations.getLabel(companySettings.showSchedule.labelTitle)}
              >
                <EditValue
                  title={translations.getLabel(
                    companySettings.showSchedule.labelDescription
                  )}
                  value={this.context.companyConfig?.showColleaguesSchedules ? translations.getLabel('yes') : translations.getLabel('no')}
                />
              </EditItem>}
              <EditItem
                onClick={this.toggleModal(companySettings.requestApproval)}
                title={translations.getLabel(companySettings.requestApproval.labelTitle)}
              >
                <EditValue
                  title={translations.getLabel(
                    companySettings.requestApproval.labelDescription
                  )}
                  value={this.context.companyConfig?.approveOwnRequests ? translations.getLabel('yes') : translations.getLabel('no')}
                />
              </EditItem>
              <EditItem
                onClick={this.toggleModal(companySettings.vacationRequestScheduleLevel)}
                title={translations.getLabel(companySettings.vacationRequestScheduleLevel.labelTitle)}
              >
                <EditValue
                  title={translations.getLabel(
                    companySettings.vacationRequestScheduleLevel.labelDescription
                  )}
                  value={this.context.companyConfig.vacationRequestScheduleLevel === scheduleLevels.CURRENT ? translations.getLabel('lblCurrentLine') : translations.getLabel('lblWishedLine')}
                />
              </EditItem>
            </Fragment>
          }
        </div>
        {isEditing.approveOwnRequests && this.renderModal(companySettings.requestApproval)}
        {isEditing.showColleaguesSchedules && this.renderModal(companySettings.showSchedule)}
        {isEditing.modules && this.renderModulesModal(companySettings.modules)}
        <Modal
          open={isEditing.deleteCompany}
          children={
            <div className="warning-message">
              {translations.getLabel(companySettings.deleteCompany.label)}
            </div>
          }
          title={translations.getLabel('lblDeleteCompany')}
          requestClose={this.toggleModal(companySettings.deleteCompany)}
          leftButtonProps={{
            label: translations.getLabel('cancel'),
            onClick: this.toggleModal(companySettings.deleteCompany),
          }}
          rightButtonProps={{
            label: translations.getLabel('lblDelete'),
            onClick: this.deleteCompany,
          }}
        />
        <Modal
          open={isEditing.updateCompany}
          title={translations.getLabel(companySettings.updateCompany.label)}
          requestClose={this.toggleModal(companySettings.updateCompany)}
          leftButtonProps={{
            label: translations.getLabel('cancel'),
            onClick: this.toggleModal(companySettings.updateCompany),
          }}
          rightButtonProps={{
            label: translations.getLabel('save'),
            onClick: this.editCompany,
            disabled: this.state.companyNameEdit.length === 0 || this.state.companyNameEdit === this.props.companyName,
          }}
        >
          <div className="update-modal">
            <InputField type={InputType.Text} emptyIcon label={translations.getLabel('lblCompanyName')} onChange={this.handleCompanyName} value={this.state.companyNameEdit} />
          </div>
        </Modal>
        <Modal
          open={isEditing.vacationRequestScheduleLevel}
          title={translations.getLabel(companySettings[scheduleLevelItem]?.labelTitle)}
          requestClose={this.toggleModal(companySettings[scheduleLevelItem])}
          leftButtonProps={{
            label: translations.getLabel('cancel'),
            onClick: this.toggleModal(companySettings[scheduleLevelItem]),
          }}
          rightButtonProps={{
            label: translations.getLabel('save'),
            onClick: this.onSave(companySettings[scheduleLevelItem]),
          }}
        >
          <div className="update-modal">
            <p>{translations.getLabel('lblScheduleLevelDescription')}</p>
            <label htmlFor={scheduleLevels.CURRENT}>
              <input type="radio" onChange={() => this.setScheduleLevel(companySettings[scheduleLevelItem].tag, scheduleLevels.CURRENT)} id={scheduleLevels.CURRENT} checked={this.state.config[scheduleLevelItem] === scheduleLevels.CURRENT} /> {translations.getLabel('lblCurrentLine')}
            </label>
            <label htmlFor={scheduleLevels.PREFERENCES}>
              <input type="radio" onChange={() => this.setScheduleLevel(companySettings[scheduleLevelItem].tag, scheduleLevels.PREFERENCES)} id={scheduleLevels.PREFERENCES} checked={this.state.config[scheduleLevelItem] === scheduleLevels.PREFERENCES} /> {translations.getLabel('lblWishedLine')}
            </label>
          </div>
        </Modal>
      </div>
    );
  }
}

CompanyDetailsTab.contextType = ConfigContext;

CompanyDetailsTab.propTypes = {
  holdingId: propTypes.string.isRequired,
  companyId: propTypes.string.isRequired,
  companyName: propTypes.string.isRequired,
  goBack: propTypes.func.isRequired,
};
