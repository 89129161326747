import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import ConfigContext from '../../../../../context/ConfigContext';
import LoginSettings from './components/LoginSettings';
import SpeakapSettings from './components/SpeakapSettings';

import './loginTab.scss';

const LoginTab = ({ holdingId }) => {
  const context = useContext(ConfigContext);
  const holding = context.holdings.find(holding => holding.id === holdingId);

  return (
    <div className="login-tab-container">
      <LoginSettings holdingId={holdingId} holding={holding} />
      <SpeakapSettings holdingId={holdingId} holding={holding} />
    </div>
  );
};

LoginTab.propTypes = {
  holdingId: PropTypes.string.isRequired,
};

export default LoginTab;
