import React, { PureComponent } from 'react';
import { IconButton, InputField, InputType } from 'react-ess-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { translations } from '../../../../../../utils';

import './Counter.scss';

export default class Counter extends PureComponent {
  render() {
    const className = classNames('counter', { 'counter-group': this.props.isGroup });

    return (
      <div className={className}>
        <div className="title">
          {
            this.props.isEditing
              ?
              <InputField type={InputType.Text} emptyIcon value={this.props.title} placeholder={translations.getLabel('lblName')} onChange={this.props.onChangeTitle} />
              :
              <h3>{this.props.title}</h3>
          }
          <IconButton disabled={this.props.isEditDisabled} tag={this.props.isEditing ? 'TrashIcon' : 'EditIcon'} color={this.props.isEditing ? 'error' : (this.props.isEditDisabled ? 'primaryTransparent' : 'primary')} onClick={this.props.isEditing ? this.props.onRemoveGroup : this.props.onEdit} />
        </div>
        <div className="counters">
          {this.props.children}
        </div>
      </div>
    );
  }
}
const noop = () => { };
Counter.propTypes = {
  title: PropTypes.string,
  onEdit: PropTypes.func.isRequired,
  onRemoveGroup: PropTypes.func.isRequired,
  onChangeTitle: PropTypes.func,
  children: PropTypes.any.isRequired,
  isGroup: PropTypes.bool,
  isEditing: PropTypes.bool,
  isEditDisabled: PropTypes.bool,
};

Counter.defaultProps = {
  isGroup: false,
  isEditing: false,
  isEditDisabled: false,
  onChangeTitle: noop,
  onRemoveGroup: noop,
  title: '',
};
