import React, { useEffect,useState } from 'react';
import { Button, Icon, IconButton, InputField, InputType,Modal } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import { timeRegistrationType, timeRegistrationTypes as timeRegistrationOptions } from '../../../../../constants';
import { useForm, useToggle } from '../../../../../hooks';
import { superAdminService } from '../../../../../services';
import { translations } from '../../../../../utils';
import Switch from '../../../../switch/Switch';

import './TimeRegistrationTypesTab.scss';

let didCancel = false;

const TimeRegistrationTab = ({ holdingId }) => {
  const [isModalOpen, toggleIsModalOpen] = useToggle(false);
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(false);
  const [timeRegistrationTypes, setTimeRegistrationTypes] = useState([]);
  const [isConfirmationModalOpen, toggleConfirmationModalOpen] = useToggle(false);
  const [activeId, setActiveId] = useState('');
  const { form, setForm, setFormAttribute } = useForm({ type: timeRegistrationOptions[0].key, isPauze: false });

  useEffect(() => {
    setIsInitialLoading(true);
    didCancel = false;
    getData();

    return () => {
      didCancel = true;
    };
  }, [holdingId]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (form.generalDescription && form.registrationCodeStart && ((form.registrationCodeEnd && form.type !== timeRegistrationType.Informative) || form.type === timeRegistrationType.Informative)) setIsValid(true);
    else setIsValid(false);
  }, [form]);

  useEffect(() => {
    if (!isModalOpen) {
      setActiveId('');
      setForm({ type: timeRegistrationOptions[0].key, isPauze: false });
      setIsValid(false);
    }
  }, [isModalOpen]); //eslint-disable-line react-hooks/exhaustive-deps

  const getData = (async () => {
    const data = await superAdminService.getTimeRegistrationTypes(holdingId);
    if (!didCancel && data) {
      setTimeRegistrationTypes(data);
      setIsInitialLoading(false);
    }
  });

  const save = async () => {
    let saveResult = null;
    setIsLoading(true);
    if (!activeId) {
      saveResult = await superAdminService.addTimeRegistrationType(holdingId, form);
    } else {
      saveResult = await superAdminService.updateTimeRegistrationType(holdingId, activeId, form);
    }
    if (!didCancel) {
      if (saveResult) {
        toast(translations.getLabel('lblGenericSuccessMessage'));
        getData();
      }
      setActiveId('');
      setForm({ type: timeRegistrationOptions[0].key });
      toggleIsModalOpen();
      setIsLoading(false);
    }
  };

  const setEdit = (id) => {
    const activeType = timeRegistrationTypes.find(type => type.id === id);
    setActiveId(id);
    if (activeType) {
      setForm({
        type: activeType.type,
        registrationCodeStart: activeType.registrationCodeStart,
        registrationCodeEnd: activeType.registrationCodeEnd,
        generalDescription: activeType.generalDescription,
        descriptionNl: activeType.descriptionNl,
        descriptionFr: activeType.descriptionFr,
        descriptionEn: activeType.descriptionEn,
        descriptionDe: activeType.descriptionDe,
        isPauze: activeType.isPauze,
      });
    }
    toggleIsModalOpen();
  };

  const onDelete = (id) => {
    toggleConfirmationModalOpen();
    setActiveId(id);
  };

  const removeType = async () => {
    setIsLoading(true);
    await superAdminService.deleteTimeRegistrationType(holdingId, activeId);
    if (!didCancel) {
      setActiveId('');
      getData();
      toggleConfirmationModalOpen();
      setIsLoading(false);
    }
  };

  const setType = (type) => {
    setFormAttribute(type, 'type');
  };

  const renderRow = (registrationType) => {
    return (
      <tr key={registrationType.id}>
        <td>{registrationType.generalDescription}</td>
        <td>{registrationType.type}</td>
        <td>{registrationType.registrationCodeStart}</td>
        <td>{registrationType.registrationCodeEnd}</td>
        <td className="icon-cell">
          <IconButton tag="EditIcon" color="primary" onClick={() => setEdit(registrationType.id)} />
        </td>
        <td className="icon-cell">
          <IconButton tag="TrashIcon" color="error" onClick={() => onDelete(registrationType.id)} />
        </td>
      </tr>
    );
  };

  return (
    <div className="counters-tab time-registration-tab">
      <h4>{translations.getLabel('titelTimeRegistration')}</h4>
      <p className="label">{translations.getLabel('lblConfigureTimeRegistration')}</p>

      {isInitialLoading && <div className="loader"><Skeleton height={30} /></div>}
      {
        !isInitialLoading && (timeRegistrationTypes.length > 0 ?
          <div>
            <table className="table" >
              <tbody>
                <tr>
                  <th>{translations.getLabel('lblGeneralDescription')}</th>
                  <th>{translations.getLabel('lblType')}</th>
                  <th>{translations.getLabel('lblRegistrationCodeStartAbbr')}</th>
                  <th>{translations.getLabel('lblRegistrationCodeEndAbbr')}</th>
                  <th />
                  <th />
                </tr>
                {
                  timeRegistrationTypes.map(renderRow)
                }
              </tbody>
            </table>
          </div>
          :
          <p className="empty-types">{translations.getLabel('lblEmptyTimeRegistrationTypes')}</p>
        )
      }

      <Button theme="transparent" onClick={toggleIsModalOpen}>
        <Icon tag="PlusSquareIcon" color="primary" />
        <p className="button-label">{translations.getLabel('btnAddTimeRegistrationType')}</p>
      </Button>
      <Modal
        open={isModalOpen}
        title={translations.getLabel('titelAddTimeRegistrationType')}
        requestClose={toggleIsModalOpen}
        leftButtonProps={{
          label: translations.getLabel('cancel'),
          onClick: toggleIsModalOpen,
        }}
        rightButtonProps={{
          label: translations.getLabel('save'),
          isLoading,
          disabled: !isValid,
          onClick: save,
        }}
      >
        <div className="form">
          <div className="form-row">
            <div className="input-container">
              <p>{translations.getLabel('lblType')}</p>
              <InputField type={InputType.Select} value={form.type} emptyIcon onChange={setType} options={timeRegistrationOptions.map(option => ({ value: option.key, label: translations.getLabel(option.label) }))} />
            </div>
            <div className="input-container input-switch">
              {form.type === timeRegistrationType.Time && <>
                <p>{translations.getLabel('lblRegistrationTypePauze')}</p>
                <Switch isChecked={!!form.isPauze} handleChange={() => setFormAttribute(!form.isPauze, 'isPauze')} leftLabel={translations.getLabel('no')} rightLabel={translations.getLabel('yes')} />
              </>}
            </div>
          </div>

          <div className="form-row">
            <div className="input-container">
              <p>{translations.getLabel('lblGeneralDescription')}</p>
              <InputField type={InputType.Text} value={form.generalDescription || ''} emptyIcon name="generalDescription" onChange={setFormAttribute} />
            </div>
            <div className="input-container" />
          </div>

          <div className="form-row">
            <div className="input-container">
              <p>{translations.getLabel('lblDescriptionNL')}</p>
              <InputField type={InputType.Text} value={form.descriptionNl || ''} emptyIcon name="descriptionNl" onChange={setFormAttribute} />
            </div>
            <div className="input-container">
              <p>{translations.getLabel('lblDescriptionFR')}</p>
              <InputField type={InputType.Text} value={form.descriptionFr || ''} emptyIcon name="descriptionFr" onChange={setFormAttribute} />
            </div>
          </div>

          <div className="form-row">
            <div className="input-container">
              <p>{translations.getLabel('lblDescriptionEN')}</p>
              <InputField type={InputType.Text} value={form.descriptionEn || ''} emptyIcon name="descriptionEn" onChange={setFormAttribute} />
            </div>
            <div className="input-container">
              <p>{translations.getLabel('lblDescriptionDE')}</p>
              <InputField type={InputType.Text} value={form.descriptionDe || ''} emptyIcon name="descriptionDe" onChange={setFormAttribute} />
            </div>
          </div>

          <div className="form-row">
            <div className="input-container">
              <p>{translations.getLabel('lblRegistrationCodeStart')}</p>
              <InputField type={InputType.Text} value={form.registrationCodeStart || ''} emptyIcon name="registrationCodeStart" onChange={setFormAttribute} />
            </div>
            <div className="input-container">
              {form.type !== timeRegistrationType.Informative && <>
                <p>{translations.getLabel('lblRegistrationCodeEnd')}</p>
                <InputField type={InputType.Text} value={form.registrationCodeEnd || ''} emptyIcon name="registrationCodeEnd" onChange={setFormAttribute} />
              </>}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        requestClose={toggleConfirmationModalOpen}
        open={isConfirmationModalOpen}
        rightButtonProps={{ label: translations.getLabel('lblDelete'), onClick: removeType, isLoading }}
        leftButtonProps={{ label: translations.getLabel('cancel'), onClick: toggleConfirmationModalOpen }}
        title={translations.getLabel('titleDeleteTimeRegistration')}
      >
        <p className="confirmation-message">{translations.getLabel('lblDeleteTimeRegistration')}</p>
      </Modal>
    </div >
  );
};

TimeRegistrationTab.propTypes = {
  holdingId: PropTypes.string.isRequired,
};

export default TimeRegistrationTab;
