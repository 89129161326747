import React, { useEffect, useState } from 'react';
import { Button, Icon, IconButton, InputField, InputType,Modal } from 'react-ess-components';
import { toast } from 'react-toastify';
import { format } from 'date-fns';
import PropTypes from 'prop-types';

import { timeRegistrationCheckTypes, timeRegistrationTypes as timeRegistrationTypesConstants } from '../../../../../../constants';
import { useToggle } from '../../../../../../hooks';
import { holdingAdminService } from '../../../../../../services';
import { translations } from '../../../../../../utils';
import TimeRegistrationProfileWizard from './TimeRegistrationProfileWizard';

let didCancel = false;

function TimeRegistrationProfile({ timeRegistrationTypes, holdingId, companyId, profile, setActiveId, isActive, getData }) {
  const [isModalOpen, toggleIsModalOpen] = useToggle(false);
  const [types, setTypes] = useState(profile?.timeRegistrationTypes || []);
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmationModalOpen, toggleConfirmationModalOpen] = useToggle(false);
  const [isTypeConfirmationModalOpen, toggleTypeConfirmationModalOpen] = useToggle(false);
  const [isTypeCancelModalOpen, toggleTypeCancelModalOpen] = useToggle(false);
  const [activeInt, setActiveInt] = useState();
  const [hasChanged, setHasChanged] = useState(false);
  const [name, setName] = useState(profile?.name);

  useEffect(() => {
    if (name && types.length) setIsValid(true);
    else setIsValid(false);
    if (name !== profile?.name || types.length !== profile?.timeRegistrationTypes.length) setHasChanged(true);
  }, [name, types]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    didCancel = false;
    return () => {
      didCancel = true;
    };
  }, [isActive]);

  useEffect(() => {
    if (!isTypeConfirmationModalOpen && !isModalOpen) setActiveInt();
  }, [isTypeConfirmationModalOpen, isModalOpen]);

  const onDeleteType = (i) => {
    setActiveInt(i);
    toggleTypeConfirmationModalOpen();
  };

  const deleteType = () => {
    const newTypes = [...types];
    newTypes.splice(activeInt, 1);
    setTypes(newTypes);
    setHasChanged(true);
    toggleTypeConfirmationModalOpen();
  };

  const onEditType = (i) => {
    setActiveInt(i);
    toggleIsModalOpen();
  };

  const renderRow = (registrationType, i) => {
    const backupRegistrationType = timeRegistrationTypes.find(type => type.id === registrationType.typeId);
    return (
      <tr key={`${registrationType.typeId || registrationType.timeRegistrationTypeId}${i}`}>
        <td>{registrationType.generalDescription || backupRegistrationType.generalDescription}</td>
        <td>{format(new Date(registrationType.startDate), ('dd/MM/yyyy'))}</td>
        <td>{format(new Date(registrationType.endDate), ('dd/MM/yyyy'))}</td>
        <td>{translations.getLabel(timeRegistrationCheckTypes.find(type => type.key === registrationType.controlStart)?.label)}</td>
        <td>{(registrationType.type || backupRegistrationType.type) !== timeRegistrationTypesConstants[1].key && translations.getLabel(timeRegistrationCheckTypes.find(type => type.key === registrationType.controlEnd)?.label)}</td>
        {
          isActive &&
          <>
            <td className="icon-cell">
              <IconButton tag="EditIcon" color="primary" onClick={() => onEditType(i)} />
            </td>
            <td className="icon-cell">
              <IconButton tag="TrashIcon" color="error" onClick={() => onDeleteType(i)} />
            </td>
          </>
        }
      </tr>
    );
  };

  const onCancel = () => {
    if (!hasChanged) cancel();
    else toggleTypeCancelModalOpen();
  };

  const cancel = () => {
    if (hasChanged) toggleTypeCancelModalOpen();
    setTypes(profile?.timeRegistrationTypes || []);
    setActiveId('');
    setHasChanged(false);
  };

  const onSave = async () => {
    setIsLoading(true);
    if (profile) {
      const parsedTypes = types.map(type => {
        return {
          id: type.id || null,
          typeId: type.typeId || type.timeRegistrationTypeId,
          controlStart: type.controlStart,
          controlEnd: type.controlEnd,
          startDate: type.startDate,
          endDate: type.endDate,
        };
      });
      await holdingAdminService.updateTimeRegistrationProfile(holdingId, companyId, profile.id, { name });
      await holdingAdminService.updateTimeRegistrationTypes(holdingId, companyId, profile.id, parsedTypes);
    } else {
      const savedProfile = await holdingAdminService.addTimeRegistrationProfile(holdingId, companyId, { name });
      if (savedProfile) await holdingAdminService.updateTimeRegistrationTypes(holdingId, companyId, savedProfile.id, types);
    }
    if (!didCancel) {
      setActiveId('');
      getData();
      setIsLoading(false);
      setHasChanged(false);
    }
  };

  const onSaveType = (type) => {
    if (activeInt || activeInt === 0) {
      const newTypes = [...types];
      newTypes[activeInt] = type;
      setTypes(newTypes);
      setActiveInt();
    } else {
      setTypes([...types, type]);
    }
    setHasChanged(true);
  };

  const onDeleteProfile = async () => {
    setIsLoading(true);
    await holdingAdminService.deleteTimeRegistrationProfile(holdingId, companyId, profile.id);
    if (!didCancel) {
      toast(translations.getLabel('lblGenericSuccessMessage'));
      getData();
      setActiveId('');
      setIsLoading(false);
      setHasChanged(false);
    }
  };

  return (
    <div className="profile">
      {
        isActive ?
          <div className="title">
            <InputField type={InputType.Text} value={name} emptyIcon onChange={setName} />
            <IconButton tag="TrashIcon" color="error" onClick={profile ? toggleConfirmationModalOpen : onCancel} />
          </div>
          :
          <div className="title">
            <h3>{profile.name}</h3>
            <IconButton tag="EditIcon" color="primary" onClick={() => setActiveId(profile.id)} />
          </div>
      }
      <div className="table-container">
        {
          (types.length > 0) &&
          <table className="table" >
            <tbody>
              <tr>
                <th>{translations.getLabel('lblRegistrationType')}</th>
                <th>{translations.getLabel('lblFrom')}</th>
                <th>{translations.getLabel('lblUntil')}</th>
                <th>{translations.getLabel('lblControlStart')}</th>
                <th>{translations.getLabel('lblControlEnd')}</th>
                {
                  isActive &&
                  <>
                    <th />
                    <th />
                  </>
                }
              </tr>
              {
                types.map(renderRow)
              }
            </tbody>
          </table>
        }
        {
          isActive &&
          <Button theme="transparent" onClick={toggleIsModalOpen}>
            <Icon tag="PlusSquareIcon" color="primary" />
            <p className="button-label">{translations.getLabel('btnAddTimeRegistrationType')}</p>
          </Button>
        }
      </div>
      {
        isActive &&
        <div className="button-container">
          <Button theme="inverse" onClick={onCancel}>{translations.getLabel('cancel')}</Button>
          <Button isLoading={isLoading} onClick={onSave} disabled={!isValid || !hasChanged}>{translations.getLabel('save')}</Button>
        </div>
      }

      <TimeRegistrationProfileWizard timeRegistrationType={types[activeInt]} timeRegistrationTypes={timeRegistrationTypes} save={onSaveType} isModalOpen={isModalOpen} toggleIsModalOpen={toggleIsModalOpen} />
      <Modal
        requestClose={toggleConfirmationModalOpen}
        open={isConfirmationModalOpen}
        rightButtonProps={{ label: translations.getLabel('lblDelete'), onClick: onDeleteProfile, isLoading }}
        leftButtonProps={{ label: translations.getLabel('cancel'), onClick: toggleConfirmationModalOpen }}
        title={translations.getLabel('lblRemoveProfile')}
      >
        <p className="confirmation-message">{translations.getLabel('lblConfirmationProfile')}</p>
      </Modal>
      <Modal
        requestClose={toggleTypeConfirmationModalOpen}
        open={isTypeConfirmationModalOpen}
        rightButtonProps={{ label: translations.getLabel('lblDelete'), onClick: deleteType, isLoading }}
        leftButtonProps={{ label: translations.getLabel('cancel'), onClick: toggleTypeConfirmationModalOpen }}
        title={translations.getLabel('titleDeleteTimeRegistration')}
      >
        <p className="confirmation-message">{translations.getLabel('lblDeleteTimeRegistration')}</p>
      </Modal>
      <Modal
        requestClose={toggleTypeCancelModalOpen}
        open={isTypeCancelModalOpen}
        rightButtonProps={{ label: translations.getLabel('yes'), onClick: cancel, isLoading }}
        leftButtonProps={{ label: translations.getLabel('btnBack'), onClick: toggleTypeCancelModalOpen }}
        title={translations.getLabel('cancel')}
      >
        <p className="confirmation-message">{translations.getLabel('lblConfirmCancel')}</p>
      </Modal>
    </div>
  );
}


TimeRegistrationProfile.propTypes = {
  timeRegistrationTypes: PropTypes.array.isRequired,
  holdingId: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  profile: PropTypes.object,
  setActiveId: PropTypes.func.isRequired,
  getData: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
};

TimeRegistrationProfile.defaultProps = {
  profile: null,
  isActive: false,
};

export default TimeRegistrationProfile;
