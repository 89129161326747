import React, { PureComponent } from 'react';
import { Button,FileUpload } from 'react-ess-components';
import PropTypes from 'prop-types';

import { backgroundImages } from '../../../../../../constants';
import ConfigContext from '../../../../../../context/ConfigContext';
import { translations, urlUtils } from '../../../../../../utils';

export default class WebBackgroundTab extends PureComponent {
  handleUpload = (type) => (file) => {
    this.props.handleUpload(file, type);
  }

  toggleConfirmationModal = (type) => () => {
    this.props.toggleConfirmationModal(type);
  }

  render() {
    return (
      <div>
        <h4>{translations.getLabel('lblGeneral')}</h4>
        <p>{translations.getLabel('lblAddBackground')}</p>
        <p className="small">{translations.getLabel('lblImageFormat')}</p>
        <div className="image-upload">
          <div className="file">
            <FileUpload
              acceptFileTypes="image/png, image/jpg, image/jpeg"
              translationLabels={{
                lblDrag: translations.getLabel('lblDragDocument'),
                lblBtn: translations.getLabel('btnChooseDocument'),
              }}
              isLoading={this.props.isLoadingWeb}
              showPreview={false}
              isModal={false}
              handleUpload={this.handleUpload(backgroundImages.web)}
            />
          </div>
          <div className="background" >
            {
              <React.Fragment>
                <div className="mockup dashboard-mockup" style={this.context.companyConfig && this.context.companyConfig.webBackgroundUrl ?
                  { backgroundImage: `url(${urlUtils.addCacheBreaker(this.context.companyConfig.webBackgroundUrl)})` } : { backgroundColor: this.context.companyConfig && this.context.companyConfig.primaryColor }}>
                  <div className="header" style={{ backgroundColor: this.context.companyConfig && this.context.companyConfig.headerColor }} />
                  <div className="mock-section" />
                  <div className="mock-section" />
                </div>
                <Button className="remove" theme="transparent" onClick={this.toggleConfirmationModal(backgroundImages.web)} >{translations.getLabel('lblRemoveImage')}</Button>
              </React.Fragment>
            }
          </div>
        </div>
      </div>
    );
  }
}

WebBackgroundTab.contextType = ConfigContext;

WebBackgroundTab.propTypes = {
  isLoadingWeb: PropTypes.bool.isRequired,
  isLoadingWebStartPage: PropTypes.bool.isRequired,
  handleUpload: PropTypes.func.isRequired,
  toggleConfirmationModal: PropTypes.func.isRequired,
};
