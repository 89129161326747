import { Network } from '../utils';

export const setURL = async (id, url) => {
  const result = await Network.put(`/v1/management/superuser/holdings/${id}`, { url });
  if (result) return result.data;
};

export const updateConfig = async (id, data) => {
  const result = await Network.patch(`/v1/management/superuser/holdings/${id}/config`, data);
  if (result) return result.data;
};

export const getConfig = async (id) => {
  const result = await Network.get(`/v1/management/superuser/holdings/${id}/config`);
  if (result) return result.data;
};

export const getHoldingConfig = async (holding) => {
  const result = await Network.get('/v1/holding/preferences', null, { url: holding });
  return { config: { ...(result ? result.data : {}), name: holding } };
};

export const getMe = async () => {
  const result = await Network.get('/v1/me');
  return { me: { ...(result ? result.data : {}) } };
};
