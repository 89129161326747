import React, { useContext,useEffect, useState } from 'react';
import { Modal } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

import ConfigContext from '../../../../../../context/ConfigContext';
import { useToggle } from '../../../../../../hooks';
import { holdingAdminService } from '../../../../../../services';
import { translations } from '../../../../../../utils';
import EditItem from '../../../../../editItem/EditItem';
import Switch from '../../../../../switch/Switch';

const CrossDepartmentShiftOffers = (props) => {
  const context = useContext(ConfigContext);
  const [isCrossDepartmentShiftOffersModalOpen, toggleCrossDepartmentShiftOffersModalOpen] = useToggle();
  const [enableCrossDepartmentsShiftOffers, setEnableCrossDepartmentsShiftOffers] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInitialLoading, setInitialLoading] = useState(true);

  useEffect(() => {
    if (Object.keys(context.companyConfig).length > 0) {
      setEnableCrossDepartmentsShiftOffers(context.companyConfig.enableCrossDepartmentsShiftOffers);
      setInitialLoading(false);
    }
  }, [props.companyId, context.companyConfig]);


  const onSave = async () => {
    setIsLoading(true);
    const result = await holdingAdminService.updateCompanyConfig(props.holdingId, props.companyId, { enableCrossDepartmentsShiftOffers: enableCrossDepartmentsShiftOffers });
    if (result) {
      context.updateCompanyConfig(result.config);
      toggleCrossDepartmentShiftOffersModalOpen();
    }
    setIsLoading(false);
  };

  const cancelModal = () => {
    setEnableCrossDepartmentsShiftOffers(context.companyConfig.enableCrossDepartmentsShiftOffers);
    toggleCrossDepartmentShiftOffersModalOpen();
  };

  return (
    <>
      <EditItem title={translations.getLabel('titleCrossDepartmentShiftOffers')} label={translations.getLabel('lblCrossDepartmentShiftOffersCompany')} onClick={toggleCrossDepartmentShiftOffersModalOpen}>
        <p className="weekday-value">{isInitialLoading ? <Skeleton width={150} /> : translations.getLabel(context.companyConfig.enableCrossDepartmentsShiftOffers ? 'yes' : 'no')}</p>
      </EditItem>
      <Modal
        title={translations.getLabel('titleCrossDepartmentShiftOffers')}
        open={isCrossDepartmentShiftOffersModalOpen}
        requestClose={cancelModal}
        rightButtonProps={{ label: translations.getLabel('save'), onClick: onSave, isLoading: isLoading }}
        leftButtonProps={{ label: translations.getLabel('cancel'), onClick: cancelModal }}
      >
        <div className="popup-schedule-config-container">
          <p>{translations.getLabel('lblCrossDepartmentShiftOffersCompany')}</p>
          <Switch isChecked={enableCrossDepartmentsShiftOffers} handleChange={(value) => setEnableCrossDepartmentsShiftOffers(value)} leftLabel={translations.getLabel('no')} rightLabel={translations.getLabel('yes')} />
        </div>
      </Modal>
    </>
  );
};

CrossDepartmentShiftOffers.propTypes = {
  companyId: PropTypes.string.isRequired,
  holdingId: PropTypes.string.isRequired,
};

export default CrossDepartmentShiftOffers;
