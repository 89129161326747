export const vacationTypeTypes = [{
  label: 'lblInterval',
  value: 'INTERVAL',
}, {
  label: 'lblShift',
  value: 'SHIFT',
}];

export const descriptionForLanguage = {
  nl_BE: 'descriptionNl',
  en_US: 'descriptionEn',
  fr_FR: 'descriptionFr',
  de_DE: 'descriptionDe',
};
