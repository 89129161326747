import React, { useContext,useEffect, useState } from 'react';
import { InputField, InputType,Modal } from 'react-ess-components';
import { toast } from 'react-toastify';
import PropTypes from 'prop-types';

import ConfigContext from '../../../../../../context/ConfigContext';
import { useForm,useToggle } from '../../../../../../hooks';
import { superAdminService } from '../../../../../../services';
import { translations } from '../../../../../../utils';
import EditItem from '../../../../../editItem/EditItem';
import EditValue from '../../../../../editValue/EditValue';
import Switch from '../../../../../switch/Switch';

import '../loginTab.scss';

let didCancel = false;

const LoginSettings = ({ holdingId, holding }) => {
  const context = useContext(ConfigContext);
  const [isSaving, setIsSaving] = useState(false);
  const [isModalOpen, toggleModal] = useToggle(false);
  const [isRegularLoginEnabled, toggleRegularLogin] = useToggle(!!holding.isRegularLoginEnabled);
  const [isSsoEnabled, toggleSsoLogin] = useToggle(!!holding.isSsoEnabled);
  const [isChangePasswordOnFirstLoginEnabled, toggleChangePasswordOnFirstLogin] = useToggle(!!holding.isChangePasswordOnFirstLoginEnabled);
  const { form, setFormAttribute, clearForm } = useForm({
    ssoEndpoint: holding.ssoEndpoint || '',
    ssoClientIdWeb: holding.ssoClientIdWeb || '',
    ssoClientSecretWeb: holding.ssoClientSecretWeb || '',
    ssoClientIdApp: holding.ssoClientIdApp || '',
    ssoClientSecretApp: holding.ssoClientSecretApp || '',
  });

  useEffect(() => {
    didCancel = false;
    return () => { didCancel = true; };
  }, []);

  const onSave = async () => {
    setIsSaving(true);
    const saveResult = await superAdminService.updateHoldingLogin(holdingId, {
      isRegularLoginEnabled,
      isSsoEnabled,
      ssoEndpoint: isSsoEnabled ? form.ssoEndpoint : null,
      ssoClientIdWeb: isSsoEnabled ? form.ssoClientIdWeb : null,
      ssoClientSecretWeb: isSsoEnabled ? form.ssoClientSecretWeb : null,
      ssoClientIdApp: isSsoEnabled ? form.ssoClientIdApp : null,
      ssoClientSecretApp: isSsoEnabled ? form.ssoClientSecretApp : null,
      isChangePasswordOnFirstLoginEnabled,
    });
    if (saveResult) {
      toast(translations.getLabel('lblGenericSuccessMessage'));
      const result = await superAdminService.getHoldings();
      if (result?.data) context.updateHoldings(result.data);
      if (!didCancel) toggleModal();
    }
    if (!didCancel) setIsSaving(false);
  };

  const resetModal = () => {
    toggleModal();
    toggleRegularLogin(!!holding.isRegularLoginEnabled);
    toggleSsoLogin(!!holding.isSsoEnabled);
    toggleChangePasswordOnFirstLogin(!!holding.isChangePasswordOnFirstLoginEnabled);
    clearForm();
  };

  return (
    <>
      <EditItem title={translations.getLabel('titleLoginForHolding')} onClick={toggleModal}>
        <>
          <p>{translations.getLabel('lblDescriptionLoginForHolding')}</p>
          <EditValue
            rightSpacing
            title={translations.getLabel('lblLoginUsernamePassword')}
            value={translations.getLabel(holding.isRegularLoginEnabled ? 'yes' : 'no')}
          />
          <EditValue
            rightSpacing
            title={translations.getLabel('lblLoginSSO')}
            value={translations.getLabel(holding.isSsoEnabled ? 'yes' : 'no')}
          />
          <EditValue
            rightSpacing
            title={translations.getLabel('lblPasswordOnFirstLogin')}
            value={translations.getLabel(holding.isChangePasswordOnFirstLoginEnabled ? 'yes' : 'no')}
          />
        </>
      </EditItem>

      <Modal
        open={isModalOpen}
        title={translations.getLabel('titleLoginForHolding')}
        requestClose={resetModal}
        leftButtonProps={{
          label: translations.getLabel('cancel'),
          onClick: resetModal,
        }}
        rightButtonProps={{
          label: translations.getLabel('save'),
          isLoading: isSaving,
          onClick: onSave,
          disabled: (!isSsoEnabled && !isRegularLoginEnabled) || (isSsoEnabled && (!form.ssoEndpoint || !form.ssoClientIdWeb || !form.ssoClientSecretWeb || !form.ssoClientIdApp || !form.ssoClientSecretApp)),
        }}
      >
        <div className="modal-content">
          <p>{translations.getLabel('lblDescriptionLoginForHolding')}</p>
          <div className="modal-toggle-container">
            <p className="modal-description">{translations.getLabel('lblLoginUsernamePassword')}</p>
            <Switch isChecked={isRegularLoginEnabled} handleChange={toggleRegularLogin} leftLabel={translations.getLabel('no')} rightLabel={translations.getLabel('yes')} />
          </div>
          <div className="modal-toggle-container">
            <p className="modal-description">{translations.getLabel('lblLoginSSO')}</p>
            <Switch isChecked={isSsoEnabled} handleChange={toggleSsoLogin} leftLabel={translations.getLabel('no')} rightLabel={translations.getLabel('yes')} />
          </div>
          <div className="modal-toggle-container">
            <p className="modal-description">{translations.getLabel('lblPasswordOnFirstLogin')}</p>
            <Switch isChecked={isChangePasswordOnFirstLoginEnabled} handleChange={toggleChangePasswordOnFirstLogin} leftLabel={translations.getLabel('no')} rightLabel={translations.getLabel('yes')} />
          </div>
          {isSsoEnabled && (
            <>
              <div className="modal-input-container">
                <InputField autoFocus type={InputType.Text} emptyIcon onChange={setFormAttribute} name="ssoEndpoint" label={translations.getLabel('lblSSOEndpoint')} value={form.ssoEndpoint} />
              </div>
              <div className="modal-input-container">
                <InputField type={InputType.Text} emptyIcon onChange={setFormAttribute} name="ssoClientIdWeb" label={translations.getLabel('lblSSOClientIdWeb')} value={form.ssoClientIdWeb} />
              </div>
              <div className="modal-input-container">
                <InputField type={InputType.Text} emptyIcon onChange={setFormAttribute} name="ssoClientSecretWeb" label={translations.getLabel('lblSSOClientSecretWeb')} value={form.ssoClientSecretWeb} />
              </div>
              <div className="modal-input-container">
                <InputField type={InputType.Text} emptyIcon onChange={setFormAttribute} name="ssoClientIdApp" label={translations.getLabel('lblSSOClientIdApp')} value={form.ssoClientIdApp} />
              </div>
              <div className="modal-input-container">
                <InputField type={InputType.Text} emptyIcon onChange={setFormAttribute} name="ssoClientSecretApp" label={translations.getLabel('lblSSOClientSecretApp')} value={form.ssoClientSecretApp} />
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
};

LoginSettings.propTypes = {
  holdingId: PropTypes.string.isRequired,
  holding: PropTypes.object.isRequired,
};

export default LoginSettings;
