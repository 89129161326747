import React, { useState } from 'react';
import { InputField, InputType } from 'react-ess-components';
import MapGL, { NavigationControl } from '@urbica/react-map-gl';
import Draw from '@urbica/react-map-gl-draw';
import PropTypes from 'prop-types';

import { translations } from '../../../../../utils';

import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';

const initialViewport = (coordinates) => {
  const coordinatesArr = coordinates && typeof coordinates === 'string' ? JSON.parse(coordinates) : coordinates;
  return {
    latitude: coordinatesArr?.[0]?.[0]?.[1] || 52.34820300258141, // use first lat/long OR Center of the Netherlands as default
    longitude: coordinatesArr?.[0]?.[0]?.[0] || 5.2814465542358455,  // use first lat/long OR Center of the Netherlands as default
    zoom: coordinatesArr ? 14 : 6.47,
  };
};

const initiaDataset = (coordinates) => {
  if (!coordinates) return null;
  return {
    type: 'FeatureCollection',
    features: [{
      id: 'a31659fe57aaeb82988385af172c3ce6',
      type: 'Feature',
      properties: {},
      geometry: {
        coordinates: typeof coordinates === 'string' ? JSON.parse(coordinates) : coordinates,
        type: 'Polygon',
      },
    }],
  };
};

export const TimeRegistrationGpsForm = ({ locationId, setFormAttribute, identifier }) => {
  const [viewport, setViewport] = useState(initialViewport(identifier));
  const [data, setData] = useState(initiaDataset(identifier));

  return (
    <div>
      <div className="input-container">
        <p>{translations.getLabel('lblLocationIdLocationWizard')}</p>
        <InputField type={InputType.Text} value={locationId} emptyIcon name="locationId" onChange={setFormAttribute} />
      </div>

      <p className="gps-description">{translations.getLabel('lblGpsMapDescription')}</p>
      <MapGL
        style={{ width: '700px', height: '400px' }}
        {...viewport}
        mapStyle="mapbox://styles/mapbox/streets-v11"
        onViewportChange={(viewport) => setViewport(viewport)}
        accessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
      >
        <Draw
          data={data}
          onChange={(data) => {
            if (data?.features?.length === 0) {
              setData(null);
              setFormAttribute(null, 'identifier');
            } else {
              setData(data);
              setFormAttribute(JSON.stringify(data.features?.[0]?.geometry?.coordinates), 'identifier');
            }
          }}
          uncombineFeaturesControl={false}
          pointControl={false}
          combineFeaturesControl={false}
          lineStringControl={false}
        />
        <NavigationControl showZoom position="top-left" />
      </MapGL>
    </div>
  );
};

TimeRegistrationGpsForm.propTypes = {
  setFormAttribute: PropTypes.func.isRequired,
  identifier: PropTypes.string,
  locationId: PropTypes.string,
};

TimeRegistrationGpsForm.defaultProps = {
  identifier: '',
  locationId: '',
};

export default TimeRegistrationGpsForm;
