import React, { useContext, useEffect,useState } from 'react';
import { Button, Icon, IconButton, InputField, InputType,Modal } from 'react-ess-components';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import ConfigContext from '../../../../../context/ConfigContext';
import { useForm,useToggle } from '../../../../../hooks';
import { configService } from '../../../../../services';
import { translations } from '../../../../../utils';

import './vacationTypes.scss';

let didCancel = false;

const initialFormFromTask = (timeType) => {
  return {
    wfmId: timeType?.wfmId || '',
    description: timeType?.description || '',
  };
};

const isFormValid = (timeType) => {
  return !!timeType.wfmId
    && !!timeType.description;
};

const SensitiveShifts = ({ match }) => {
  const context = useContext(ConfigContext);
  const [isEditModalOpen, toggleEditModal] = useToggle(false);
  const [isConfirmModalOpen, toggleConfirmModal] = useToggle(false);
  const [isSaveLoading, setSaveLoading] = useState(false);
  const [isDeleteLoading, setDeleteLoading] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const { form, setForm, setFormAttribute } = useForm(initialFormFromTask());

  useEffect(() => {
    return () => {
      didCancel = true;
    };
  }, []);

  useEffect(() => {
    if (!isEditModalOpen && editIndex >= 0) setEditIndex(null);
  }, [editIndex, isEditModalOpen]);

  const openEditModal = (timeType, index) => {
    const initialValue = initialFormFromTask(timeType);
    setForm(initialValue);
    setEditIndex(index);
    toggleEditModal();
  };

  const saveSensitiveType = async () => {
    setSaveLoading(true);

    const timeTypes = [...(context.config?.wcf?.avgSensitiveShifts || [])];
    if (editIndex >= 0) {
      timeTypes[editIndex] = { ...timeTypes[editIndex], wfmId: form.wfmId, description: form.description };
    } else {
      timeTypes.push({ wfmId: form.wfmId, description: form.description });
    }

    didCancel = false;
    const result = await handleSave(timeTypes);
    if (!didCancel) {
      if (result) {
        toggleEditModal();
      }
      setSaveLoading(false);
    }
  };

  const handleSave = async (timeTypes) => {
    didCancel = false;
    const result = await configService.updateConfig(match.params.id, { wcf: { avgSensitiveShifts: timeTypes } });
    if (result && !didCancel) {
      context.updateConfig(result.config);
      return result;
    }
    return null;
  };

  const deleteSensitiveType = async () => {
    const timeTypes = context.config.wcf.avgSensitiveShifts.filter((tt, index) => index !== deleteIndex);
    setDeleteLoading(true);
    didCancel = false;
    const result = await handleSave(timeTypes);
    setDeleteLoading(false);
    if (result && !didCancel) {
      setDeleteIndex(null);
      toggleConfirmModal(false);
    }
  };

  const openDeleteModal = (index) => {
    setDeleteIndex(index);
    toggleConfirmModal();
  };

  const renderRow = (timeType, index) => {
    return (<tr key={`${timeType.wfmId}-${timeType.description}`}>
      <td>{timeType.wfmId}</td>
      <td>{timeType.description}</td>
      <td className="icon-cell"><IconButton tag="EditIcon" color="primary" onClick={() => openEditModal(timeType, index)} /></td>
      <td className="icon-cell"><IconButton tag="TrashIcon" color="error" onClick={() => openDeleteModal(index)} /></td>
    </tr>);
  };

  return (
    <div className="vacation-types-container">
      <h4>{translations.getLabel('titleSensitiveTypesSchedule')}</h4>

      <p>{translations.getLabel('lblSensitiveTypesScheduleDescription')}</p>
      {context.config?.wcf?.avgSensitiveShifts.length > 0 &&
        <table className="table">
          <thead>
            <tr>
              <th>{translations.getLabel('lblWFMID')}</th>
              <th>{translations.getLabel('lblDescription')}</th>
              <th colSpan={2} />
            </tr>
          </thead>
          <tbody>
            {context.config?.wcf?.avgSensitiveShifts.map(renderRow)}
          </tbody>
        </table>}

      <div className="add-button">
        <Button theme="transparent" onClick={openEditModal}>
          <Icon tag="PlusSquareIcon" color="primary" />
          <p>{translations.getLabel('btnAddSensitiveType')}</p>
        </Button>
      </div>

      {isEditModalOpen && <Modal
        requestClose={openEditModal}
        open={isEditModalOpen}
        rightButtonProps={{ label: translations.getLabel('save'), onClick: saveSensitiveType, isLoading: isSaveLoading, disabled: !isFormValid(form) }}
        leftButtonProps={{ label: translations.getLabel('cancel'), onClick: openEditModal }}
        title={editIndex >= 0 ? translations.getLabel('titleEditSensitiveType') : translations.getLabel('titleNewSensitiveType')}
      >
        <InputField autoFocus type={InputType.Text} name="wfmId" emptyIcon onChange={setFormAttribute} label={translations.getLabel('lblWFMID')} value={form.wfmId} />
        <InputField type={InputType.Text} name="description" emptyIcon onChange={setFormAttribute} label={translations.getLabel('lblDescription')} value={form.description} />
      </Modal>}

      <Modal
        requestClose={toggleConfirmModal}
        open={isConfirmModalOpen}
        rightButtonProps={{ label: translations.getLabel('lblDelete'), onClick: deleteSensitiveType, isLoading: isDeleteLoading }}
        leftButtonProps={{ label: translations.getLabel('cancel'), onClick: toggleConfirmModal }}
        title={translations.getLabel('titleConfirmationDeleteSensitiveType')}
      >
        <p className="confirmation-message">{translations.getLabel('lblConfirmationDeleteSensitiveType')}</p>
      </Modal>
    </div>
  );
};

SensitiveShifts.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(SensitiveShifts);
