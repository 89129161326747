import React, { Fragment, useEffect, useState } from 'react';
import { Button, Icon, IconButton, Modal } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import { withRouter } from 'react-router-dom';
import { format, parseISO } from 'date-fns';
import PropTypes from 'prop-types';

import { superAdminService } from '../../../../../../services';
import { translations } from '../../../../../../utils';
import CounterModal from '../counter/CounterModal';

import '../CountersConfigurationTab.scss';


function CounterConfiguration({ holdingId }) {
  const [counters, setCounters] = useState([]);
  const [editingCounter, setEditingCounter] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const [removeId, setRemoveId] = useState('');

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getData = async () => {
    let counters = await superAdminService.getHoldingCounters(holdingId);

    counters = sortCounters(counters);

    setCounters(counters);
    setIsLoading(false);
  };

  const deleteCounter = async () => {
    setIsRemoveLoading(true);
    await superAdminService.deleteHoldingCounter(holdingId, removeId);

    setIsConfirmationModalOpen(false);
    setIsRemoveLoading(false);
    getData();
  };

  const handleSave = async (counter, id = '') => {
    setIsLoading(true);

    const counterBody = {
      description: counter.description || '',
      externalId: counter.externalId || '',
      fromDate: counter.fromDate || '',
      toDate: counter.toDate || '',
    };

    if (id) {
      await superAdminService.updateHoldingCounter(holdingId, id, counterBody);
    } else {
      await superAdminService.addHoldingCounter(holdingId, counterBody);
    }

    getData();
  };

  const renderLoading = key => {
    return <div key={key} className="loading-wrapper"><Skeleton width="100%" count={15} /></div>;
  };

  const renderRowCounters = (counter) => {
    return (
      <tr key={counter.id}>
        <td>{counter.description}</td>
        <td>{counter.externalId}</td>
        <td>{counter.fromDate.includes('/') ? <p title={counter.fromDate}>Relative date</p> : format(parseISO(counter.fromDate), 'dd/MM/yyyy')}</td>
        <td>{counter.toDate.includes('/') ? <p title={counter.toDate}>Relative date</p> : format(parseISO(counter.toDate), 'dd/MM/yyyy')}</td>
        <td className="icon-cell">
          <IconButton
            color="primary"
            onClick={toggleModal(counter, counter.counterId)}
            tag="EditIcon"
          />
        </td>
        <td className="icon-cell">
          <IconButton
            color="error"
            onClick={toggleConfirmationModal(counter.id)}
            tag="TrashIcon"
          />
        </td>
      </tr>
    );
  };

  const toggleModal = (counter = {}) => () => {
    setIsModalOpen(!isModalOpen);
    setEditingCounter(counter);
  };

  const toggleConfirmationModal = (id) => () => {
    setIsConfirmationModalOpen(!isConfirmationModalOpen);
    setRemoveId(id);
  };

  const sortCounters = (counters) => {
    return counters.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateA - dateB;
    }
    );
  };

  return (
    <>
      <div className="item-container">
        <h4>{translations.getLabel('titleCounters')}</h4>
        <p>{translations.getLabel('lblHoldingCounterConfiguration')}</p>
        {isLoading
          ? renderLoading()
          : <Fragment>
            {counters.length > 0 ? (
              <table className="table">
                <thead>
                  <tr>
                    <th>{translations.getLabel('lblDescription')}</th>
                    <th>{translations.getLabel('lblWFMID')}</th>
                    <th>{translations.getLabel('lblFrom')}</th>
                    <th>{translations.getLabel('lblUntil')}</th>
                    <th colSpan={4} />
                  </tr>
                </thead>
                <tbody>{counters.map(renderRowCounters)}</tbody>
              </table>
            ) : <div />}
          </Fragment>
        }

        <div className="add-button">
          <Button theme="transparent" onClick={toggleModal()}>
            <Icon tag="PlusSquareIcon" color="primary" />
            <p>{translations.getLabel('btnAddCounter')}</p>
          </Button>
        </div>

        <div className="hr" />

        {isModalOpen && <CounterModal editingCounter={editingCounter} editingCounterId={editingCounter.id} handleSave={handleSave} isLoading={isLoading} isModalOpen={isModalOpen} toggleModal={toggleModal()} />}
        <Modal
          requestClose={toggleConfirmationModal()}
          open={isConfirmationModalOpen}
          rightButtonProps={{ label: translations.getLabel('lblDelete'), onClick: deleteCounter, isLoading: isRemoveLoading }}
          leftButtonProps={{ label: translations.getLabel('cancel'), onClick: toggleConfirmationModal() }}
          title={translations.getLabel('titleConfirmationDeleteCounter')}
        >
          <p className="confirmation-message">{translations.getLabel('lblConfirmationDeleteCounter')}</p>
        </Modal>
      </div>
    </>
  );
}

CounterConfiguration.propTypes = {
  holdingId: PropTypes.string.isRequired,
};

export default withRouter(CounterConfiguration);
