import React, { PureComponent } from 'react';
import { Button, Icon, IconButton, InputField, InputType,Modal } from 'react-ess-components';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import ConfigContext from '../../../../../context/ConfigContext';
import { configService } from '../../../../../services';
import { translations } from '../../../../../utils';
import EditItem from '../../../../editItem/EditItem';
import EditValue from '../../../../editValue/EditValue';
import Switch from '../../../../switch/Switch';
import SensitiveShifts from './SensitiveShifts';
import VacationTypes from './VacationTypes';

import './scheduleTab.scss';

const DEFAULT_COLOR_CODE = '#ffffff';

class ScheduleTab extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      description: '',
      editedTimeTypeId: '',
      isConfirmationModalOpen: false,
      isEditing: false,
      isLoading: false,
      isModalOpen: false,
      isRemoveLoading: false,
      removeId: '',
      timeTypeId: '',
    };
  }

  toggleModal = (timeTypeId = '', description = '', isEditing = false) => () => this.setState({ description, timeTypeId, editedTimeTypeId: timeTypeId, isModalOpen: !this.state.isModalOpen, isEditing });
  toggleConfirmationModal = (id) => () => this.setState({ isConfirmationModalOpen: !this.state.isConfirmationModalOpen, removeId: id });

  onChangeDescription = description => this.setState({ description });
  onChangeId = editedTimeTypeId => this.setState({ editedTimeTypeId });

  editItem = (id) => () => {
    const timeType = this.context.config.wcf.scheduleTimeTypes.find(tt => tt.timeTypeId === id);
    this.toggleModal(timeType.timeTypeId, timeType.description, true)();
  }

  removeItem = async () => {
    const timeTypes = this.context.config.wcf.scheduleTimeTypes.filter(tt => tt.timeTypeId !== this.state.removeId);
    this.setState({ isRemoveLoading: true });
    await this.handleSave(timeTypes);
    this.setState({ isRemoveLoading: false, removeId: '', isConfirmationModalOpen: false });
  }

  handleSave = async (timeTypes) => {
    const result = await configService.updateConfig(this.props.match.params.id, { wcf: { scheduleTimeTypes: timeTypes } });
    if (result) {
      this.context.updateConfig(result.config);
    }
    return result;
  }

  handleSaveModal = async () => {
    const { editedTimeTypeId, timeTypeId, description } = this.state;
    this.setState({ isLoading: true });
    const oldTimeTypes = (this.context.config && this.context.config.wcf && this.context.config.wcf.scheduleTimeTypes) || [];
    const timeTypes = [...oldTimeTypes];
    const existingTimeTypeIndex = oldTimeTypes.findIndex(ott => ott.timeTypeId === timeTypeId);
    if (existingTimeTypeIndex >= 0) {
      timeTypes[existingTimeTypeIndex] = { ...timeTypes[existingTimeTypeIndex], timeTypeId: editedTimeTypeId || timeTypeId, description, visible: false };
    } else {
      timeTypes.push({ timeTypeId: editedTimeTypeId || timeTypeId, description, visible: false, color: DEFAULT_COLOR_CODE });
    }

    const result = await this.handleSave(timeTypes);
    if (result) {
      this.toggleModal()();
    }
    this.setState({ isLoading: false });
  }

  renderRow = (timeType) => {
    return (<tr key={timeType.timeTypeId}>
      <td>{timeType.timeTypeId}</td>
      <td>{timeType.description}</td>
      <td className="icon-cell"><IconButton tag="EditIcon" color="primary" onClick={this.editItem(timeType.timeTypeId)} /></td>
      <td className="icon-cell"><IconButton tag="TrashIcon" color="error" onClick={this.toggleConfirmationModal(timeType.timeTypeId)} /></td>
    </tr>);
  }

  toggleHideAllModal = () => {
    this.setState({ isHideModalOpen: !this.state.isHideModalOpen, hideAllTimeTypes: this.context.config?.wcf?.hideAllTimeTypes });
  }

  toggleSetting = () => {
    this.setState({ hideAllTimeTypes: !this.state.hideAllTimeTypes });
  }

  onCancelHideAll = () => {
    this.toggleHideAllModal();
  }

  onSaveHideAll = async () => {
    const result = await configService.updateConfig(this.props.match.params.id, { wcf: { hideAllTimeTypes: this.state.hideAllTimeTypes } });
    if (result) {
      this.context.updateConfig(result.config);
    }

    this.toggleHideAllModal();
  }

  render() {
    const timeTypes = (this.context.config && this.context.config.wcf && this.context.config.wcf.scheduleTimeTypes) || [];
    const hiddenTimeType = timeTypes.filter(tt => !tt.visible);
    return (
      <div className="schedule-container">
        <h4>{translations.getLabel('titleTimeTypesSchedule')}</h4>
        <EditItem
          title=""
          onClick={this.toggleHideAllModal}
        >
          <EditValue
            title={translations.getLabel('lblHideAllTimeTypes')}
            value={this.context.config?.wcf?.hideAllTimeTypes ? translations.getLabel('yes') : translations.getLabel('no')}
          />
        </EditItem>
        {
          !this.context.config?.wcf?.hideAllTimeTypes &&
          <>
            <p>{translations.getLabel('lblTimeTypesScheduleDescription')}</p>
            {hiddenTimeType.length > 0 &&
              <table className="table" >
                <thead>
                  <tr>
                    <th>{translations.getLabel('lblWFMID')}</th>
                    <th>{translations.getLabel('lblDescription')}</th>
                    <th colSpan={2} />
                  </tr>
                </thead>
                <tbody>
                  {hiddenTimeType.map(this.renderRow)}
                </tbody>
              </table>}

            <div className="add-button">
              <Button theme="transparent" onClick={this.toggleModal()}>
                <Icon tag="PlusSquareIcon" color="primary" />
                <p>{translations.getLabel('btnAddScheduleTimeTypes')}</p>
              </Button>
            </div>
          </>
        }

        <SensitiveShifts />
        <VacationTypes />

        <Modal
          open={this.state.isHideModalOpen}
          title={translations.getLabel('titleHideAllTimeTypes')}
          requestClose={this.toggleHideAllModal}
          leftButtonProps={{
            label: translations.getLabel('cancel'),
            onClick: this.onCancelHideAll,
          }}
          rightButtonProps={{
            label: translations.getLabel('save'),
            isLoading: this.state.isLoading,
            onClick: this.onSaveHideAll,
          }}
        >
          <div className="settings-toggle-container" >
            <span className="settings-description">{translations.getLabel('lblHideAllTimeTypes')}</span>
            <Switch isChecked={!!this.state.hideAllTimeTypes} handleChange={this.toggleSetting} leftLabel={translations.getLabel('no')} rightLabel={translations.getLabel('yes')} />
          </div>
        </Modal>

        <Modal
          requestClose={this.toggleModal()}
          open={this.state.isModalOpen}
          rightButtonProps={{ label: translations.getLabel('save'), onClick: this.handleSaveModal, isLoading: this.state.isLoading, disabled: !this.state.description || (!this.state.timeTypeId && !this.state.editedTimeTypeId) }}
          leftButtonProps={{ label: translations.getLabel('cancel'), onClick: this.toggleModal() }}
          title={this.state.isEditing ? translations.getLabel('titleEditTimeTypeSchedule') : translations.getLabel('titleNewTimeTypeSchedule')}
        >
          <InputField autoFocus type={InputType.Text} emptyIcon onChange={this.onChangeId} label={translations.getLabel('lblWFMID')} value={this.state.editedTimeTypeId || this.state.timeTypeId} />
          <InputField type={InputType.Text} emptyIcon onChange={this.onChangeDescription} label={translations.getLabel('lblDescription')} value={this.state.description} />
        </Modal>

        <Modal
          requestClose={this.toggleConfirmationModal()}
          open={this.state.isConfirmationModalOpen}
          rightButtonProps={{ label: translations.getLabel('lblDelete'), onClick: this.removeItem, isLoading: this.state.isRemoveLoading }}
          leftButtonProps={{ label: translations.getLabel('cancel'), onClick: this.toggleConfirmationModal() }}
          title={translations.getLabel('titleConfirmationDeleteTimeType')}
        >
          <p className="confirmation-message">{translations.getLabel('lblConfirmationDeleteTimeType')}</p>
        </Modal>

      </div>

    );
  }
}

ScheduleTab.contextType = ConfigContext;
ScheduleTab.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(ScheduleTab);
