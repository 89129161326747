import React, { useEffect, useState } from 'react';
import { Avatar, Button, Icon, IconButton } from 'react-ess-components';
import PropTypes from 'prop-types';

import { timeRegistrationLocationTypeKeys, timeRegistrationLocationTypes } from '../../../../../constants';
import { useConfirmationModal, useToggle } from '../../../../../hooks';
import { companyAdminService } from '../../../../../services';
import { translations } from '../../../../../utils';
import TimeRegistrationLocationWizard from './TimeRegistrationLocationWizard';

const Department = ({ id, name, locations, companyId, updateLocations }) => {
  const [isWizardOpen, toggleIsWizardOpen] = useToggle(false);
  const [editLocation, setEditLocation] = useState(null);

  const deleteLocation = async ({ locationId }) => {
    await companyAdminService.deleteLocation(companyId, id, locationId);
    await updateLocations();
  };
  const [renderDeleteModal, openDeleteModal] = useConfirmationModal(null, deleteLocation);


  useEffect(() => {
    if (!isWizardOpen) setEditLocation(null);
  }, [isWizardOpen]);

  const renderRow = (item) => {
    const type = timeRegistrationLocationTypes.find(type => type.key === item?.type);
    return (
      <tr key={item?.id}>
        <td>{item?.locationId}</td>
        <td className="identifier-cell">{type.key === timeRegistrationLocationTypeKeys.Gps ? `Lnt.Lat. ${JSON.parse(item?.identifier).join(',')}` : item?.identifier}</td>
        <td>{type ? translations.getLabel(type.label) : ''}</td>
        <td className="icon-cell">
          <IconButton tag="EditIcon" color="primary" onClick={() => { setEditLocation(item); toggleIsWizardOpen(); }} />
        </td>
        <td className="icon-cell">
          <IconButton tag="TrashIcon" color="error" onClick={() => openDeleteModal({ locationId: item.id })} />
        </td>
      </tr>
    );
  };

  return (
    <div className="department">
      <div className="header">
        <Avatar isCompany />
        <p className="title">{name}</p>
      </div>
      <div className="table-container">
        {
          locations.length ?
            <table className="table" >
              <thead>
                <tr>
                  <th>{translations.getLabel('lblLocationId')}</th>
                  <th>{translations.getLabel('lblId')}</th>
                  <th>{translations.getLabel('lblType')}</th>
                  <th />
                  <th />
                </tr>
              </thead>
              <tbody>
                {locations.map(renderRow)}
              </tbody>
            </table>
            :
            <p className="empty-label">{translations.getLabel('lblEmptyLocation')}</p>
        }

        <Button theme="transparent" onClick={toggleIsWizardOpen}>
          <Icon tag="PlusSquareIcon" color="primary" />
          <p className="button-label">{translations.getLabel('btnAddLocation')}</p>
        </Button>

        <TimeRegistrationLocationWizard
          isOpen={isWizardOpen}
          toggleModal={toggleIsWizardOpen}
          companyId={companyId}
          departmentId={id}
          location={editLocation}
          onSaved={updateLocations} />

        {renderDeleteModal({
          title: translations.getLabel('titleDeleteTimeRegistrationLocation'),
          rightButton: translations.getLabel('lblDelete'),
          message: translations.getLabel('lblDeleteTimeRegistrationLocation'),
        })}
      </div>
    </div>
  );
};

Department.propTypes = {
  id: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  locations: PropTypes.array.isRequired,
  updateLocations: PropTypes.func.isRequired,
};

export default Department;
