import React, { useEffect, useState } from 'react';
import { SelectPopup } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

import { translations } from '../../../../../../utils';
import { EditItem } from '../../../../..';

const CountersEditItem = (props) => {
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [selectedCounters, setSelectedCounters] = useState([]);

  useEffect(() => {
    if (!props.isLoading) setSelectedCounters(props.counters?.map(c => c.counterId));
  }, [props.counters, props.isLoading]);

  const openEditModal = () => setIsEditOpen(true);

  const closeEditModal = () => {
    setSelectedCounters(props.counters?.map(c => c.counterId));
    setIsEditOpen(false);
  };

  const renderRow = (counter) => (
    <tr key={counter.id}>
      <td>{counter.counterDescription}</td>
    </tr>
  );

  const onSave = () => {
    setIsEditOpen(false);
    props.onSave(selectedCounters);
  };

  return (
    <div>
      <EditItem
        title={props.title}
        label={props.label}
        onClick={openEditModal}
      >
        {(props.counters.length > 0 && !props.isLoading) &&
          <table className="table" >
            <thead>
              <tr>
                <th>{translations.getLabel('lblCounters')}</th>
              </tr>
            </thead>
            <tbody>
              {
                props.isLoading
                  ?
                  <tr><td> <Skeleton width="100%" height="4rem" /></td></tr>
                  :
                  props.counters.map(renderRow)
              }
            </tbody>
          </table>
        }
        {!props.isLoading && !props.counters.length && <p>{props.emptyLabel}</p>}
        {props.isLoading && <p><Skeleton width="50%" height="2rem" /></p>}

      </EditItem>

      <SelectPopup
        data={props.availableCounters?.map(counter => ({ value: counter.id, label: counter.description }))}
        popupOpen={isEditOpen}
        isMultiple
        isModal
        selected={selectedCounters}
        title={props.title}
        requestClose={closeEditModal}
        onValueSelected={setSelectedCounters}
        onSave={onSave}
        acceptLabel={translations.getLabel('save')}
        cancelLabel={translations.getLabel('cancel')}
        isSearchEnabled={false}
        isAllowedEmpty
      />
    </div>
  );
};

CountersEditItem.propTypes = {
  counters: PropTypes.array.isRequired,
  availableCounters: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  emptyLabel: PropTypes.string.isRequired,
};

export default CountersEditItem;
