import React, { useEffect, useState } from 'react';
import { Modal } from 'react-ess-components';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { timeRegistrationLocationTypeKeys,timeRegistrationLocationTypes } from '../../../../../constants';
import { useForm } from '../../../../../hooks';
import { companyAdminService } from '../../../../../services';
import { translations } from '../../../../../utils';
import TimeRegistrationBeaconForm from './TimeRegistrationBeaconForm';
import TimeRegistrationGpsForm from './TimeRegistrationGpsForm';
import TimeRegistrationWifiForm from './TimeRegistrationWifiForm';

let didCancel = false;

const TimeRegistrationLocationWizard = ({ onSaved, isOpen, toggleModal, companyId, departmentId, location }) => {
  const [wizardStep, setWizardStep] = useState(0);
  const [isValid, setIsValid] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { form, setForm, setFormAttribute } = useForm({});

  useEffect(() => {
    didCancel = false;
    return () => {
      didCancel = true;
    };
  }, []);

  useEffect(() => {
    if (!isOpen) {
      setForm({});
      setWizardStep(0);
    } else if (isOpen && location) {
      setForm({
        type: location.type,
        identifier: location.identifier,
        locationId: location.locationId,
      });
      setWizardStep(1);
    }
    setIsValid(false);
  }, [isOpen, location, setForm]);

  useEffect(() => {
    if (form.identifier && form.locationId) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [form.identifier, form.locationId]);

  const save = async () => {
    setIsLoading(true);
    let result;
    if (location) {
      // We can't update the type, so remove it from object that we send
      const { type, ...locationToUpdate } = form;
      result = await companyAdminService.updateLocation(companyId, departmentId, location.id, locationToUpdate);
    } else {
      result = await companyAdminService.postLocation(companyId, departmentId, form);
    }
    if (result?.id) {
      toggleModal();
      await onSaved();
    }
    if (!didCancel) {
      setIsLoading(false);
    }
  };

  const step = (direction) => {
    const position = wizardStep + direction;
    if (position < 0) toggleModal();
    if (position > 1) {
      save();
    }
    else setWizardStep(position);
  };

  return (
    <Modal
      open={isOpen}
      title={translations.getLabel(location ? 'titelEditTimeRegistrationLocation' : 'titelAddTimeRegistrationLocation')}
      requestClose={toggleModal}
      leftButtonProps={{
        label: translations.getLabel(wizardStep === 0 || location ? 'cancel' : 'prev'),
        onClick: () => step(location ? -2 : -1),
      }}
      rightButtonProps={{
        label: translations.getLabel(wizardStep === 1 ? 'save' : 'next'),
        isLoading: isLoading,
        disabled: (wizardStep === 0 && !form.type) || (wizardStep === 1 && !isValid),
        onClick: () => step(1),
      }}
    >
      {
        wizardStep === 0 &&
        <div className="inner-modal-container">
          <p className="description">{translations.getLabel('lblAddTimeRegistrationLocationOptions')}</p>
          {timeRegistrationLocationTypes.map((type => (
            <label key={type.key} htmlFor={type.key}>
              <input type="radio" id={type.key} onChange={() => setFormAttribute(type.key, 'type')} checked={type.key === form.type} /> {translations.getLabel(type.label)}
            </label>
          )))}
        </div>
      }
      {
        wizardStep === 1 &&
        <div className={classNames('inner-modal-container', { 'with-map': form.type === timeRegistrationLocationTypeKeys.Gps })}>
          {form.type === timeRegistrationLocationTypeKeys.Wifi && <TimeRegistrationWifiForm setFormAttribute={setFormAttribute} identifier={form.identifier} locationId={form.locationId} />}
          {form.type === timeRegistrationLocationTypeKeys.Beacon && <TimeRegistrationBeaconForm setFormAttribute={setFormAttribute} identifier={form.identifier} locationId={form.locationId} />}
          {form.type === timeRegistrationLocationTypeKeys.Gps && <TimeRegistrationGpsForm setFormAttribute={setFormAttribute} identifier={form.identifier} locationId={form.locationId} />}
        </div>
      }
    </Modal>
  );
};


TimeRegistrationLocationWizard.propTypes = {
  onSaved: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  companyId: PropTypes.string.isRequired,
  departmentId: PropTypes.string.isRequired,
  toggleModal: PropTypes.func.isRequired,
  location: PropTypes.object,
};

TimeRegistrationLocationWizard.defaultProps = {
  isOpen: false,
  location: null,
};

export default TimeRegistrationLocationWizard;
