import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

import { companyAdminService } from '../../../../services';
import Department from './components/Department';

import './TimeRegistrationLocationsTab.scss';

let didCancel = false;

const TimeRegistrationLocationsTab = ({ companyId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [departments, setDepartments] = useState([]);

  const getLocationsNoLoading = async () => {
    const result = await companyAdminService.getLocations(companyId);
    if (!didCancel) {
      setDepartments(result || []);
    }
  };

  useEffect(() => {
    const getLocations = async () => {
      setIsLoading(true);
      const result = await companyAdminService.getLocations(companyId);
      if (!didCancel) {
        setIsLoading(false);
        setDepartments(result || []);
      }
    };

    didCancel = false;

    getLocations();
    return () => {
      didCancel = true;
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="counters-tab locations-tab">
      {
        isLoading ?
          <Skeleton height="4.8rem" />
          :
          departments.map(department => (
            <Department
              companyId={companyId}
              key={department.id}
              name={department.name}
              id={department.id}
              locations={department.locations}
              updateLocations={getLocationsNoLoading}
            />
          ))
      }
    </div>
  );
};

TimeRegistrationLocationsTab.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default TimeRegistrationLocationsTab;

