import React from 'react';
import { IconButton } from 'react-ess-components';
import PropTypes from 'prop-types';

import { services } from '../../../../../constants/selfRosterProfileServices';
import { useConfirmationModal } from '../../../../../hooks';
import { companyAdminService } from '../../../../../services';
import { translations } from '../../../../../utils';

import './selfRosterProfile.scss';

const SelfRosterProfile = ({ profile, companyId, switchModal, getData }) => {
  const deleteProfile = async ({ profileId }) => {
    await companyAdminService.deleteSelfRosterProfile(companyId, profileId);
    getData();
  };

  const [renderDeleteModal, openDeleteModal] = useConfirmationModal(null, deleteProfile);

  const checkIterations = (profile) => {
    if (profile.checkIterationOne && profile.checkIterationTwo) return `${translations.getLabel('lblRuleChecksRound1')}, ${translations.getLabel('lblRuleChecksRound2')}`;
    else if (profile.checkIterationOne) return translations.getLabel('lblRuleChecksRound1');
    else if (profile.checkIterationTwo) return translations.getLabel('lblRuleChecksRound2');
    return translations.getLabel('lblNoRuleChecks');
  };

  const renderRow = (profile) => {
    return (
      <div className="profile-container">
        <div className="profile-information">
          <h4>{profile.name}</h4>
          <p><strong>{translations.getLabel('lblSelfRosterRuleChecks')}</strong> - <i>{checkIterations(profile)}</i></p>
          <p><strong>{translations.getLabel('lblServices')}</strong> - <i>{profile.showShifts === services.LARGER_THAN_ZERO ? translations.getLabel('lblOccupanyGreater') : translations.getLabel('lblOccupanyGreaterAndFunction')}</i></p>
          <p><strong>{translations.getLabel('lblDepartments')}</strong> - <i>{translations.getLabel('lblDepartmentsProfiles', { departments: profile.numberOfDepartments })}</i></p>
        </div>
        <div className="action-buttons">
          <div className="icon-cell">
            <IconButton tag="TrashIcon" color="error" onClick={() => openDeleteModal({ profileId: profile.id })} />
          </div>
          <div className="icon-cell">
            <IconButton tag="EditIcon" color="primary" onClick={() => { switchModal(true, false, profile); }} />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="self-roster-profile">
      {renderRow(profile)}
      {renderDeleteModal({
        title: translations.getLabel('titleDeleteSelfRosterProfile'),
        rightButton: translations.getLabel('lblDelete'),
        message: translations.getLabel('lblDeleteSelfRosterProfile'),
      })}
    </div>
  );
};

SelfRosterProfile.propTypes = {
  profile: PropTypes.object.isRequired,
  companyId: PropTypes.string.isRequired,
  switchModal: PropTypes.func.isRequired,
  getData: PropTypes.func.isRequired,
};

export default SelfRosterProfile;
