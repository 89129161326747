import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import ConfigContext from '../../../../../context/ConfigContext';
import { holdingAdminService } from '../../../../../services';
import CrossDepartmentShiftOffers from './components/CrossDepartmentShiftOffers';
import StartOfWeek from './components/StartOfWeek';
import VacationApprovalSetting from './components/VacationApprovalSetting';
import VacationTypes from './components/VacationTypes';

import './scheduleConfig.scss';

export default function ScheduleConfigTab(props) {
  const context = useContext(ConfigContext);

  useEffect(() => {
    if (props.companyId) {
      const initConfig = async () => {
        const result = await holdingAdminService.getCompanyConfig(props.companyId);
        if (result) {
          context.updateCompanyConfig(result.config);
        }
      };
      initConfig();
    }
  }, [props.companyId]);  //eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="counters-tab vacation-types-container">
      <StartOfWeek holdingId={props.holdingId} companyId={props.companyId} />
      <CrossDepartmentShiftOffers holdingId={props.holdingId} companyId={props.companyId} />
      <VacationApprovalSetting holdingId={props.holdingId} companyId={props.companyId} />
      <VacationTypes holdingId={props.holdingId} companyId={props.companyId} />
    </div>
  );
}

ScheduleConfigTab.propTypes = {
  companyId: PropTypes.string.isRequired,
  holdingId: PropTypes.string.isRequired,
};
