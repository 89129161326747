import React, { PureComponent } from 'react';
import { Redirect,Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import { routes,storageItems } from '../constants';
import ConfigContext from '../context/ConfigContext';

class AuthorizedRoute extends PureComponent {
  renderRoute = () => {
    const RouteComponent = this.props.component;
    return localStorage.getItem(storageItems.IS_LOGGED_IN) === 'true' ? <RouteComponent {...this.props} isSuperUser={this.context.isSuperUser} isHoldingAdmin={!!this.context.me.isHoldingAdmin} /> : <Redirect to={this.context.isSuperUser ? routes.loginSuperAdmin : routes.login} />;
  };

  render() {
    const { component, ...otherProps } = this.props;

    return (
      <Route
        {...otherProps}
        render={this.renderRoute}
      />
    );
  }
}

AuthorizedRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

AuthorizedRoute.contextType = ConfigContext;

export default AuthorizedRoute;
