import React, { useContext,useEffect, useState } from 'react';
import { Button, Modal,SelectPopup } from 'react-ess-components';
import Skeleton from 'react-loading-skeleton';
import PropTypes from 'prop-types';

import ConfigContext from '../../../../../context/ConfigContext';
import { holdingAdminService } from '../../../../../services';
import { translations } from '../../../../../utils';
import CounterGroup from '../components/counterGroup/CounterGroup';

import '../CountersTab.scss';

let didCancel = false;

const CountersTab = (props) => {
  const context = useContext(ConfigContext);

  const [allCountersFormatted, setAllCountersFormatted] = useState([]);
  const [selectedCounters, setSelectedCounters] = useState([]);
  const [counterGroups, setCounterGroups] = useState([]);
  const [initialCounterGroups, setInitialCounterGroups] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [isCounterGroupsLoading, setIsCounterGroupsLoading] = useState(true);
  const [groupToEdit, setGroupToEdit] = useState('');

  const getCountersData = async () => {
    const counterGroups = await holdingAdminService.getCounterGroups(context.config.holdingId, props.companyId);
    let allCounters = [];
    if (context.allCounters?.length) {
      allCounters = [...context.allCounters];
    } else {
      allCounters = await holdingAdminService.getAllCounters(context.config.holdingId);
      context.updateAllCounters(allCounters);
    }
    if (!didCancel) {
      setCounterGroups(counterGroups || []);
      setInitialCounterGroups(counterGroups || []);
      if (allCounters) {
        setAllCountersFormatted(allCounters.map(counter => ({ value: counter.id, label: counter.description })));
      }
      setIsCounterGroupsLoading(false);
    }
  };

  useEffect(() => {
    didCancel = false;

    getCountersData();

    return () => {
      didCancel = true;
    };
  }, [props.companyId]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSelectedCounters(groupToEdit ? getSelection(groupToEdit) : []);
  }, [isAdding]); //eslint-disable-line react-hooks/exhaustive-deps

  const renderGroups = (group) => {
    return (
      <CounterGroup
        key={group.groupId}
        group={group}
        toggleSelect={toggleSelect}
        toggleEdit={toggleEdit}
        isEditing={group.groupId === groupToEdit}
        shouldShowAdd={context.allCounters.length > 0 && context.allCounters.length > group.counters?.length}
        isEditDisabled={!!groupToEdit && group.groupId !== groupToEdit}
        onRemove={onRemove}
        onRemoveGroup={group.groupId === 'add' ? onCancel : toggleRemoveModal}
        onSave={handleSaveGroup}
        onCancel={onCancel}
        onChangeTitle={changeGroupTitle}
      />
    );
  };

  const changeGroupTitle = (title) => {
    const groupIndex = counterGroups.findIndex(group => group.groupId === groupToEdit);
    const groupedCounters = [...counterGroups];
    groupedCounters[groupIndex].group = title;
    setCounterGroups(groupedCounters);
  };

  const renderGroupSkeletons = (i) => <div key={`group-skeleton-${i}`} className="skeleton-wrapper"><Skeleton width="100%" height="12rem" /></div>;

  const closePopups = (cb) => async () => {
    cb();
  };

  const toggleSelect = () => {
    setIsAdding(!isAdding);
  };

  const toggleEdit = (data = {}) => {
    setGroupToEdit(groupToEdit ? '' : data.id);
  };

  const getSelection = (id) => {
    const group = counterGroups.find(group => group.groupId === id);
    if (!group) return [];
    return group.counters.map(counter => counter.id);
  };

  const onChangeCounters = (selection) => {
    setSelectedCounters(selection);
  };

  const handleSaveGroup = async () => {
    setIsCounterGroupsLoading(true);

    const groupIndex = counterGroups.findIndex(group => group.groupId === groupToEdit);
    const counterIds = counterGroups[groupIndex].counters.map(counter => counter.id);
    const name = counterGroups[groupIndex].group;

    if (groupToEdit === 'add') {
      await holdingAdminService.addCounterGroup(context.config.holdingId, props.companyId, { counterIds, name, order: counterGroups.length });
    } else {
      await holdingAdminService.updateCounterGroup(context.config.holdingId, props.companyId, groupToEdit, { counterIds, name });
    }
    const groupedCounters = await holdingAdminService.getCounterGroups(context.config.holdingId, props.companyId);

    setIsCounterGroupsLoading(false);
    setCounterGroups(groupedCounters || []);
    setInitialCounterGroups(groupedCounters || []);
    setGroupToEdit('');
  };

  const onCancel = async () => {
    setGroupToEdit('');
    setCounterGroups(initialCounterGroups || []);
  };


  const addSelectionToGroup = () => {
    toggleSelect();
    const groupedCounters = counterGroups.reduce((counterGroupAccu, group) => {
      if (group.groupId !== groupToEdit) {
        return [...counterGroupAccu, group];
      }
      const counters = selectedCounters.reduce((acc, counter) => {
        return [...acc, { ...context.allCounters.find(c => c.id === counter) }];
      }, []);
      return [...counterGroupAccu, { ...group, counters }];
    }, []);

    setCounterGroups(groupedCounters);
  };

  const handleSave = async () => {
    if (groupToEdit) return addSelectionToGroup();
  };

  const onRemove = (id) => {
    const groupedCounters = counterGroups.reduce((counterGroupAccu, group) => {
      if (group.groupId !== groupToEdit) {
        return [...counterGroupAccu, group];
      }
      const counters = group.counters.reduce((acc, counter) => {
        if (counter.id !== id) return [...acc, { ...counter }];
        return acc;
      }, []);
      return [...counterGroupAccu, { ...group, counters }];
    }, []);

    const selection = selectedCounters.filter(counter => counter !== id);

    setSelectedCounters(selection);
    setCounterGroups(groupedCounters);
  };

  const toggleRemoveModal = () => {
    setIsRemoveModalOpen(!isRemoveModalOpen);
  };

  const onRemoveGroup = async () => {
    setIsCounterGroupsLoading(true);

    await holdingAdminService.deleteCounterGroup(context.config.holdingId, props.companyId, groupToEdit);
    const groupedCounters = await holdingAdminService.getCounterGroups(context.config.holdingId, props.companyId);

    toggleRemoveModal();

    setIsCounterGroupsLoading(false);
    setCounterGroups(groupedCounters || []);
    setInitialCounterGroups(groupedCounters || []);
    setGroupToEdit('');
  };

  const addCounterGoup = () => {
    const group = { groupId: 'add' };
    setGroupToEdit('add');
    setSelectedCounters([]);
    group.counters = [];
    const groupedCounters = [...counterGroups];
    groupedCounters.push(group);
    setCounterGroups(groupedCounters);
  };

  return (
    <div className="counters-tab">
      <div className="counter-container">
        <div className="title-container">
          <p className="label">{translations.getLabel('lblConfigureCounterPage')}</p>
          <Button disabled={!!groupToEdit || !context.allCounters?.length} onClick={addCounterGoup}>{translations.getLabel('btnAddCounterGroup')}</Button>
        </div>
        <div className="counters-config">
          {
            isCounterGroupsLoading && [0, 1].map(renderGroupSkeletons)
          }
          {
            !isCounterGroupsLoading && !!counterGroups.length && counterGroups.map(renderGroups)
          }
          {
            !isCounterGroupsLoading && !counterGroups.length && <p>{translations.getLabel('lblNoCounters')}</p>
          }
        </div>
      </div>

      <Modal
        open={isRemoveModalOpen}
        title={translations.getLabel('lblRemoveCounterGroup')}
        requestClose={toggleRemoveModal}
        leftButtonProps={{
          label: translations.getLabel('cancel'),
          onClick: toggleRemoveModal,
        }}
        rightButtonProps={{
          label: translations.getLabel('lblDelete'),
          isLoading: isCounterGroupsLoading,
          onClick: onRemoveGroup,
        }}
      >
        <div className="warning-message">{translations.getLabel('lblConfirmationDeleteCounterGroup')}</div>
      </Modal>

      <SelectPopup
        data={allCountersFormatted}
        popupOpen={isAdding}
        isMultiple
        isModal
        selected={selectedCounters}
        title={translations.getLabel('titleAddCountersGroup')}
        requestClose={closePopups(toggleSelect)}
        onValueSelected={onChangeCounters}
        onSave={handleSave}
        acceptLabel={translations.getLabel('save')}
        cancelLabel={translations.getLabel('cancel')}
        isSearchEnabled={false}
      />
    </div >
  );
};

CountersTab.propTypes = {
  companyId: PropTypes.string.isRequired,
};

export default CountersTab;
