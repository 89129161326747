import { listTimeZones } from 'timezone-support';

const getTimeZones = () => {
  const list = listTimeZones();
  const subset = list.filter(zone => (
    (zone.toLowerCase().indexOf('africa') === -1)
    && (zone.toLowerCase().indexOf('america') === -1)
    && (zone.toLowerCase().indexOf('asia') === -1)
    && (zone.toLowerCase().indexOf('antarctica') === -1)
    && (zone.toLowerCase().indexOf('australia') === -1)
    && (zone.toLowerCase().indexOf('pacific') === -1)
    && (zone.toLowerCase().indexOf('indian') === -1)
    && (zone.toLowerCase().indexOf('atlantic') === -1)
  ));
  return subset.map(zone => ({ label: zone, value: zone }));
};


export const timeZones = getTimeZones();